const franceTranslation = {
  // HEADER //////////////////
  // Nav
  'header_nav_dashboard': 'Tableau de bord',
  'header_nav_dashboard_sales': 'Ventes',
  'header_nav_dashboard_stock': 'Action',
  'header_nav_dashboard_prediction': 'Prédiction',
  'header_nav_catalog': 'Catalogue',
  'header_nav_catalog-download': 'Télécharger le catalogue',
  'header_nav_multimedia': 'Multimédia',
  'header_nav_clients': 'Clients',
  'header_sub-nav_view-catalog': 'Voir le catalogue',
  'header_sub-nav_add-product': 'Ajouter un produit',
  'header_sub-nav_all-catalogs': 'Gestion des catalogues',
  'header_sub-nav_catalog-correlation': 'Corrélation des références',
  'header_sub-nav_catalog-generic': 'Génériques H4R',
  'header_sub-nav_multimedia-catalog': 'Catalogue multimédia',
  'header_sub-nav_multimedia-catalog-videos': 'Vidéos',
  'header_sub-nav_multimedia-catalog-images': 'Images',
  'header_sub-nav_multimedia-catalog-banners': 'Bannières',
  'header_sub-nav_clients-list': 'Liste des clients',
  'header_sub-nav_add-client': 'Ajouter un client',
  // Notifications
  'header_notifications_empty-notifications': 'Il n\'y a pas de notifications',
  ////////////////////////////

  // SIGNUP PAGE /////////////
  'signup-page_title': "S'inscrire à :",
  'signup-page_sub-title': "Inscrivez-vous pour développer votre entreprise",
  'signup-page_button-brand': "S'inscrire en tant que marque",
  'signup-page_button-multibrand': "S'inscrire en tant que multimarque",
  'signup-page_button-brand-wh': "M'inscrire en tant que marque ou multimarque",
  'signup-page_return-to-login': 'Retour à la page de connexion',
  ////////////////////////////

  // ACCOUNT PAGE /////////////
  'profile-page_nav_profile': 'Profil',
  'profile-page_nav_company': "Informations de l'entreprise",
  'profile-page_nav_main-contacts': 'Contacts principaux',
  'profile-page_nav_commercial-info': 'Informations commerciales',
  /////////////////////////////

  // DASHBOARDS //////////
  'dashboard_empty-dashboard': 'Contactez Hub4Retail pour activer ce service.',
  'dashboard_dashboard_error': 'Erreur de chargement du tableau de bord. Si le problème persiste, contactez :',
  //////////////////////////////

  // HELMET ///////////////////
  'signup': "S'inscrire",
  'home': 'Accueil',
  'dashboard': 'Tableau de bord',
  'multimedia': 'Multimédia',
  'multimedia-videos': 'Vidéos multimédias',
  'multimedia-images': 'Images multimédias',
  'multimedia-banners': 'Bannières multimédia',
  'clients': 'Clients',
  'profile': 'Profil',
  'company': "Informations de l'entreprise",
  'main-contacts': 'Contacts principaux',
  'commercial-info': 'Informations commerciales',
  'catalog': 'Catalogue',
  'notifications': 'Notifications',
  'commercial-profile': 'Profil Commercial',
  'all-catalogs': 'Tous les catalogues',
  'catalog-correlation': 'Corrélation de références',
  'catalog-generic': 'Génériques H4R',
  'catalog_download': 'Télécharger le catalogue',
  // Client
  'client_resume': 'Résumé',
  'client_business-info': "Informations sur l'entreprise",
  'client_contacts': 'Contacts',
  'client_sale-points': 'Points de vente',
  'client_comercial-info': 'Informations commerciales',
  /////////////////////////////

  // USERS ///////////////////////
  // Departments
  'users_department_commercial': 'Commercial',
  'users_department_administration': 'Administration',
  'users_department_agent': 'Agent',
  'users_department_owner': 'Propriétaire',
  'users_department_store-manager': 'Responsable de magasin',
  'users_department_logistics': 'Logistique',
  'users_department_marketing': 'Marketing',
  'users_department_others': 'Autres',
  ////////////////////////////////

  // ACCOUNT //////////////////
  'account_profile_title': 'Mon profil',
  'account-info-panel_title': 'Informations générales',
  'account-company-info-panel_title': "Données de l'entreprise",
  // Profile
  'account-info-panel_profile_name': 'Nom',
  'account-info-panel_profile_lastname': 'Nom de famille',
  'account-info-panel_profile_email': 'Email',
  'account-info-panel_profile_phone-number': 'Numéro de téléphone',
  'account-info-panel_profile_department': 'Département',
  'account-info-panel_profile_security-data': 'Données de sécurité',
  'account-info-panel_profile_change-password': 'Changer le mot de passe',
  'account-info-panel_profile_pwd_password-label': 'Nouveau mot de passe',
  'account-info-panel_profile_pwd_repeat-password-label': 'Répétez le nouveau mot de passe',
  'account-info-panel_profile_pwd_change-button': 'Changer le mot de passe',
  'account-info-panel_profile_pwd_validation': 'Le mot de passe doit comporter au minimum 8 caractères et au maximum 16, 1 numéro, 1 lettre majuscule et une minuscule.',
  'account-info-panel_profile_pwd_validation-match': 'Les mots de passe ne correspondent pas',
  'account-info-panel_profile_pwd_change-success': 'Mot de passe changé avec succès.',
  'account-info-panel_profile_pwd_empty-inputs': 'Remplissez les champs vides.',
  // Profile form
  'account-profile_form-input_name': 'Entrez votre nom',
  'account-profile_form-input_name-validation': 'Veuillez entrer votre nom',
  'account-profile_form-input_lastname': 'Entrez votre nom de famille',
  'account-profile_form-input_lastname-validation': 'Veuillez entrer votre nom de famille',
  'account-profile_form-input_email': 'Entrez votre adresse e-mail',
  'account-profile_form-input_email-validation-required': 'Veuillez entrer votre adresse e-mail',
  'account-profile_form-input_email-validation': "L'adresse e-mail n'est pas valide",
  'account-profile_form-input_department': 'Entrez le département',
  'account-profile_form-input_department-validation': 'Veuillez entrer le département',
  'account-profile_form-input_phone-number': '',
  'account-profile_form-input_phone-number-validation': 'Veuillez entrer le numéro de téléphone',
  // Company
  'account-info-panel_company_title': "Données de l'entreprise",
  'account-info-panel_company_cif': 'Numéro de NIF (Numéro d\'Identification Fiscale)',
  'account-info-panel_company_trade-name': 'Nom commercial',
  'account-info-panel_company_business-name': 'Raison sociale',
  'account-info-panel_company_address': 'Adresse',
  'account-info-panel_company_postal-code': 'Code postal',
  'account-info-panel_company_population': 'VILLE',
  'account-info-panel_company_country': 'Pays',
  'account-info-panel_company_email': 'Email',
  'account-info-panel_company_phone-number': 'Numéro de téléphone',
  'account-info-panel_company_contact_name_fr': 'Contact Principal',
  'account-info-panel_company_metas-connected_title_fr': 'Objectifs Connectés',
  'account-info-panel_company_active-seasons_title_fr': 'Saisons Actives',
  // Company form
  'account-company_form-input_trade-name': 'Entrez le nom commercial',
  'account-company_form-input_trade-name-validation': 'Veuillez entrer le nom commercial',
  'account-company_form-input_business-name': 'Entrez le nom de l\'entreprise',
  'account-company_form-input_business-name-validation': 'Veuillez entrer le nom de l\'entreprise',
  'account-company_form-input_country': 'Entrez le pays',
  'account-company_form-input_country-validation': 'Veuillez entrer le pays',
  'account-company_form-input_population': 'Entrez la ville',
  'account-company_form-input_population-validation': 'Veuillez entrer la ville',
  'account-company_form-input_address': 'Entrez l\'adresse',
  'account-company_form-input_address-validation': 'Veuillez entrer votre adresse',
  'account-company_form-input_postal-code': 'Entrez le code postal',
  'account-company_form-input_postal-code-validation': 'Veuillez entrer le code postal',
  'account-company_form-input_cif': 'Entrez le NIF (Numéro d\'Identification Fiscale)',
  'account-company_form-input_cif-validation': 'Veuillez entrer le NIF (Numéro d\'Identification Fiscale)',
  'account-company_form-input_phone-number': 'Numéro de téléphone',
  'account-company_form-input_phone-number-validation': 'Veuillez entrer le numéro de téléphone',
  'account-company_form-input_email': 'Entrez l\'e-mail de l\'entreprise',
  'account-company_form-input_contact_name_fr': 'Entrez le contact principal de l\'entreprise',
  'account-company_form-input_contact_name-validation-required_fr': 'Veuillez entrer le contact principal de l\'entreprise',
  'account-company_form-input_email-validation-required': 'Veuillez entrer votre adresse e-mail',
  'account-company_form-input_email-validation': "L'adresse e-mail n'est pas valide",
  'account-company_form-input_instagram': 'Entrez l\'URL Instagram',
  'account-company_form-input_youtube': 'Entrez l\'URL Youtube',
  'account-company_form-input_linkedin': 'Entrez l\'URL Linkedin',
  'account-company_form-input_facebook': 'Entrez l\'URL Facebook',
  'account-company_social-media-title': 'Réseaux Sociaux',
  'account-company_form-input_social-media-label': 'Nom du Réseau Social',
  'account-company_form-input_social-media-label-placeholder': 'Nom du Réseau Social',
  'account-company_form-input_social-media-label_validation': 'Veuillez entrer le nom du réseau social',
  'account-company_form-input_social-media-value': 'URL du Réseau Social',
  'account-company_form-input_social-media-value-placeholder': 'URL du Réseau Social',
  'account-company_form-input_social-media-value_validation': 'Veuillez entrer l\'URL du réseau social',
  'account-company_form-input_social-media-add-button': 'Ajouter un Réseau Social',
  'account-company_form-input_social-media-accept-button_title': 'Mettre à jour le Réseau Social',
  'account-company_form-input_social-media-reject-button_title': 'Annuler les modifications',
  'account-company_form-input_social-media-delete-button_title': 'Supprimer le Réseau Social',
  'account-company_form-input_social-media-delete-button_popconfirm_title': 'Supprimer le Réseau Social',
  'account-company_form-input_social-media-delete-button_popconfirm_description': 'Êtes-vous sûr de vouloir supprimer ce réseau social ?',
  'account-company_form-input_social-media-delete-button_popconfirm_ok': 'Supprimer',
  'account-company_form-input_social-media-delete-button_popconfirm_cancel': 'Annuler',
  // Main Contacts
  'main-contacts_title': 'Contacts Principaux',
  'main-contacts_add-contact': '+ Ajouter',
  'main-contacts_table_name': 'Nom',
  'main-contacts_table_lastname': 'Nom de famille',
  'main-contacts_table_email': 'E-mail',
  'main-contacts_table_phone-number': 'Numéro de téléphone',
  'main-contacts_table_department': 'Département',
  'main-contacts_table_pop-confirm_title': 'Supprimer le contact',
  'main-contacts_table_pop-confirm_description': 'Êtes-vous sûr de vouloir supprimer ce contact ?',
  'main-contacts_table_pop-confirm_btn-accept': 'Supprimer',
  'main-contacts_table_pop-confirm_btn-cancel': 'Annuler',
  // Main Contacts Form
  'main-contacts_form-label_name': 'Nom',
  'main-contacts_form-label_lastname': 'Nom de famille',
  'main-contacts_form-label_email': 'E-mail',
  'main-contacts_form-label_phone-number': 'Numéro de téléphone',
  'main-contacts_form-label_department': 'Département',
  'main-contacts_form-input_name': 'Entrez le nom',
  'main-contacts_form-input_lastname': 'Entrez le nom de famille',
  'main-contacts_form-input_email': 'Entrez l\'e-mail',
  'main-contacts_form-input_department': 'Entrez le département',
  'main-contacts_form_sector_label': 'Secteur',
  'main-contacts_form-input_sector': 'Entrer le secteur',
  'main-contacts_form-input_sector_validation': 'Entrer le secteur',
  'main-contacts_form-input_phone-number': 'Entrez le numéro de téléphone',
  'main-contacts_form-input-validation_name': 'Veuillez entrer le nom',
  'main-contacts_form-input-validation_lastname': 'Veuillez entrer le nom de famille',
  'main-contacts_form-input-validation_email': 'L\'e-mail n\'est pas valide',
  'main-contacts_form-input-validation-required_email': 'Veuillez entrer l\'e-mail',
  'main-contacts_form-input-validation_department': 'Veuillez entrer le département',
  'main-contacts_form-input-validation_phone-number': 'Veuillez entrer le numéro de téléphone',
  // Comercial information
  'comercial-info_title': 'Informations Commerciales',
  'comercial-info_add-contact': '+ Ajouter un Agent',
  'comercial-info_table_agent-name': 'Agent Commercial / Distributeur',
  'comercial-info_table_division': 'Division',
  'comercial-info_table_buy-group': 'Groupe d\'achat',
  'comercial-info_table_genre': 'Genre',
  'comercial-info_table_pop-confirm_title': 'Supprimer l\'agent commercial',
  'comercial-info_table_pop-confirm_description': 'Êtes-vous sûr de vouloir supprimer cet agent commercial ?',
  'comercial-info_table_pop-confirm_btn-accept': 'Supprimer',
  'comercial-info_table_pop-confirm_btn-cancel': 'Annuler',
  // Comercial information Form
  'comercial-info_form-label_name': 'Nom',
  'comercial-info_form-label_lastname': 'Nom de famille',
  'comercial-info_form-label_trade-name': 'Nom de l\'entreprise',
  'comercial-info_form-label_business-name': 'Raison sociale',
  'comercial-info_form-label_cif': 'NIF (Numéro d\'Identification Fiscale)',
  'comercial-info_form-label_phone-number': 'Numéro de téléphone',
  'comercial-info_form-label_main-contact': 'Contact Principal',
  'comercial-info_form-label_working-brands': 'Marques avec lesquelles il travaille :',
  'comercial-info_form-input_name': 'Entrez le nom',
  'comercial-info_form-label_email': 'Email',
  'comercial-info_form-label_sector': 'Secteur',
  'comercial-info_form-input_email': 'Entrez le courriel',
  'comercial-info_form-input_sector': 'Introduisez le secteur',
  'comercial-info_form-input_division': 'Introduisez la division',
  'comercial-info_form-input_yes': 'Oui',
  'comercial-info_form-input_no': 'Non',
  'comercial-info_form-input_lastname': 'Entrez le nom de famille',
  'comercial-info_form-input_trade-name': 'Entrez le nom de l\'entreprise',
  'comercial-info_form-input_business-name': 'Entrez la raison sociale',
  'comercial-info_form-input_cif': 'Entrez le NIF (Numéro d\'Identification Fiscale)',
  'comercial-info_form-input_phone-number': 'Entrez le numéro de téléphone',
  'comercial-info_form-input_main-contact': 'Entrez le contact principal',
  'comercial-info_form-input-validation_name': 'Veuillez entrer le nom',
  'comercial-info_form-input-validation_email': 'S\'il vous plaît, entrez l\'email',
  'comercial-info_form-input-validation_sector': 'S\'il vous plaît, introduisez le secteur',
  'comercial-info_form-input-validation_division': 'S\'il vous plaît, introduisez la division',
  'comercial-info_form-input-validation_buy-group': 'S\'il vous plaît, introduisez le groupe d\'achat',
  'comercial-info_form-input-validation_lastname': 'Veuillez entrer le nom de famille',
  'comercial-info_form-input-validation_trade-name': 'Veuillez entrer le nom de l\'entreprise',
  'comercial-info_form-input-validation_business-name': 'Veuillez entrer la raison sociale',
  'comercial-info_form-input-validation_cif': 'Veuillez entrer le NIF (Numéro d\'Identification Fiscale)',
  'comercial-info_form-input-validation_phone-number': 'Veuillez entrer le numéro de téléphone',
  'comercial-info_form-input-validation_main-contact': 'Veuillez entrer le contact principal',
  // Drawers
  'account-profile_drawer_title': 'Information Générale',
  'account-company_drawer_title': 'Données de l’Entreprise',
  'comercial-info_drawer_title': 'Information Commerciale',
  'main-contacts_drawer_title': 'Contacts Principaux',
  'main-contacts_drawer_create-title': 'Créer un contact',
  'main-contacts_drawer_update-title': 'Mettre à jour le contact',
  /////////////////////////////

  // MULTIMEDIA ///////////////
  'multimedia_nav-videos': 'Vidéos',
  'multimedia_nav-images': 'Images',
  'multimedia_sort-btn': 'Trier par',
  'multimedia_filter-btn': 'Filtres',
  'multimedia_apply-filters-btn': 'Appliquer les filtres',
  'multimedia_search-input-placeholder': 'Rechercher par marque, genre, saison, pays, date',
  'multimedia_search-results': 'Résultats',
  'multimedia_download': 'Télécharger',
  'multimedia_download_started': 'Le téléchargement a commencé',
  'multimedia_open': 'Ouvrir',
  'multimedia_link-copied': 'Lien copié dans le presse-papiers',
  'multimedia_link-copy-button': 'Copier le lien',
  'multimedia_delete-popconfirm-title': 'Supprimer le fichier multimédia',
  'multimedia_delete-popconfirm-description': 'Êtes-vous sûr de vouloir supprimer ce fichier ?',
  // Sort by
  'multimedia_sort-last-favorites': 'Favoris',
  'multimedia_sort-last-aggregates': 'Derniers ajoutés',
  'multimedia_sort-best-seller': 'Meilleures ventes',
  'multimedia_sort-less-sold': 'Moins vendu',
  'multimedia_sort-best-pvp_great-minor': 'Prix : élevé à bas',
  'multimedia_sort-best-pvp_minor-great': 'Prix : bas à élevé',
  'multimedia_sort-best-pvi_great-minor': 'PVI : élevé à bas',
  'multimedia_sort-best-pvi_minor-great': 'PVI : bas à élevé',
  'multimedia_sort-by-color': 'Par couleur de A à Z',
  'multimedia_sort-by-reference': 'Par référence de A à Z',
  // Images
  'multimedia_images_popover-edit': 'Modifier',
  'multimedia_images_popover-remove': 'Supprimer',
  'multimedia_images_popover-yes': 'Oui',
  'multimedia_images_popover-no': 'Non',
  // Filter
  'multimedia_filter-remove-filters': 'Supprimer les filtres',
  'multimedia_filter-season': 'Saison',
  'multimedia_filter-division': 'Division',
  'multimedia_filter-genders': 'Genre',
  'multimedia_filter-visualization': 'Visualisation',
  'multimedia_filter-image-rights': 'Droits d\'image',
  'multimedia_filter-country': 'Pays',
  'multimedia_filter-tags': 'Étiquettes',
  'multimedia_filter-favorites': 'Favoris',
  'multimedia_filter-start': 'Émis à partir de',
  'multimedia_filter-end': "Émis jusqu'à",
  //Dragger
  'multimedia_upload-images-manually_dragger_msg-error': 'Fichiers pris en charge',
  'multimedia_upload-images-manually_dragger_msg_image-size-limit_error': 'La taille de l’image doit être inférieure à 3 Mo',
  'multimedia_upload-images-manually_dragger_msg_video-size-limit_error': 'La taille de la vidéo doit être inférieure à 100 Mo',
  'multimedia_upload-images-manually_dragger_text-1': 'Cliquez ou faites glisser une image',
  // Upload image manually
  'multimedia_upload-images-manually_form-item_selection': 'Sélectionner',
  'multimedia_upload-images-manually_form-item_title': 'Titre',
  'multimedia_upload-images-manually_form-item_title-required': 'S\'il vous plaît, saisissez le titre de l\'image',
  'multimedia_upload-images-manually_form-item_usage-required': 'Veuillez entrer le type d’utilisation',
  'multimedia_upload-images-manually_form-item_name': 'Dénomination Technique',
  'multimedia_upload-images-manually_form-item_name-required': 'S\'il vous plaît, saisissez le nom de l\'image',
  'multimedia_upload-images-manually_form-item_description': 'Description',
  'multimedia_upload-images-manually_form-item_description-required': 'S\'il vous plaît, saisissez la descriptio',
  'multimedia_upload-images-manually_form-item_divisions': 'Division',
  'multimedia_upload-images-manually_form-item_season': 'Saison',
  'multimedia_upload-images-manually_form-item_language': 'Langue',
  'multimedia_upload-images-manually_form-item_genders': 'Genre',
  'multimedia_upload-images-manually_form-item_products': 'Produits',
  'multimedia_upload-images-manually_form-item_catalog_id': 'Identifiant du catalogue',
  'multimedia_upload-images-manually_form-item_catalog_id_text-1': 'Champ associé à Chronos pour définir son circuit publicitaire',
  'multimedia_upload-images-manually_form-item_catalog_id_text-2': 'Contactez Hub4Retail pour activer Chronos',
  'multimedia_upload-images-manually_form-item_usage': 'Utilisation du contenu',
  'multimedia_upload-images-manually_form-item_visualization': 'Visualisation',
  'multimedia_upload-images-manually_form-item_tags': 'Étiquettes',
  'multimedia_upload-images-manually_form-item_tags_text-1': 'Créez vos propres étiquettes" en francés',
  'multimedia_upload-images-manually_form-item_start': 'Reproductible depuis',
  'multimedia_upload-images-manually_form-item_end': "Reproductible jusqu'à",
  'multimedia_upload-images-manually_form-item_copyright': 'Ce vidéo a-t-elle les droits d\'image ?',
  'multimedia_upload-images-manually_form-item_enable_download': 'Permettez-vous le téléchargement?',
  'multimedia_upload-images-manually_form-item_countries': 'Pays',
  'multimedia_upload-images-manually_form-item_add': 'Ajouter',
  'multimedia_upload-images-manually_form-item_next': 'Suivant',
  'multimedia_upload-images-manually_form-item_yes': 'Oui',
  'multimedia_upload-images-manually_form-item_no': 'Non',
  // Modal manually
  'multimedia_upload-images-manually_modal_title': 'Mettre à jour l\'image',
  'multimedia_upload-images-manually_modal_step-1': 'Sélectionne une image',
  'multimedia_upload-images-manually_modal_step-2': 'Complétez les données',
  'multimedia_upload-images-manually_modal_step-2-msg': 'Titre de l\'image',
  'multimedia_upload-images-manually_modal_step-3-msg': 'Image téléchargée avec succès',
  // Upload images CSV
  'multimedia_upload-images_button': 'Télécharger des images',
  'multimedia_upload-images_step-1': 'Sélectionner des images',
  'multimedia_upload-images_step-1_dragger_text-1': 'Cliquez ou déposez les fichiers d\'image',
  'multimedia_upload-images_step-1_dragger_text-2': 'Prenez note des éléments suivants...',
  'multimedia_upload-images_step-1_dragger_text-3': 'Enregistrez les images avec le nom',
  'multimedia_upload-images_step-1_dragger_text-4': 'référence_couleur_image#',
  'multimedia_upload-images_step-2': 'Télécharger le fichier CSV',
  'multimedia_upload-images_step-2_dragger_text-1': 'Cliquez ou déposez le fichier CSV',
  'multimedia_upload-images_step-3': 'Téléchargement terminé !',
  'multimedia_upload-images_step-3-text': 'fichiers d\'image téléchargés',
  'multimedia_upload-images_modal_title': 'Télécharger des images',
  'multimedia_upload-images_modal_btn-back': 'Retour',
  'multimedia_upload-images_modal_btn-next': 'Suivant',
  'multimedia_upload-images_modal_btn-close': 'Fermer',
  //Upload
  'multimedia_upload_step-2': 'Complétez les données',
  // Upload videos
  'multimedia_upload-videos_button': 'Télécharger des vidéo',
  'multimedia_upload-videos_step-1_dragger_text-1': 'Cliquez ou glissez et déposez des fichiers vidéo',
  'multimedia_upload-videos_step-1': 'Sélectionner une vidéo',
  'multimedia_upload-videos_modal_title': 'Télécharger une vidéo',
  // Upload banners
  'multimedia_upload-banners_button': 'Télécharger des bannières',
  'multimedia_upload-banners_modal_title': 'Télécharger une bannière',
  'multimedia_upload-banner_step-1': 'Sélectionner une bannière',
  'multimedia_upload-banners_step-1_dragger_text-1': 'Cliquez ou glissez et déposez une bannière',
  ////////////////////////////

  // BRAND USERS /////////////////
  'brand-user_title': 'Utilisateurs',
  'brand-user_description': 'Contrôlez comment les membres de votre équipe interagissent avec Hub4Retail.',
  'brand-user_create-user': 'Créer un utilisateur',
  'brand-user_modal_title-create': 'Ajouter un utilisateur',
  'brand-user_modal_title-edit': 'Éditer un utilisateur',
  'brand-user_modal_input_name': 'Prénom',
  'brand-user_modal_input_lastname': 'Nom',
  'brand-user_modal_input_email': 'Email',
  'brand-user_modal_input_password': 'Mot de passe',
  'brand-user_modal_input_btn-save': 'Enregistrer',
  'brand-user_modal_input_btn-cancel': 'Annuler',
  'brand-user_table_name': 'Prénom',
  'brand-user_table_email': 'Email',
  'brand-user_table_role': 'Rôle',
  'brand-user_table_last-session': 'Dernière session',
  'brand-user_table_last-session-never': 'Jamais',
  'brand-user_delete-popconfirm_title': 'Supprimer l’utilisateur',
  'brand-user_delete-popconfirm_description': 'Êtes-vous sûr de vouloir supprimer cet utilisateur?',
  'brand-user_delete-popconfirm_confirm': 'Oui',
  'brand-user_delete-popconfirm_cancel': 'Non',
  ////////////////////////////////

  // CATALOG //////////////////
  'catalog_add-product': 'Ajouter un produit',
  'catalog_ProductList_results': 'Résultats',
  'catalog_no-more-results': 'Aucun résultat trouvé...',
  'catalog_tags_delete-all': 'Tout supprimer',
  'catalog_loading-filters': 'Chargement des filtres',
  // Sort by
  'catalog_sort-by_dateAsc': 'Derniers ajoutés',
  'catalog_sort-by_dateDesc': 'Les plus anciens',
  'catalog_sort-by_unitsAsc': 'Meilleures ventes',
  'catalog_sort-by_unitsDesc': 'Moins vendus',
  'catalog_sort-by_nameAsc': 'Nom de A à Z',
  'catalog_sort-by_nameDesc': 'Nom de Z à A',
  'catalog_sort-by_colorAsc': 'Couleur de A à Z',
  'catalog_sort-by_colorDesc': 'Couleur de Z à A',
  'catalog_sort-by_pviAsc': 'PVI du plus bas au plus haut',
  'catalog_sort-by_pviDesc': 'PVI du plus haut au plus bas',
  'catalog_sort-by_pvprAsc': 'PVPR du plus bas au plus haut',
  'catalog_sort-by_pvprDesc': 'PVPR du plus haut au plus bas',
  'catalog_sort-by_referenceAsc': 'Référence de A à Z',
  'catalog_sort-by_referenceDesc': 'Référence de Z à A',
  'catalog_search-input-placeholder': 'Rechercher par référence',
  // Sor by images
  'catalog_sort-by-images_with-images': 'Avec images',
  'catalog_sort-by-images_without-images': 'Sans images',
  'catalog_sort-by-images_all': 'Tous les produits',
  'catalog_sort-by-images_download-csv-products-without-images-tooltip': 'Télécharger le CSV des produits sans images',
  // Add catalog
  'catalog_add-catalog-csv-input-title': 'Télécharger CSV des produits',
  'catalog_add-catalog-csv-s1-text-1': 'Option',
  'catalog_add-catalog-csv-s1-text-2': 'Vous pouvez télécharger notre',
  'catalog_add-catalog-csv-s1-text-3': 'modèle excel',
  'catalog_add-catalog-csv-s1-text-4': 'ou',
  'catalog_add-catalog-csv-s1-text-5': 'Téléchargez votre propre modèle',
  'catalog_add-catalog-csv-s2-text-1': 'Télécharger CSV',
  'catalog_add-catalog-csv-s2-text-2': 'Télécharger des images',
  'catalog_add-catalog-csv-s2-text-3': 'Déterminer les dates de début et de fin des saisons',
  'catalog_add-catalog-csv_dowload-template-success': 'Modèle téléchargé avec succès',
  // Correlation-Generic
  'catalog-correlation_select-all': 'Toutes les références',
  'catalog-correlation_select-no-associated': 'Références uniques par générique H4R',
  'catalog-correlation_select-no-associated-at-all': 'Références non attribuées',
  'catalog-correlation_select-all_tooltip': 'Voir toutes les références disponibles',
  'catalog-correlation_select-no-associated_tooltip': 'Voir les références qui ne se répètent pas dans plus d\'un générique H4R',
  'catalog-correlation_select-no-associated-at-all_tooltip': 'Voir les références qui ne sont attribuées à aucun générique H4R',
  'catalog-correlation_empty-values': 'Aucune valeur sélectionnée à attribuer',
  'catalog-correlation_not-associated': 'Non attribué',
  'catalog-correlation_season-label': 'Saison',
  'catalog-correlation_gender-label': 'Genre',
  'catalog-correlation_color-label': 'Couleur',
  'catalog-correlation_color-code-label': 'Code couleur',
  'catalog-correlation_segmentation-label': 'Segmentation',
  'catalog-correlation_division-label': 'Division',
  'catalog-correlation_family-label': 'Famille',
  'catalog-correlation_table_column_h4r': 'Générique H4R',
  'catalog-correlation_table_accept-btn': 'Enregistrer',
  'catalog-correlation_table_reject-btn': 'Annuler',
  'catalog-correlation_table_required-field': 'Champ requis',
  'catalog-correlation_table_add-field': 'Ajouter',
  'catalog-correlation_pending-correlations': 'non assignée',
  'catalog-correlation_pendings-correlations': 'non assignées',
  // Upload product images
  'catalog_handle-images_pattern_title': 'Modèle d\'image',
  'catalog_handle-images_pattern_description': 'Sélectionnez le modèle de nom d\'image :',
  'catalog_handle-images_pattern_separator': 'Séparateur :',
  'catalog_handle-images_pattern_label': 'Modèle :',
  'catalog_handle-images_pattern_save': 'Enregistrer le modèle',
  'catalog_handle-images_pattern_create-message-success': 'Modèle enregistré avec succès',
  'catalog_handle-images_pattern_create-message-error': 'Erreur lors de l\'enregistrement du modèle',
  'catalog_handle-images_upload_title': 'Télécharger des images',
  'catalog_handle-images_upload_upload-image-success': 'Image enregistrée',
  'catalog_handle-images_upload_upload-images-success': 'Images enregistrées',
  'catalog_handle-images_upload_upload-image-without-pattern-success': 'Image sans modèle enregistrée',
  'catalog_handle-images_upload_upload-images-without-pattern-success': 'Images sans modèle enregistrées',
  'catalog_handle-images_upload_upload-images-success-correctly': 'correctement',
  'catalog_handle-images_upload_upload-image-error': 'Erreur lors du téléchargement des images',
  'catalog_handle-images_upload_upload-image-btn': 'Télécharger des images',
  'catalog_handle-images_upload_selected-images': 'Images sélectionnées :',
  'catalog_handle-images_upload_previously-uploaded-images': 'Images déjà téléchargées :',
  'catalog_handle-images_upload_uploaded-images': 'Images téléchargées :',
  'catalog_handle-images_upload_images-without-pattern': 'Images sans modèle :',
  'catalog_handle-images_upload_save-images-confirm': 'Voulez-vous enregistrer les images sans modèle ?',
  'catalog_handle-images_not-matched-images_title': 'Images ne correspondant à aucun produit',
  'catalog_handle-images_not-matched-images_save-success': 'Images assignées avec succès',
  'catalog_handle-images_not-matched-images_save-error': 'Erreur lors de l\'assignation des images',
  'catalog_handle-images_not-matched-images_search-placeholder': 'Rechercher des images par référence',
  'catalog_handle-images_not-matched-images_search-clear-btn': 'Effacer la recherche',
  'catalog_handle-images_not-matched-images_assign-product-btn': 'Assigner au produit',
  'catalog_handle-images_not-matched-images_cancel-product-selection': 'Annuler la sélection',
  // Catalog download
  'catalog_download_alert_brand-required': 'Veuillez sélectionner une marque',
  'catalog_download_alert_get-filters-error': 'Erreur lors de la récupération des filtres',
  'catalog_download_alert_dowloading-compressed-files': 'Téléchargement des fichiers compressés...',
  'catalog_download_progress-images': 'Images',
  'catalog_download_success-msg': 'Images téléchargées avec succès',
  'catalog_download_success-msg-imgs-and-csv': 'CSV et images téléchargés avec succès',
  'catalog_download_csv-msg-without-images': 'CSV téléchargé avec succès',
  'catalog_download_csv-msg-pending-images': 'CSV téléchargé avec succès, mais les images sont en attente',
  'catalog_download_csv-msg-pending-images-navigate': 'CSV téléchargé avec succès, mais les images sont en attente. Vous pouvez continuer à naviguer pendant le téléchargement',
  'catalog_download_apply-changes-btn': 'Appliquer les modifications',
  'catalog_download_cancel-filter-btn': 'Annuler',
  'catalog_download_cancel-values-selection-btn': 'Effacer la sélection',
  'catalog_download_cancel-values-download-btn': 'Annuler le téléchargement',
  'catalog_download_clear-filters-btn': 'Effacer les filtres',
  'catalog_download_result': 'résultat',
  'catalog_download_results': 'résultats',
  'catalog_download_columns_brand': 'Marque',
  'catalog_download_columns_reference': 'Référence',
  'catalog_download_columns_ean': 'EAN',
  'catalog_download_columns_size': 'Taille',
  'catalog_download_columns_name': 'Nom',
  'catalog_download_columns_color': 'Description de la couleur',
  'catalog_download_columns_color_code': 'Code couleur',
  'catalog_download_columns_pvi': 'PVI',
  'catalog_download_columns_pvpr': 'PVPR',
  'catalog_download_columns_division': 'Division',
  'catalog_download_columns_gender': 'Genre',
  'catalog_download_columns_season': 'Saison',
  'catalog_download_columns_segmentation': 'Segmentation',
  'catalog_download_columns_family': 'Famille',
  'catalog_download_columns_material': 'Matériau',
  'catalog_download_columns_images': 'Images',
  'catalog_download_columns_all-images': 'Tous les produits',
  'catalog_download_columns_with-images': 'Avec images',
  'catalog_download_columns_without-images': 'Sans images',
  'catalog_download_columns_favorites': 'Favoris',
  'catalog_download_columns_column-search_search': 'Rechercher',
  'catalog_download_columns_column-search_reset': 'Réinitialiser',
  'catalog_download_columns_column-search_close': 'Fermer',
  'catalog_download_list_select-all': 'Tout sélectionner',
  'catalog_download_list_clear-all': 'Effacer la sélection',
  'catalog_download_list_download-btn': 'Télécharger',
  'catalog_download_list_download-images-btn': 'Télécharger les images',
  'catalog_download_list_item': 'élément',
  'catalog_download_list_items': 'éléments',
  'catalog_download_list_select-order_popover-title': 'Sélectionnez et triez',
  ///////////////////////////

  // PRODUCT /////////////////
  'product_infoBox_codeEAN': 'Code EAN',
  'product_collection_title': 'Toute la collection',
  'product_infoProduct_other_colors': 'Autres couleurs',
  'product_infoProduct_currency': '€',
  'product_infoProduct_sizes': 'Tailles et prix',
  'product_infoProduct_EAN_size': 'Tailles',
  'product_infoProduct_EAN_code': 'Codes EAN',
  'product_collapse_title_key1': 'Tailles',
  'product_collapse_title_key2': 'INFORMATIONS GÉNÉRALES',
  'product_collapse_title_key3': 'COMPOSITION',
  'product_card-color': 'couleur',
  'product_mobile_product-details_sizes': 'Tailles et prix',
  'product_mobile_product-details_size': 'Taille',
  'product_mobile_product-details_info': 'Information Générale',
  'product_mobile_product-details_ean-codes': 'Codes EAN',
  'product_mobile_product-details_ean-code': 'Code EAN',
  'product_mobile_product-details_composition': 'Composition',
  'product_related-videos_title': 'Catalogue Multimédia',
  'product_related-videos_subtitle': 'Vidéos Associées',
  'product_related-products_title': 'Cela pourrait aussi vous intéresser...',
  'product_card_sold-units': 'Unités vendues:',
  // Edit product
  'product_edit-product_button': 'Modifier le produit',
  'product_edit-product_modal_title': 'Modifier',
  'product_edit-product_modal_season': 'Saison',
  'product_edit-product_modal_season_required': 'Veuillez entrer la saison',
  'product_edit-product_modal_color-name': 'Nom de la couleur',
  'product_edit-product_modal_color-name_required': 'Veuillez entrer le nom de la couleur',
  'product_edit-product_modal_color-code': 'Code de la couleur',
  'product_edit-product_modal_color-code_required': 'Veuillez entrer le code de la couleur',
  'product_edit-product_modal_gender': 'Genre',
  'product_edit-product_modal_gender_required': 'Veuillez entrer le genre',
  'product_edit-product_modal_family': 'Famille',
  'product_edit-product_modal_family_required': 'Veuillez entrer la famille',
  'product_edit-product_modal_division': 'Division',
  'product_edit-product_modal_division_required': 'Veuillez entrer la division',
  'product_edit-product_modal_segmentation': 'Segmentation',
  'product_edit-product_modal_segmentation_required': 'Veuillez entrer la segmentation',
  'product_edit-product_modal_material': 'Composition',
  'product_edit-product_modal_material_required': 'Veuillez entrer la composition',
  'product_edit-product_modal_cancel-button': 'Annuler',
  'product_edit-product_modal_save-button': 'Enregistrer',
  ///////////////////////////

  // THANKS PAGE ////////////
  'ThanksForRegister__register': 'ENREGISTREMENT DES MARQUES',
  'ThanksForRegister__title': 'Merci de votre intérêt pour Hub4Retail!',
  'ThanksForRegister__subtitle': 'Votre demande est en cours de traitement et nous vous contacterons sous peu.',
  'ThanksForRegister__button': 'Aller sur le site web',
  ///////////////////////////

  // REGISTER BRAND PAGE ////////////
  'RegisterBrand__title1': 'Prêt à commencer à',
  'RegisterBrand__title2': 'amener votre entreprise au',
  'RegisterBrand__title3': 'niveau suivant?',
  'RegisterBrand__subtitle': 'Plateforme conçue pour :',
  'RegisterBrand__ex1': 'Améliorer les outils de collaboration et de partage.',
  'RegisterBrand__ex2': 'Assurer la sécurité des données à tout moment.',
  'RegisterBrand__ex3': 'Augmenter les marges de profit.',
  'RegisterBrand__button': 'Retour',
  'RegisterBrand__register': 'Enregistrement',
  'registerBrand__form__name': 'Prénom',
  'registerBrand__form__lastName': 'Nom',
  'registerBrand__form__business': 'Raison Sociale',
  'registerBrand__form__company': 'Nom Commercial',
  'registerBrand__form__phone': 'Web',
  'registerBrand__form__email': 'Téléphone',
  'registerBrand__form__web': 'Email Corporatif',
  'registerBrand__form__description': 'Voulez-vous nous en dire plus?',
  'registerBrand__form__selector': 'Secteur',
  'registerBrand__form__say-more': 'Voulez-vous nous en dire plus?',
  'registerBrand__form__selector__optionDefault': '',
  'registerBrand__form__selector__option1': 'Chaussures',
  'registerBrand__form__selector__option2': 'Accessoires',
  'registerBrand__form__selector__option3': 'Maison-Jardin',
  'registerBrand__form__selector__option4': 'Beauté',
  'registerBrand__form__selector__option5': 'Électronique',
  'registerBrand__form__selector__option6': 'Sport',
  'registerBrand__form__selector__option7': 'Automobile',
  'registerBrand__form__selector__option8': 'Alimentaire',
  'registerBrand__form__selector__option9': 'Autre',
  ///////////////////////////

  // CLIENTS ///////////////
  'clients_by-client-btn': 'Par client',
  'clients_by-points-sale-btn': 'Par point de vente',
  'clients_add-client-link': '+ Ajouter un client',
  'clients_add-search-input': 'Rechercher...',
  'clients_filters-btn': 'Filtres',
  // Tables
  'clients_clients-table_businessName': 'Raison sociale',
  'clients_clients-table_tradeName': 'Nom commercial',
  'clients_clients-table_clientCode': 'Code client',
  'clients_clients-table_city': 'Ville',
  'clients_clients-table_totalPointsSale': 'Total des points de vente',
  'clients_clients-table_totalPointsSale_popover-title': 'Il y a des points de vente avec de nouvelles données',
  'clients_clients-table_totalPointsSale_popover-btn': 'Aller aux points de vente',
  'clients_clients-table_totalPointsSale_client-popover-title': 'a mis à jour les données',
  'clients_clients-table_totalPointsSale_client-popover-content': 'données ajoutées ou mises à jour',
  'clients_clients-table_totalPointsSale_client-popover-content-2': 'données ajoutées ou mises à jour',
  'clients_clients-table_totalPointsSale_client-popover-btn': 'Aller au client',
  'clients_client-table_status': 'Statut',
  'clients_client-table_action_see-profile': 'Voir le profil',
  'clients_client-table_action_remove-popconfirm_title': 'Rejeter l\'association',
  'clients_client-table_action_remove-popconfirm_description': 'Êtes-vous sûr de vouloir rejeter cette association ?',
  'clients_client-table_action_remove-popconfirm_on-ok': 'Accepter',
  'clients_client-table_action_remove-popconfirm_on-cancel': 'Annuler',
  'clients_client-table_action_reject-popconfirm_title_fr': 'Dissocier l\'Association',
  'clients_client-table_action_reject-popconfirm_description_fr': 'Êtes-vous sûr de vouloir dissocier cette association ?',
  'clients_client-table_action_reject-popconfirm_on-ok_fr': 'Accepter',
  'clients_client-table_action_reject-popconfirm_on-cancel_fr': 'Annuler',
  'client_clients-page_table_disassociate-modal_title_fr': 'Dissocier le Client',
  'client_clients-page_table_disassociate-modal_on-ok_fr': 'Dissocier',
  'client_clients-page_table_disassociate-modal_on-cancel_fr': 'Annuler',
  'client_clients-page_table_disassociate-modal_content_fr': 'Êtes-vous sûr de vouloir dissocier ce client et tous ses points de vente ?',
  'clients_sale-point-table_businessName': 'Raison sociale',
  'clients_sale-point-table_shop-name': 'Nom du magasin',
  'clients_sale-point-table_category': 'Catégorie',
  'clients_sale-point-table_tradeName': 'Nom commercial',
  'clients_sale-point-table_pointSaleCode': 'Code du point de vente',
  'clients_sale-point-table_city': 'Ville',
  'clients_sale-point-table_personae-and-tier': 'Personae et Tier',
  'clients_sale-point-table_share-data': 'Partage de données',
  'clients_sale-point-table_share-data-popover-share': 'Réception des données de vente du client.',
  'clients_sale-point-table_share-data-popover-no-share': 'Non réception des données de vente du client.',
  'clients_sale-point-table_recive-data': 'Réception de données',
  'clients_sale-point-table_recive-data_green_fr': 'Réception des données de vente du client.',
  'clients_sale-point-table_recive-data_orange_fr': 'Le client ne partage pas les données.',
  'clients_sale-point-table_recive-data_red_fr': 'Aucune donnée n\'est reçue de l\'objectif.',
  'clients_sale-point-table_connection': 'Connexion',
  'clients_sale-point-table_connection-popover-share': 'Connecté au terminal de point de vente du client.',
  'clients_sale-point-table_connection-popover-no-share': 'Non connecté au terminal de point de vente du client.',
  'clients_sale-point-table_active': 'Actif',
  'clients_sale-point-table_inactive': 'Inactif',
  'clients_sale-point-table_connected': 'Connecté',
  'clients_sale-point-table_not-connected': 'Non connecté',
  'clients_sale-point-table_total-clients': 'Total des clients',
  'clients_sale-point-table_total-sale-points': 'Total des points de vente',
  // Association
  'clients_association-modal_title': 'Associer un client',
  'clients_association-modal_associate-label_associate_txt-1': 'Associer:',
  'clients_association-modal_associate-label_associate_txt-2': 'l\'association est créée et les données du point de vente propre sont conservées.',
  'clients_association-modal_associate-label_associate-update_txt-1': 'Associer et mettre à jour:',
  'clients_association-modal_associate-label_associate-update_txt-2': 'l\'association est créée et les données du point de vente à associer sont copiées.',
  'clients_association-modal_update-label_associate_txt-1': 'Mettre à jour:',
  'clients_association-modal_update-label_associate_txt-2': 'les données du point de vente sont copiées avec le point de vente propre.',
  'clients_association-modal_update-label_associate-update_txt-1': 'Mettre à jour et associer:',
  'clients_association-modal_update-label_associate-update_txt-2': 'l\'association est créée et les données du point de vente à associer sont copiées.',
  'clients_pending-association-modal_title': 'Associations en attente',
  'clients_pending-association-modal_no-more': 'Ne plus afficher',
  'clients_pending-association-modal_close': 'Fermer',
  'clients_association-modal_on-ok': 'Accepter',
  'clients_association-modal_on-finish': 'Terminer',
  'clients_association-modal_on-next': 'Suivant',
  'clients_association-modal_on-cancel': 'Annuler',
  'clients_association-modal_ps_pdv-code_fr': 'Code PDV',
  'clients_association-modal_ps_associate_fr': 'Associer',
  'clients_association-modal_ps_update_fr': 'Mettre à jour',
  'clients_association-modal_ps_empty-ps_fr': 'Pas de nouveaux points de vente',
  'clients_association-table_item_client-code': 'Code Client',
  'clients_association-table_item_cif': 'Numéro CIF',
  'clients_association-table_item_trade-name': 'Nom Commercial',
  'clients_association-table_item_business-name': 'Raison Sociale',
  'clients_association-table_item_address': 'Adresse',
  'clients_association-table_item_postal-code': 'Code Postal',
  'clients_association-table_item_city': 'Ville',
  'clients_association-table_item_province': 'Province',
  'clients_association-table_item_country': 'Pays',
  'clients_association-table_item_contact_fr': 'Contact',
  'clients_association-table_item_email': 'Courriel',
  'clients_association-table_item_phone-number': 'Téléphone',
  'clients_association-steps_step-1': 'Informations Générales',
  'clients_association-steps_step-2': 'Points de Vente',
  // Add client form
  'clients_add-client-form-label_trade-name': 'Nom commercial',
  'clients_add-client-form-label_business-name': 'Raison sociale',
  'clients_add-client-form-label_client-code': 'Code client',
  'clients_add-client-form-label_clientCode': 'Code client',
  'clients_add-client-form-label_country': 'Pays',
  'clients_add-client-form-label_population': 'Ville',
  'clients_add-client-form-label_province': 'Province',
  'clients_add-client-form-label_address': 'Adresse',
  'clients_add-client-form-label_postal-code': 'Code postal',
  'clients_add-client-form-label_cif': 'Numéro de NIF (Numéro d\'Identification Fiscale)',
  'clients_add-client-form-label_phone-number': 'Numéro de téléphone',
  'clients_add-client-form-label_email': 'Email',
  'clients_add-client-form-input-validation_trade-name': 'Veuillez entrer le nom commercial',
  'clients_add-client-form-input-validation_business-name': 'Veuillez entrer la raison sociale',
  'clients_add-client-form-input-validation_country': 'Veuillez entrer le pays',
  'clients_add-client-form-input-validation_population': 'Veuillez entrer la ville',
  'clients_add-client-form-input-validation_address': `Veuillez entrer l'adresse`,
  'clients_add-client-form-input-validation_postal-code': 'Veuillez entrer le code postal',
  'clients_add-client-form-input-validation_cif': 'Veuillez entrer le numéro de NIF (Numéro d\'Identification Fiscale)',
  'clients_add-client-form-input-validation_email-required': `Veuillez entrer l'email`,
  'clients_add-client-form-input-validation_email': 'Email invalide',
  'clients_add-client-form-input-validation_phone-number': 'Veuillez entrer le numéro de téléphone',
  // Ajouter un client
  'clients_add-client_add-manually': 'Ajouter un client manuellement',
  'clients_add-client_add-by-csv-1': 'Importer un fichier CSV',
  'clients_add-client_add-by-csv-2': 'clients',
  'clients_add-client_add-by-excel': 'Télécharger le modèle Excel',
  // Menu client
  'clients_menu-desktop_resume': 'Résumé',
  'clients_menu-desktop_business-info': 'Informations sur l\'entreprise',
  'clients_menu-desktop_main-contacts': 'Contacts principaux',
  'clients_menu-desktop_sale-points': 'Points de vente',
  'clients_menu-desktop_commercial-info': 'Informations commerciales',
  // Client resume
  'client_resume_title': 'Catalogue disponible',
  'client_resume_main-info-panel_title': 'Informations générales',
  'client_resume_main-info-panel_li-info_client-code': 'Code client :',
  'client_resume_main-info-panel_li-info_trade-name': 'Nom commercial :',
  'client_resume_main-info-panel_li-info_web-page': 'Site Web:',
  'client_resume_main-info-panel_li-info_points-of-sale': 'Points de vente:',
  'client_resume_main-info-panel_li-info_email': 'E-mail :',
  'client_resume_main-info-panel_li-info_category': 'Catégorie :',
  'client_resume_main-info-panel_li-info_start-date': 'Date de début :',
  'client_resume_main-info-panel_li-info_start-season': 'Saison de début :',
  'client_resume_main-info-panel_li-info_phone-number': 'Téléphone :',
  'client_resume_main-info-panel_li-info_main-contact': 'Contact principal :',
  'client_resume_main-info-panel_li-info_contact-email': 'E-mail du contact :',
  // Business Info
  'client_business-info_edit-button': 'Modifier',
  'client_business-info_name': 'Nom commercial',
  'client_business-info_legal_name': 'Raison sociale',
  'client_business-info_cif': 'NIF (Numéro d\'Identification Fiscale)',
  'client_business-info_address': 'Adresse',
  'client_business-info_postal_code': 'Code postal',
  'client_business-info_city': 'Ville',
  'client_business-info_country': 'Pays',
  'client_business-info_province': 'Province',
  'client_business-info_email': 'E-mail',
  'client_business-info_contact_name': 'Contact Principal',
  'client_business-info_phoneNumber': 'Téléphone',
  'client_business-info_clientCode': 'Code client',
  'client_business-info_web': 'Site web',
  'client_business-info_floor': 'Étage',
  'client_business-info_door': 'Porte',
  'client_business-info_upgrade_all-popover_title': 'Mettre à jour les nouvelles données',
  'client_business-info_upgrade_popover_update': 'Mettre à jour',
  'client_business-info_upgrade_popover_update-all': 'Tout mettre à jour',
  'client_business-info_upgrade_update-field': 'champ à mettre à jour',
  'client_business-info_upgrade_update-fields': 'champs à mettre à jour',
  'client_business-info_upgrade_client-updates': 'a changé',
  'client_business-info_upgrade_new-value': 'Nouveau',
  'client_business-info_upgrade_new-actual': 'Actuel',
  // Client Contacts
  'client_business-client-contacts_title': 'Contacts principaux',
  'client_business-client-contacts_add-contact': '+ Ajouter un contact',
  'client_business-client-contacts_modal_title': 'Supprimer le contact',
  'client_business-client-contacts_modal_text': 'Voulez-vous supprimer le contact de la liste ? Cette action ne le supprime pas de vos contacts.',
  'client_business-client-contacts_modal_ok-text': 'Supprimer',
  'client_business-client-contacts_modal_cancel-text': 'Annuler',
  //////////////////////////

  // CLIENT ///////////////
  // Resume page
  'client_resume-page_history': 'Historique',
  'client_resume-page_contacts': 'Contacts',
  'client_resume-page_accept-association': 'Associer',
  'client_resume-page_reject-association': 'Rejeter',
  'client_resume-page_accept-association_btn-title': 'Accepter l\'association',
  'client_resume-page_reject-association_btn-title': 'Rejeter l\'association',
  'client_resume-page_client-brand-works_title': 'Marques avec lesquelles il travaille',
  'client-page_history-points-of-sale_associated': 'Associé',
  'client-page_history-points-of-sale_not-associated': 'Non associé',
  'client-page_history-active-contacts_name': 'Nom',
  'client-page_history-active-contacts_email': 'Email',
  'client-page_history-active-contacts_phone-number': 'Nº de téléphone',
  'client-page_history-active-contacts_department': 'Département',
  // Commercial info page
  'client_commercial-info_title': 'Informations Commerciales',
  'client_commercial-info_add-agent': '+ Ajouter un agent',
  'client_commercial-info_table_agent-name': 'Agent Commercial / Distributeur',
  'client_commercial-info_table_division': 'Division',
  'client_commercial-info_table_buy-group': 'Groupe d\'achat',
  'client_commercial-info_table_buy-group-true': 'Oui',
  'client_commercial-info_table_buy-group-false': 'Non',
  // Sale points page
  'client_sale-points-page_title': 'Points de Vente',
  'client_sale-points-page_add-sale-point': '+ Ajouter',
  'client_sale-points-page_associate-sale-point': 'Point de vente associé',
  'client_sale-points-page_table_client-code': 'Code',
  'client_sale-points-page_table_address': 'Adresse',
  'client_sale-points-page_table_city': 'Ville',
  'client_sale-points-page_table_division': 'Division',
  'client_sale-points-page_table_personae': 'Personae',
  'client_sale-points-page_table_tier': 'Niveau',
  'client_sale-points-page_table_pdv': 'Photos du Point de Vente',
  'client_sale-points-page_table_name': 'Nom',
  'client_sale-points-page_table_area': 'Surface commerciale (M2)',
  'client_sale-points-page_table_category': 'Catégorie',
  'client_sale-points-page_table_brands': 'Marques',
  'client_sale-points-page_table_disassociate-modal_title_fr': 'Dissocier le Point de Vente',
  'client_sale-points-page_table_disassociate-modal_on-ok_fr': 'Dissocier',
  'client_sale-points-page_table_disassociate-modal_on-cancel_fr': 'Annuler',
  'client_sale-points-page_table_disassociate-modal_content_fr': 'Êtes-vous sûr de vouloir dissocier ce point de vente ?',
  'client_sale-points-page_table_associate-modal_title_fr': 'Lier les Points de Vente',
  'client_sale-points-page_table_associate-modal_on-ok_fr': 'Mettre à jour',
  'client_sale-points-page_table_associate-modal_on-cancel_fr': 'Annuler',
  'client_sale-points-page_table_status_associated': 'Associé',
  'client_sale-points-page_table_status_matching': 'En attente',
  'points-of-sale_drawer_exchange_label': 'Type de monnaie',
  'points-of-sale_drawer_add-edit-sale-point_exchange_input-validation': 'Veuillez entrer le type de monnaie',
  'client_sale-points-page_table_status_not-associated': 'Non Associé',
  'client_sale-points-page_upgrade_online': 'Magasin physique',
  'client_sale-points-page_upgrade_category_id': 'Catégorie',
  'client_sale-points-page_upgrade_name': 'Nom du magasin',
  'client_sale-points-page_upgrade_exchange_id': 'Type de devise',
  'client_sale-points-page_upgrade_area': 'Zone commerciale',
  'client_sale-points-page_upgrade_address': 'Adresse',
  'client_sale-points-page_upgrade_floor': 'Étage',
  'client_sale-points-page_upgrade_door': 'Porte',
  'client_sale-points-page_upgrade_city': 'Ville',
  'client_sale-points-page_upgrade_province': 'Province',
  'client_sale-points-page_upgrade_postal_code': 'Code postal',
  'client_sale-points-page_upgrade_country_id': 'Pays',
  'client_sale-points-page_upgrade_lat': 'Latitude',
  'client_sale-points-page_upgrade_lng': 'Longitude',
  'client_sale-points-page_upgrade_tel_prefix': 'Préfixe téléphonique',
  'client_sale-points-page_upgrade_tel_sufix': 'Numéro de téléphone',
  'client_sale-points-page_upgrade_web': 'Web',
  'client_sale-points-page_upgrade_popconfirm-title': 'Mettre à jour les données des points de vente',
  'client_sale-points-page_upgrade_popconfirm-description': 'Êtes-vous sûr de vouloir mettre à jour les données de tous les points de vente ?',
  'client_sale-points-page_upgrade_upgrade-btn': 'Mettre à jour les points de vente',
  // Point of sale info modal
  'points-of-sale_point-of-sale-info_close-btn': 'Fermer',
  // Info
  'points-of-sale_point-of-sale-info_info_title': 'Informations',
  'points-of-sale_point-of-sale-info_info_code': 'Code',
  'points-of-sale_point-of-sale-info_info_store-type': 'Type de magasin',
  'points-of-sale_point-of-sale-info_info_store-type_online': 'En ligne',
  'points-of-sale_point-of-sale-info_info_store-type_physic': 'Physique',
  'points-of-sale_point-of-sale-info_info_category': 'Catégorie',
  'points-of-sale_point-of-sale-info_info_web': 'Page Web',
  'points-of-sale_point-of-sale-info_info_exchange-type': 'Type de devise',
  'points-of-sale_point-of-sale-info_info_start-season': 'Début de saison',
  'points-of-sale_point-of-sale-info_info_division': 'Division',
  'points-of-sale_point-of-sale-info_info_segmentation': 'Personae',
  'points-of-sale_point-of-sale-info_info_tier': 'Niveau',
  // Connection
  'points-of-sale_point-of-sale-info_connection_title': 'Connexion',
  'points-of-sale_point-of-sale-info_connection_no-associated': 'Aucune association avec le point de vente',
  'points-of-sale_point-of-sale-info_connection_meta-connection': 'Connexion avec Meta',
  'points-of-sale_point-of-sale-info_connection_meta-connection_true': 'Connecté',
  'points-of-sale_point-of-sale-info_connection_meta-connection_false': 'Non connecté',
  'points-of-sale_point-of-sale-info_connection_meta-receiving': 'Reçoit des données de Meta',
  'points-of-sale_point-of-sale-info_connection_meta-receiving_true': 'Reçoit',
  'points-of-sale_point-of-sale-info_connection_meta-receiving_false': '24h sans réception',
  'points-of-sale_point-of-sale-info_connection_data-share': 'Le client partage des données',
  'points-of-sale_point-of-sale-info_connection_data-share_true': 'Partage',
  'points-of-sale_point-of-sale-info_connection_data-share_false': 'Ne partage pas',
  // Contact
  'points-of-sale_point-of-sale-info_contact_title': 'Contact',
  'points-of-sale_point-of-sale-info_contact_contact-name': 'Personne de contact',
  'points-of-sale_point-of-sale-info_contact_phone-number': 'Téléphone de contact',
  'points-of-sale_point-of-sale-info_contact_email': 'Email',
  'points-of-sale_point-of-sale-info_contact_address': 'Adresse',
  // Brand works
  'points-of-sale_point-of-sale-info_contact_brand-works_title_0': 'marques à',
  'points-of-sale_point-of-sale-info_contact_brand-works_title_1': 'marque à',
  'points-of-sale_point-of-sale-info_contact_brand-works_empty': 'Pas encore de marques au point de vente',
  // Drawers
  // Sale points
  'client_drawer_add-edit-sale-point_title': 'Données de point de vente',
  'client_drawer_add-edit-sale-point_name': 'Nom',
  'client_drawer_add-edit-sale-point_point-of-sale-type_fr': 'Type de Point de Vente',
  'client_drawer_add-edit-sale-point_point-of-sale-type_physical_fr': 'Magasin Physique',
  'client_drawer_add-edit-sale-point_point-of-sale-type_online_fr': 'Magasin en Ligne',
  'client_drawer_add-edit-sale-point_category_fr': 'Catégorie',
  'client_drawer_add-edit-sale-point_client-code': 'Code',
  'client_drawer_add-edit-sale-point_client-area': 'Surface commerciale (M2)',
  'client_drawer_add-edit-sale-point_address': 'Adresse',
  'client_drawer_add-edit-sale-point_postal-code': 'Code Postal',
  'client_drawer_add-edit-sale-point_city': 'Ville',
  'client_drawer_add-edit-sale-point_country': 'Pays / Région',
  'client_drawer_add-edit-sale-point_province': 'État / Province',
  'client_drawer_add-edit-sale-point_name_input-validation': 'Veuillez entrer le nom du point de vente',
  'client_drawer_add-edit-sale-point_category_input-validation_fr': 'Veuillez saisir la catégorie du point de vente',
  'client_drawer_add-edit-sale-point_client-code_input-validation': 'Veuillez entrer le code client',
  'client_drawer_add-edit-sale-point_address_input-validation': 'Veuillez entrer l\'adresse',
  'client_drawer_add-edit-sale-point_postal-code_input-validation': 'Veuillez entrer le code postal',
  'client_drawer_add-edit-sale-point_city_input-validation': 'Veuillez entrer la ville',
  'client_drawer_add-edit-sale-point_country_input-validation': 'Veuillez entrer le pays / la région',
  'client_drawer_add-edit-sale-point_province_input-validation': 'Veuillez entrer l\'État / la Province',
  'client_drawer_add-edit-sale-point_division': 'Division',
  'client_drawer_add-edit-sale-point_personae': 'Personae',
  'client_drawer_add-edit-sale-point_tier': 'Niveau',
  'client_drawer_add-edit-sale-point_share-sale-point-title': 'Classification du Point de Vente',
  'client_drawer_add-edit-sale-point_share-sale-point-data': 'Souhaitez-vous partager des données avec ce point de vente?',
  'client_drawer_add-edit-sale-point_share-sale-point-data-true': 'Oui',
  'client_drawer_add-edit-sale-point_share-sale-point-data-false': 'Non',
  // Commercial info
  'client_drawer_client-commercial-info_title': 'Assigner un Agent',
  'client_drawer_client-commercial-info_select': 'Agents',
  'client_drawer_client-commercial-info_select-placeholder': 'Sélectionnez des agents',
  'client_drawer_client-commercial-info_add-agent': '+ Ajouter un agent',
  // Client contacts
  'client_drawer_client-contacts_title': 'Contacts Principaux',
  // Edit client
  'client_drawer_edit-client_title': 'Données de l\'entreprise',
  ///////////////////////////

  // ACTIONS ////////////////
  // Clients
  'clients_actions_add-client-success': 'Client créé avec succès',
  'clients_actions_update-client-success': 'Client mis à jour avec succès',
  'clients_actions_update-client-error': 'Erreur lors de la mise à jour du client',
  'clients_actions_client-exists': 'Le client existe déjà',
  'clients_actions_add-point-of-sale-success': 'Point de vente créé avec succès',
  'clients_actions_add-point-of-sale-error': 'Erreur lors de la création du point de vente',
  'clients_actions_update-point-of-sale-success': 'Point de vente mis à jour avec succès',
  'clients_actions_upgrade-point-of-sale-success': 'Point de vente mis à jour avec succès',
  'clients_actions_upgrade-points-of-sale-success': 'Points de vente mis à jour avec succès',
  'clients_actions_update-point-of-sale-error': 'Erreur lors de la mise à jour du point de vente',
  'clients_actions_share-data-point-of-sale-success': 'Partage de données avec le point de vente',
  'clients_actions_dont-share-data-point-of-sale-success': 'Vous avez cessé de partager des données avec le point de vente',
  'clients_actions_association-success_fr': 'Client associé avec succès',
  'clients_actions_association-error_fr': 'Erreur lors de l\'association du client',
  'clients_actions_reject-association-success_fr': 'Dissociation réussie',
  'clients_actions_reject-association-error_fr': 'Erreur lors de la dissociation du client',
  'clients_actions_accept-ps-association-success_fr': 'Point de vente lié avec succès',
  'clients_actions_accept-ps-association-error_fr': 'Erreur lors de la dissociation du point de vente',
  // Commercial Profile
  'commercial-profile_actions_init-bio_error': 'Erreur lors de la récupération de la biographie',
  'commercial-profile_actions_update-bio_success': 'Biographie mise à jour avec succès',
  'commercial-profile_actions_update-bio_error': 'Erreur lors de la mise à jour de la biographie',
  // Catalog Correlation
  'catalog-correlation_assign-generics_success': 'Génériques attribués avec succès',
  'catalog-correlation_assign-generics_error': 'Erreur lors de l\'attribution des génériques',
  // Catalog CSV upload
  'catalog-csv-upload_publish-upload-success-1': 'Catalogue publié avec succès',
  'catalog-csv-upload_publish-upload-success-2': 'Catalogue masqué avec succès',
  'catalog-csv-upload_publish-upload-error-1': 'Erreur lors de la publication du catalogue',
  'catalog-csv-upload_publish-upload-error-2': 'Erreur lors du masquage du catalogue',
  'catalog-csv-upload_reject-upload-success': 'Catalogue supprimé avec succès',
  'catalog-csv-upload_reject-upload-error': 'Erreur lors de la suppression du catalogue',
  'catalog-csv-upload_set-start-season-date-success': 'Date de début de la saison mise à jour avec succès',
  'catalog-csv-upload_set-start-season-date-error': 'Erreur lors de la mise à jour de la date de début de la saison',
  'catalog-csv-upload_set-end-season-date-success': 'Date de fin de la saison mise à jour avec succès',
  'catalog-csv-upload_set-end-season-date-error': 'Erreur lors de la mise à jour de la date de fin de la saison',
  'catalog-csv-upload_enable-season-success-1': 'Saison activée avec succès',
  'catalog-csv-upload_enable-season-success-2': 'Saison désactivée avec succès',
  'catalog-csv-upload_enable-season-error-1': 'Erreur lors de l\'activation de la saison',
  'catalog-csv-upload_enable-season-error-2': 'Erreur lors de la désactivation de la saison',
  'catalog-csv-upload_get-mappings-error-1': 'Erreur lors de la récupération des clés de correspondance',
  'catalog-csv-upload_get-mappings-error-2': 'Erreur du serveur lors de la récupération des clés de correspondance',
  'catalog-csv-upload_delete-mapping-success': 'Correspondance supprimée avec succès',
  'catalog-csv-upload_delete-mapping-error': 'Erreur lors de la suppression de la correspondance',
  'catalog-csv-upload_create-mapping-success': 'Correspondance créée avec succès',
  'catalog-csv-upload_create-mapping-error': 'Erreur lors de la création de la correspondance',
  // Commercial profile
  'commercial-proile_actions_set-banner-action-success': 'Bannière mise à jour avec succès',
  'commercial-proile_actions_set-banner-action-error': 'Erreur lors de la mise à jour de la bannière',
  'commercial-proile_actions_set-banner-action-server-error': 'Erreur du serveur lors de la mise à jour de la bannière',
  'commercial-proile_actions_set-products-action-success': 'Produits mis à jour avec succès',
  'commercial-proile_actions_set-products-action-error': 'Erreur lors de la mise à jour des produits',
  'commercial-proile_actions_set-products-action-server-error': 'Erreur du serveur lors de la mise à jour des produits',
  'commercial-proile_actions_remove-product-action-error': 'Erreur lors de la suppression du produit',
  'commercial-proile_actions_remove-product-action-server-error': 'Erreur du serveur lors de la suppression du produit',
  'commercial-proile_actions_set-videos-action-success': 'Vidéos mises à jour avec succès',
  'commercial-proile_actions_set-videos-action-error': 'Erreur lors de la mise à jour des vidéos',
  'commercial-proile_actions_set-videos-action-server-error': 'Erreur du serveur lors de la mise à jour des vidéos',
  'commercial-proile_actions_remove-video-action-error': 'Erreur lors de la suppression de la vidéo',
  'commercial-proile_actions_remove-video-action-server-error': 'Erreur du serveur lors de la suppression de la vidéo',
  ///////////////////////////

  // SETTINGS PAGE ////////
  'settings-page_nav_chronos': 'Chronos',
  'settings-page_nav_devices': 'Dispositifs',
  'settings-page_nav_touch-screen': 'Écrans Tactiles',
  'settings-page_nav_users': 'Utilisateurs',
  'settings-page_nav_system-settings': 'Paramètres du Système',
  'settings-page_nav_commercial-profile': 'Profil Commercial de la Marque',
  'settings-page_nav_billing': 'Facturation',
  'settings-page_nav_support': 'Support',
  // Users page
  'settings-page_users-page_create-user-btn': 'Créer un utilisateur',
  /////////////////////////

  // COMMERCIAL PROFILE ////////////
  'commercial-profile_title': 'Profil Commercial',
  'commercial-profile_btn-edit': 'Éditer',
  'commercial-profile_btn-save': 'Sauvegarder',
  'commercial-profile_banner_title': 'Télécharger une Bannière',
  'commercial-profile_banner_p-1': 'La bannière apparaîtra sur le profil commercial de votre marque pour la promouvoir et vous connecter avec les magasins multimarques.',
  'commercial-profile_banner_p-2': 'Téléchargez une bannière de 1800x200 px.',
  'commercial-profile_banner_p-3': 'Assurez-vous qu’elle soit de bonne qualité et attrayante pour attirer les magasins multimarques.',
  'commercial-profile_about-us_title': 'À Propos de Nous',
  'commercial-profile_about-us_p-1': 'Parlez un peu de votre marque pour attirer les magasins multimarques. Jusqu’à un maximum de 100 mots.',
  'commercial-profile_about-us_p-2': 'La description apparaîtra sur le profil commercial de votre marque pour la promouvoir et vous connecter avec les magasins multimarques.',
  'commercial-profile_our-products_title': 'Nos Produits',
  'commercial-profile_our-products_p': 'Sélectionnez 5-10 produits de votre dernière saison.',
  'commercial-profile_our-videos_title': 'Quelques-unes de nos vidéos...',
  'commercial-profile_our-videos_p': 'Sélectionnez 4 produits de votre dernière saison.',
  'commercial-profile_banner_modal_title': 'Sélectionner une bannière',
  'commercial-profile_modal_onOk': 'Accepter',
  'commercial-profile_modal_onCancel': 'Annuler',
  'commercial-profile_banner-title': 'Sélectionnez une bannière pour votre profil commercial',
  'commercial-profile_modal-content_banners-empty-msg': 'Il n\'y a pas encore de bannières.',
  'commercial-profile_modal-content_navigate-banners-btn': 'Aller aux bannières multimédia',
  'commercial-profile_modal-content_videos-empty-msg': 'Il n\'y a pas encore de vidéos.',
  'commercial-profile_modal-content_navigate-videos-btn': 'Aller aux vidéos multimédia',
  'commercial-profile_our-products_modal_title': 'Sélectionner des produits',
  'commercial-profile_our-products_modal_max-items-msg': 'Vous ne pouvez sélectionner que 10 produits.',
  'commercial-profile_our-products_modal_see-more-btn': 'Voir plus',
  'commercial-profile_our-videos_modal_title': 'Sélectionner des vidéos',
  'commercial-profile_our-videos_modal_max-items-msg': 'Vous ne pouvez sélectionner que 4 vidéos.',
  'commercial-profile_search-input-placeholder_fr': 'Rechercher par nom ou code',
  //////////////////////////////////

  // NOTIFICATIONS /////////////////
  // Notifications Page
  'notifications-page_notifications-title': 'Notifications',
  'notifications-page_alerts-title': 'Alertes',
  'notifications_btn-name_catalog': 'Voir le Catalogue',
  'notifications_btn-name_profile': 'Voir le Profil',
  'notifications_list_show-all': 'Tout',
  'notifications_list_show-unreaded': 'Non lus',
  'notifications_list_per-page-select': 'par page',
  // Popover
  'notifications_popover_title': 'NOTIFICATIONS',
  'notifications_popover_empty-notifications': 'Pas de notifications',
  'notifications_popover_btn-name_catalog': 'Voir le Catalogue',
  'notifications_popover_btn-name_profile': 'Voir le Profil',
  'notifications_popover_item_mark-as-readed_msg': 'Marqué comme lu',
  'notifications_popover_item_mark-as-unreaded_msg': 'Marqué comme non lu',
  'notifications_popover_item_mark-as-readed': 'Marquer comme lu',
  'notifications_popover_item_mark-as-unreaded': 'Marquer comme non lu',
  'notifications_popover_all-notifications-btn': 'Toutes les notifications',
  'notifications_popover_accept': 'Accepter',
  'notifications_popover_reject': 'Rejeter',
  'notifications_popover_pending_fr': 'En attente',
  'alerts_popover_title': 'ALERTES',
  'alerts_popover_empty-notifications': 'Pas d\'alertes',
  'alerts_popover_all-notifications-btn': 'Toutes les alertes',
  ///////////////////////////////////

  // WHOLESALE FEED /////////////////
  'wholesale-feed_info_title': 'Informations Générales',
  'wholesale-feed_info_city': 'Ville',
  'wholesale-feed_info_province': 'Province',
  'wholesale-feed_main-contact_title': 'Contact Principal',
  'wholesale-feed_main-contact_name': 'Nom',
  'wholesale-feed_main-contact_email': 'E-mail',
  'wholesale-feed_main-contact_phone-number': 'Numéro de Téléphone',
  'wholesale-feed_main-contact_sector': 'Secteur',
  'wholesale-feed_points-of-sale_title': 'Points de Vente',
  'wholesale-feed_working-brands_title': 'Marques Commercialisées',
  ///////////////////////////////////

  // MESSAGE PAGES //////////
  // Error
  'errors-page_404_subtitle': 'Désolé, la page que vous recherchez n\'existe pas.',
  'errors-page_404_btn-go-back': 'Retourner',
  // Coming soon
  'coming-soon-page_title': 'Bientôt disponible!',
  'coming-soon-page_go-back': 'Retour',
  /////////////////////////

  // BREADCRUMBS ///////////////
  'breadcrumbs_multimedia': 'Multimédia',
  'breadcrumbs_multimedia-videos': 'Vidéos',
  'breadcrumbs_multimedia-images': 'Images',
  'catalog_breadcrumb_item1': 'Catalogue',
  'catalog_breadcrumb_item2': 'Voir le catalogue',
  'breadcrumbs_multimedia-banners': 'Bannières',
  'catalog-uploads_breadcrumb': 'Téléversement de catalogues',
  'catalog-uploads_handle-images_breadcrumb': 'Gestion des images',
  'breadcrumb_catalog-download': 'Télécharger le catalogue',
  'catalog-uploads-preview_breadcrumb': 'Aperçu',
  'add-catalog_breadcrumb': 'Ajouter un catalogue',
  'catalog_correlation': 'Corrélation de références',
  'catalog_generic': 'Génériques H4R',
  // Clients
  'breadcrumbs_clients': 'Clients',
  'breadcrumbs_clients-list': 'Liste des clients',
  'breadcrumbs_add-client': 'Ajouter un client',
  'breadcrumbs_resume': 'Résumé',
  'breadcrumbs_business-info': 'Informations sur l\'entreprise',
  'breadcrumbs_main-contacts': 'Contacts principaux',
  'breadcrumbs_sale-points': 'Points de vente',
  'breadcrumbs_commercial-info': 'Informations commerciales',
  // Account
  'breadcrumbs_account': 'Compte',
  'breadcrumbs_account_profile': 'Profil',
  'breadcrumbs_account_company': "Informations sur l'entreprise",
  'breadcrumbs_account_contacts': 'Contacts Principaux',
  'breadcrumbs_account_commercial-info': 'Informations Commerciales',
  // Settings
  'breadcrumbs_settings': 'Paramètres',
  'breadcrumbs_commercial-profile': 'Profil Commercial',
  //////////////////////////////

  // MODALS ///////////////////
  'modal_email-verified_title': 'Vérifiez votre e-mail',
  'modal_email-verified_content': 'Pour continuer le processus, veuillez vérifier votre e-mail.',
  'modal_email-verified_content-1': "Vérifiez l'adresse à partir du lien reçu par email, puis cliquez sur",
  'modal_email-verified_content_btn_resend': 'Renvoyer la vérification',
  'modal_email-verified_content_btn_reload': 'Recharger',
  'modal_email-verified_content_notif_resend_title': 'Notification de renvoi',
  'modal_email-verified_content_notif_resend_content-1': 'L\'email de vérification a été envoyé à cette adresse',
  'modal_email-verified_content_notif_resend_content-2': 'Vérifiez votre email pour continuer.',
  /////////////////////////////

  // POLICY PRIVACY //////////
  'policy-privacy-modal_title': 'Conditions d\'utilisation',
  'policy-privacy-modal_btn-accept': 'Accepter',
  'policy-privacy-modal_btn-cancel': 'Annuler',
  'policy-privacy-modal_checkbox': 'J\'accepte les Conditions d\'utilisation',
  'policy-privacy-modal_checkbox-message': 'Acceptez la politique de confidentialité et les conditions.',
  ////////////////////////////

  // SERVER DOWN MODAL /////
  'server-down-modal_title': 'Erreur du serveur',
  'server-down-modal_content': 'Nous rencontrons des problèmes avec nos services. Veuillez réessayer plus tard ou contacter le support technique.',
  //////////////////////////

  // NOTIFICATIONS /////////
  'notification-error_message': 'Code d\'erreur',
  'notification-error_description': 'Veuillez réessayer plus tard. Si le problème persiste, contactez Hub4Retail et fournissez le code d\'erreur.',
  //////////////////////////

  // GOOGLE ADDRESS FORM //////
  'google-address-form_address': 'Adresse',
  'google-address-form_floor': 'Étage',
  'google-address-form_door': 'Porte',
  'google-address-form_postal-code': 'Code postal',
  'google-address-form_city': 'Ville',
  'google-address-form_province': 'Province',
  'google-address-form_country': 'Pays',
  'google-address-form_select-country': 'Sélectionnez un pays',
  // Countries
  'google-address-form_country_spain': 'Espagne',
  'google-address-form_country_italy': 'Italie',
  'google-address-form_country_france': 'France',
  'google-address-form_country_portugal': 'Portugal',
  'google-address-form_country_united kingdom': 'Royaume-Uni',
  'google-address-form_country_afghanistan': 'Afghanistan',
  'google-address-form_country_brazil': 'Brésil',
  'google-address-form_country_canada': 'Canada',
  'google-address-form_country_india': 'Inde',
  'google-address-form_country_japan': 'Japon',
  'google-address-form_country_kenya': 'Kenya',
  'google-address-form_country_mexico': 'Mexique',
  'google-address-form_country_nigeria': 'Nigéria',
  'google-address-form_country_philippines': 'Philippines',
  'google-address-form_country_russia': 'Russie',
  'google-address-form_country_south africa': 'Afrique du Sud',
  'google-address-form_country_thailand': 'Thaïlande',
  'google-address-form_country_turkey': 'Turquie',
  'google-address-form_country_argentina': 'Argentine',
  'google-address-form_country_bangladesh': 'Bangladesh',
  'google-address-form_country_colombia': 'Colombie',
  'google-address-form_country_egypt': 'Égypte',
  'google-address-form_country_indonesia': 'Indonésie',
  'google-address-form_country_iran': 'Iran',
  'google-address-form_country_poland': 'Pologne',
  'google-address-form_country_vietnam': 'Vietnam',
  'google-address-form_country_south korea': 'Corée du Sud',
  /////////////////////////////

  // CSV MAPPING FIELDS //////
  'csv-mapping-feld__product_description': 'Description du produit',
  'csv-mapping-feld__reference': 'Référence',
  'csv-mapping-feld__color': 'Couleur',
  'csv-mapping-feld__season': 'Saison',
  'csv-mapping-feld__ean': 'EAN',
  'csv-mapping-feld__gender': 'Genre',
  'csv-mapping-feld__material': 'Composition',
  'csv-mapping-feld__segmentation': 'Segmentation',
  'csv-mapping-feld__family': 'Famille',
  'csv-mapping-feld__division': 'Division',
  'csv-mapping-feld__tier': 'Segmentation de catalogue (Tier)',
  'csv-mapping-feld__color_description': 'Description de la couleur',
  'csv-mapping-feld__pvi': 'WHP (Prix de gros)',
  'csv-mapping-feld__pvpr': 'PVP (Prix de vente conseillé)',
  'csv-mapping-feld__size': 'Taille',
  'csv-mapping-feld__tags': 'Étiquettes',
  /////////////////////////////

  // LANGUAGES /////////////////
  'spanish-lang': 'Espagnol',
  'english-lang': 'Anglais',
  'french-lang': 'Français',
  'italian-lang': 'Italien',
  ////////////////////////////

  // SHARED //////////////////
  'settings': 'Paramètres',
  'logout': 'Déconnexion',
  'admin-panel_link': 'Panneau d\'administration',
  'see-more': 'Voir plus',
  'see-less': 'Voir moins',
  'see-all': 'Voir tout',
  'save-btn': 'Enregistrer',
  'edit-btn-label': 'Éditer',
  'delete-btn-label': 'Supprimer',
  'cancel-btn': 'Annuler',
  'see-more-content-button': 'Voir plus',
  'never-label': 'Jamais',
  'clean-form': 'Effacer le formulaire',
  ////////////////////////////
}

export default franceTranslation;