import { createMultimediaAPI, findMultimediaAPI, removeMultimediaAPI } from 'api/multimedia.api';
import * as Types from './multimedia.types'
import { messageError, messageLoading, messageSuccess } from 'views/components/UI/message';
import { notificationErr } from 'views/components/UI/notification';
import { multimediaError } from 'utils/errorCodes';
import { CreateMultimediaBody, PostMultimediaResponseState, MultimediaTypes, MultimediaItemState, TMediaState, IProductItemState } from 'interfaces/multimedia.interface';
import { videoType } from 'views/pages/main/multimediaPage/components/constants';
import { ICompanyState } from 'interfaces/user.interface';
import { TProductAttributeItem, TProductAttributes } from 'interfaces/catalog.interface';

export async function getMediaAction(dispatch: any, multimediaState: TMediaState, companyBrandId: string, type: MultimediaTypes, signal: AbortSignal | undefined, translate: any, token: string) {
	dispatch({ type: Types.SET_IS_LOADING_MEDIA, payload: { type: type, isLoading: true } })
	try {
		multimediaState.bodyMultimediaFetch.options.brand_id = [companyBrandId]
		const mediaFetch = await findMultimediaAPI(multimediaState.bodyMultimediaFetch, signal, token)
		const mediaData = mediaFetch.data as MultimediaItemState[]
		if (mediaFetch.response.status === 200) {
			return dispatch({
				type: Types.GET_MULTIMEDIA,
				payload: { type: type, items: mediaData }
			})
		}
	} catch (err: any) {
		if (err.code !== 20) {
			return notificationErr(multimediaError.get_multimedia_001, translate)
		}
	} finally {
		dispatch({ type: Types.SET_IS_LOADING_MEDIA, payload: { type: type, isLoading: false } })
	}
}

export async function getMoreMediaAction(dispatch: any, multimediaState: TMediaState, companyBrandId: string, type: MultimediaTypes, signal: AbortSignal | undefined, translate: any, token: string) {
	dispatch({ type: Types.SET_IS_LOADING_MORE_MEDIA, payload: { type: type, isLoading: true } })
	try {
		multimediaState.bodyMultimediaFetch.options.brand_id = [companyBrandId]
		const mediaFetch = await findMultimediaAPI(multimediaState.bodyMultimediaFetch, signal, token)
		const mediaData = mediaFetch.data as MultimediaItemState[]
		if (mediaFetch.response.status === 200) {
			return dispatch({
				type: Types.GET_MULTIMEDIA,
				payload: { type: type, items: mediaData }
			})
		}
	} catch (err: any) {
		if (err.code !== 20) {
			return notificationErr(multimediaError.get_more_multimedia_001, translate)
		}
	} finally {
		dispatch({ type: Types.SET_IS_LOADING_MORE_MEDIA, payload: { type: type, isLoading: false } })
	}
}

export async function createMultimediaAction(dispatch: any, company: ICompanyState, type: MultimediaTypes, body: CreateMultimediaBody, media: any, videoThumbnailMedia: any, setCurrentStep: any, productAttributes: TProductAttributes, products: IProductItemState[], translate: any, token: string) {
	dispatch({ type: Types.SET_IS_LOADING_CREATE_MULTIMEDIA, payload: true })
	try {
		const mediaFetch = await createMultimediaAPI(body, token)
		const mediaFetchData = mediaFetch.data as PostMultimediaResponseState
		if (mediaFetch.response.status === 200) {
			const { upload_url } = mediaFetchData
			const mediaBlob = await (media.originFileObj as File).arrayBuffer();
			try {
				const uploadMedia = await fetch(upload_url, {
					method: 'PUT',
					body: new Blob([new Uint8Array(mediaBlob)], { type: media.originFileObj.type })
				})
				if (uploadMedia.status !== 200) {
					notificationErr(multimediaError.create_multimedia_004, translate)
					messageError('Error al subir el archivo multimedia.')
					return
				}
				if (type === videoType && videoThumbnailMedia) {
					const { thumbnail_url } = mediaFetchData
					const thumbnailBlob = await (videoThumbnailMedia.originFileObj ?? videoThumbnailMedia as File).arrayBuffer();
					const uploadThumbnail = await fetch(thumbnail_url as string, {
						method: 'PUT',
						body: new Blob([new Uint8Array(thumbnailBlob)], { type: videoThumbnailMedia?.originFileObj?.type ?? videoThumbnailMedia.type })
					})
					if (uploadThumbnail.status !== 200) {
						notificationErr(multimediaError.create_multimedia_003, translate)
						messageError('Error al subir el archivo multimedia.')
						return
					}
					insertMultimedia({ dispatch, data: mediaFetchData, type, company, body, productAttributes, products })
					messageSuccess('Multimedia creado correctamente.')
					setCurrentStep(2)
					return
				}
				insertMultimedia({ dispatch, data: mediaFetchData, type, company, body, productAttributes, products })
				messageSuccess('Multimedia creado correctamente.')
				setCurrentStep(2)
				return
			} catch (error) {
				notificationErr(multimediaError.create_multimedia_002, translate)
				messageError('Error al subir el archivo multimedia.')
			}
		}
	} catch (error) {
		notificationErr(multimediaError.create_multimedia_001, translate)
		messageError('Error del servidero al subir multimedia.')
	} finally {
		dispatch({ type: Types.SET_IS_LOADING_CREATE_MULTIMEDIA, payload: false })
	}
}

function insertMultimedia({ dispatch, data, type, company, body, productAttributes, products }: { dispatch: any, data: PostMultimediaResponseState, type: MultimediaTypes, company: ICompanyState, body: CreateMultimediaBody, productAttributes: TProductAttributes, products: IProductItemState[] }) {
	const divisions: (TProductAttributeItem | undefined)[] = body?.multimedia_division
  ?.map(item => productAttributes.division.find(division => division.id === item))
  .filter(Boolean) ?? [];
	const seasons: (TProductAttributeItem | undefined)[] = body?.multimedia_season
	?.map(item => productAttributes.season.find(season => season.id === item))
	.filter(Boolean) ?? [];
	const genders: (TProductAttributeItem | undefined)[] = body?.multimedia_gender
	?.map(item => productAttributes.gender.find(gender => gender.id === item))
	.filter(Boolean) ?? [];
	
	const newMultimedia = {
		id: data.multimedia.id,
		name: data.multimedia.name,
		title: data.multimedia.title,
		description: data.multimedia.description,
		file_type: data.multimedia.file_type,
		multimedia_type: data.multimedia.multimedia_type,
		url: data.multimedia.url,
		enable_download: data.multimedia.enable_download,
		start: data.multimedia.start,
		end: data.multimedia.end,
		brand: {
			id: data.multimedia.brand_id,
			name: company.brand.name,
			logo: company.brand.logo
		},
		tag: body.multimedia_tags,
		division: divisions,
		season: seasons,
		gender: genders,
		// country: Country[],
		thumbnail: data.thumbnail,
		product: products,
	}
	dispatch({
		type: Types.CREATE_MULTIMEDIA,
		payload: {
			type: type,
			item: newMultimedia
		}
	})
}

export async function removeMultimediaAction(dispatch: any, mediaId: string, multimediaType: MultimediaTypes, translate: any, token: string) {
	try {
		messageLoading('Eliminando archivo...')
		const mediaFetch = await removeMultimediaAPI(mediaId, token)
		if (mediaFetch.response.status === 200) {
			messageSuccess('Archivo eliminado correctamente.')
			dispatch({
				type: Types.REMOVE_MULTIMEDIA,
				payload: {
					id: mediaId,
					type: multimediaType
				}
			})
			return true
		}
		messageError('Error al eliminar el archivo multimedia.')
		notificationErr(multimediaError.remove_multimedia_002, translate)
		return false
	} catch (err) {
		messageError('Error al eliminar el archivo multimedia.')
		notificationErr(multimediaError.remove_multimedia_001, translate)
		return false
	}
}

export async function searchMediaAction(dispatch: any, multimediaState: TMediaState, query: string, type: MultimediaTypes, signal: AbortSignal | undefined, translate: Function, token: string) {
	dispatch({ type: Types.SET_IS_LOADING_MEDIA, payload: { type: type, isLoading: true } })
	try {
		multimediaState.bodyMultimediaFetch.options.search = query.length > 0 ? [`%${query}%`] : []
		multimediaState.bodyMultimediaFetch.options.index = 0
		const mediaFetch = await findMultimediaAPI(multimediaState.bodyMultimediaFetch, signal, token)
		const mediaData = mediaFetch.data as MultimediaItemState[]
		if (mediaFetch.response.status === 200) {
			return dispatch({
				type: Types.SEARCH_MEDIA,
				payload: {
					type: type,
					media: mediaData,
					query: query,
				}
			})
		}
	} catch (err: any) {
		if (err.code !== 20) {
			return notificationErr(multimediaError.search_multimedia_001, translate)
		}
	} finally {
		dispatch({ type: Types.SET_IS_LOADING_MEDIA, payload: { type: type, isLoading: false } })
	}
}