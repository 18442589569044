/* eslint-disable react-hooks/exhaustive-deps */
import { useReducer, useMemo, useCallback, memo, useEffect } from 'react'
import ClientsContext from './ClientsContext'
import initialClientsState from './initialClientsState'
import userReducer from 'contexts/clients/reducer/clients.reducer'
import * as action from "contexts/clients/reducer/clients.actions";
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { IClientState, IClientsInitialState, IPointOfSaleState, TClientToUpdateRequest, TDataUpgrade, TPointsOfSaleToUpdateRequest, TUpgradeClientItems } from 'interfaces/clients.interface';
import useContextUser from 'hooks/contexts/useContextUser';
import { ChildrenProps } from 'types';
import useContextCountries from 'hooks/contexts/useContextCountries';

function ClientsProvider(props: ChildrenProps) {
	const [initialStateState, dispatch] = useReducer(userReducer, initialClientsState)
	const state: IClientsInitialState = initialStateState
	const { getAccessTokenSilently, isAuthenticated } = useAuth0()
	const { dbUser, company } = useContextUser()
	const { countries } = useContextCountries()
	const { t: translate } = useTranslation()

	useEffect(() => {
		(async () => {
			const token = await getAccessTokenSilently()
			if (!token) return
			return action.getCategoriesAction(dispatch, token)
		})()
	}, [])

	useEffect(() => {
		if (state.exchanges[0]?.id) return
		(async () => {
			if (isAuthenticated) {
				const token = await getAccessTokenSilently()
				token && action.getExchangesAction(dispatch, translate, token)
			}
		})()
	}, [isAuthenticated, state.exchanges])

	useEffect(() => {
		(async () => {
			const token = await getAccessTokenSilently()
			if (token && company.id && state.pointsOfSaleCategories.length > 0 && countries.length > 0 && state.exchanges.length > 0 && dbUser.associationsStates.connected.id) {
				return await action.getClientsAction(dispatch, company.id, dbUser.associationsStates, state.pointsOfSaleCategories, state.exchanges, countries, token, translate)
			} 
		})()
	}, [company.id])

	useEffect(() => {
		if (state.segmentations?.segmentation?.length > 0) return
		(async () => {
			if (isAuthenticated && company.brand?.id) {
				const token = await getAccessTokenSilently()
				token && action.getSegmentationsAction(dispatch, company.brand?.id, translate, token)
			}
		})()
	}, [company.brand?.id, getAccessTokenSilently, isAuthenticated, translate])

	const selectClient = useCallback(async (client: IClientState, navigateTo: string, navigate: () => void) => {
		const token = await getAccessTokenSilently()
		token && action.selectClientAction(dispatch, client, navigate, navigateTo, token)
	}, [])

	const selectPointOfSale = useCallback((pointOfSale: IPointOfSaleState) => {
		action.selectPointOfSaleAction(dispatch, pointOfSale)
	}, [])

	const createClient = useCallback(async (client: IClientState, setIsOpenDrawer: any, navigate: any, resetGoogleAddress: () => void) => {
		const token = await getAccessTokenSilently()
		token && action.createClientAction(dispatch, client, countries, setIsOpenDrawer, resetGoogleAddress, navigate, token, translate)
	}, [translate, countries])

	const updateClient = useCallback(async (client: IClientState & { country_id: string, code: string }, setIsOpenDrawer: any) => {
		const token = await getAccessTokenSilently()
		token && action.updateClientAction(dispatch, state.clientSelected.key, state.clientSelected.dataColumns, state.pointsOfSaleCategories, state.exchanges, client, company.brand?.id, dbUser.associationsStates, countries, setIsOpenDrawer, token, translate)
	}, [state.clientSelected.key, company.brand?.id, state.pointsOfSaleCategories, state.exchanges, state.clientSelected.dataColumns, dbUser.associationsStates, countries, translate])

	const upgradeClientItems = useCallback(async ({ values, clientId }: { values: TUpgradeClientItems[], clientId: string }) => {
		const token = await getAccessTokenSilently()
		return await action.upgradeClientItemsAction(dispatch, clientId, state, values, countries, token, translate) as boolean
	}, [getAccessTokenSilently, countries, state])

	const createPointOfSale = useCallback(async (pointOfSale: any, setIsOpenDrawer: any, resetGoogleAddress: () => void, form: any, showConnected: any) => {
		const token = await getAccessTokenSilently()
		token && action.createPointOfSaleAction(dispatch, state, pointOfSale, dbUser.associationsStates, countries, setIsOpenDrawer, resetGoogleAddress, form, showConnected, token, translate)
	}, [state, dbUser.associationsStates, countries, translate])

	const updatePointOfSale = useCallback(async (pointOfSale: any, setIsOpenDrawer: any) => {
		const token = await getAccessTokenSilently()
		token && action.updatePointOfSaleAction(dispatch, company.id, state, pointOfSale, countries, setIsOpenDrawer, token, translate)
	}, [company.id, state, countries, translate])

	const upgradePointsOfSale = useCallback(async (fieldsToUpgrade: TDataUpgrade[], clientId: string) => {
		const token = await getAccessTokenSilently()
		action.upgradePointsOfSaleAction(dispatch, company.id, countries, state, fieldsToUpgrade, clientId, token, translate)
	}, [company.id, state, countries])

	const shareDataPointOfSale = useCallback(async (pointOfSaleId: string, haveToShare: boolean) => {
		const token = await getAccessTokenSilently()
		token && action.shareDataPointOfSaleAction(dispatch, state, pointOfSaleId, haveToShare, token, translate)
	}, [state, translate])

	const searchClients = useCallback((query: string) => {
		action.searchClientsAction(dispatch, state, query)
	}, [state])

	const searchPointsOfSale = useCallback((query: string) => {
		action.searchPointsOfSaleAction(dispatch, state, query)
	}, [state])

	const acceptAssociation = useCallback(async (data: TClientToUpdateRequest, status: string, setIsOpenDrawer: any, setCurrentStep: any) => {
		const token = await getAccessTokenSilently()
		token && action.acceptAssociationAction(dispatch, state.clients, state.pointsOfSaleCategories, state.exchanges, company.id, data, status, dbUser.associationsStates, countries, setIsOpenDrawer, setCurrentStep, token, translate)
	}, [company.id, dbUser.associationsStates, state.pointsOfSaleCategories, state.exchanges, state.clients, countries, translate])

	const rejectAssociation = useCallback(async (data: IClientState) => {
		const token = await getAccessTokenSilently()
		token && action.rejectAssociationAction(dispatch, data, state.pointsOfSaleCategories, state.exchanges, company.id, dbUser.associationsStates, countries, token, translate)
	}, [company.id, dbUser.associationsStates, state.pointsOfSaleCategories, state.exchanges, countries, translate])

	const associatePointsOfSale = useCallback(async (data: TPointsOfSaleToUpdateRequest[], setPointsOfSaleToUpdate: any, setIsOpenDrawer: any, setCurrentStep: any) => {
		const token = await getAccessTokenSilently()
		token && action.associatePointsOfSaleAction(dispatch, state, company.id, data, dbUser.associationsStates, countries, setPointsOfSaleToUpdate, setIsOpenDrawer, setCurrentStep, token, translate)
	}, [company.id, dbUser.associationsStates, state, countries, translate])

	const rejectPointOfSale = useCallback(async (data: IPointOfSaleState) => {
		const token = await getAccessTokenSilently()
		token && await action.rejectPointOfSaleAction(dispatch, data, state.clientSelected, token, translate)
	}, [state.clientSelected, translate])

	const memoProvider = useMemo(
		() => ({
			...state,
			selectClient,
			selectPointOfSale,
			createClient,
			updateClient,
			upgradeClientItems,
			createPointOfSale,
			updatePointOfSale,
			upgradePointsOfSale,
			shareDataPointOfSale,
			searchClients,
			searchPointsOfSale,
			acceptAssociation,
			rejectAssociation,
			associatePointsOfSale,
			rejectPointOfSale
		}), [
		state,
		selectClient,
		selectPointOfSale,
		createClient,
		updateClient,
		upgradeClientItems,
		createPointOfSale,
		updatePointOfSale,
		upgradePointsOfSale,
		shareDataPointOfSale,
		searchClients,
		searchPointsOfSale,
		acceptAssociation,
		rejectAssociation,
		associatePointsOfSale,
		rejectPointOfSale
	]
	);

	return (
		<ClientsContext.Provider value={memoProvider}>
			{props.children}
		</ClientsContext.Provider>
	)
}

export default memo(ClientsProvider)