import * as SuperAdminOfSaleType from './superAdmin.types'

export default function superAdminReducer(state: any, action: any) {
	const { type, payload } = action;

	switch (type) {
		case SuperAdminOfSaleType.SET_CLIENTS:
			return {
				...state,
				clients: payload
			}

		case SuperAdminOfSaleType.SET_IS_LOADING:
			return {
				...state,
				isLoading: payload
			}

		default:
			return state
	}
}