import { IGenericItem } from 'interfaces/generics.interface';
import * as GenericType from './catalogGeneric.types'

export default function catalogGenericReducer(state: any, action: any) {
	const { type, payload } = action;

	switch (type) {
		case GenericType.SET_GENERICS:
			return {
				...state,
				generics: {
					...state.generics,
					[payload.type]: payload.data
				}
			}

		case GenericType.SET_IS_LOADING_GENERICS:
			return {
				...state,
				isLoadingGenerics: payload
			}

		case GenericType.UPDATE_GENERIC:
			return {
				...state,
				generics: {
					...state.generics,
					[payload.type]: state.generics[payload.type].map((generic: IGenericItem) => {
						if (generic.group_id === payload.newGeneric.group_id) {
							return payload.newGeneric
						}
						return generic
					})
				}
			}

		default:
			return state
	}
}