/* eslint-disable react-hooks/exhaustive-deps */
import { useReducer, useMemo, useCallback, useEffect, memo } from 'react'
import CatalogContext from './CatalogContext'
import initialCatalogState from './initialCatalogState'
import * as CatalogTypes from 'contexts/catalog/reducer/catalog.types'
import catalogReducer from 'contexts/catalog/reducer/catalog.reducer'
import * as action from "contexts/catalog/reducer/catalog.actions";
import { useAuth0 } from '@auth0/auth0-react';
import { initialProductsQuantity } from 'utils/constants'
import { useTranslation } from 'react-i18next'
import { abortCatalogController } from 'utils/abortController'
import useContextUser from 'hooks/contexts/useContextUser'
import { ICatalogState, TProductInfoFieldNameTypes, TProductInfoTypes, TQuerySearchArray, TSortBy } from 'interfaces/catalog.interface'
import { ChildrenProps } from 'types'

function CatalogProvider(props: ChildrenProps) {
	const [cs, dispatch] = useReducer(catalogReducer, initialCatalogState)
	const catalogState = cs as ICatalogState
	const { getAccessTokenSilently } = useAuth0()
	const { dbUser, company } = useContextUser()
	const { t: translate } = useTranslation()

	useEffect(() => {
		if (!company.brand?.id || !dbUser.language?.id) return
		catalogState.bodyProductSearch.options.brand_id = [company.brand?.id]
		catalogState.bodyProductSearch.options.language_id = dbUser.language?.id
		dispatch({
			type: CatalogTypes.SET_BODY_PRODUCT, payload: {
				brandId: company.brand.id,
				limit: initialProductsQuantity,
				languageId: dbUser.language.id
			}
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [company.brand?.id, dbUser.language?.id])

	useEffect(() => {
		if (!company.brand?.id) return
		(async () => {
			const token = await getAccessTokenSilently()
			action.getProductAttributesAction({ dispatch, brandId: company.brand.id, translate, token })
		})()
	}, [company.brand?.id])

	// Catalog
	const getCatalog = useCallback(async () => {
		const signal = abortCatalogController()
		const token = await getAccessTokenSilently()
		catalogState.bodyProductSearch.options.brand_id[0] && action.getCatalogAction(dispatch, catalogState.catalogItems, catalogState.bodyProductSearch, translate, signal, token)
	}, [catalogState.bodyProductSearch, catalogState.catalogItems, translate])

	const addMoreProductsToCatalog = useCallback(async () => {
		const token = await getAccessTokenSilently()
		catalogState.bodyProductSearch && action.addMoreProductsToCatalogAction(dispatch, catalogState.catalogItems, catalogState.bodyProductSearch, translate, token)
	}, [catalogState.bodyProductSearch, catalogState.catalogItems, translate])

	const addLike = useCallback(async (productId: string, setHaveLike: () => void) => {
		const token = await getAccessTokenSilently()
		action.addLikeAction(dispatch, catalogState.catalogItems, dbUser.id, productId, setHaveLike, token)
	}, [dbUser.id, catalogState.catalogItems])

	const removeLike = useCallback(async (productId: string, favorites: any, setHaveLike: () => void) => {
		const token = await getAccessTokenSilently()
		action.removeLikeAction(dispatch, catalogState.catalogItems, productId, favorites, dbUser.id, setHaveLike, token)
	}, [catalogState.catalogItems, dbUser.id])

	const resetCatalogItems = useCallback(async () => {
		dispatch({ type: CatalogTypes.RESET_CATALOG_ITEMS })
	}, [])

	// Filters
	const filter = useCallback(async (setOpenDrawer: any) => {
		const signal = abortCatalogController()
		const token = await getAccessTokenSilently()
		action.filterAction(dispatch, setOpenDrawer, catalogState, translate, signal, token)
	}, [catalogState, translate])

	const addBodyFilters = useCallback(async (field: string, value: string) => {
		action.addBodyFiltersAction(dispatch, field, value)
	}, [])

	const removeBodyFilters = useCallback(async (field: string, value: string) => {
		action.removeBodyFiltersAction(dispatch, field, value)
	}, [])

	const resetBodyFilters = useCallback(async (setOpenDrawer?: any) => {
		if (!catalogState.bodyProductSearch.options.brand_id[0]) return
		const signal = abortCatalogController()
		const token = await getAccessTokenSilently()
		action.resetBodyFiltersAction(dispatch, catalogState.catalogItems, catalogState.bodyProductSearch, setOpenDrawer, translate, signal, token)
	}, [catalogState.bodyProductSearch, catalogState.catalogItems, translate])

	const setPriceSliders = useCallback(async (field: string, value: any) => {
		action.setPriceSlidersAction(dispatch, field, value)
	}, [])

	const setFavorites = useCallback(async (userId: string) => {
		action.setFavoritesAction(dispatch, userId)
	}, [])

	const resetPriceSlider = useCallback(async (type: string) => {
		if (!catalogState.bodyProductSearch.options.brand_id[0]) return
		const signal = abortCatalogController()
		const token = await getAccessTokenSilently()
		action.resetPriceSliderAction(dispatch, type, catalogState.catalogItems, catalogState.bodyProductSearch, translate, signal, token)
	}, [catalogState.bodyProductSearch, catalogState.catalogItems, translate])

	// Product
	const getProduct = useCallback(async (productId: string) => {
		const token = await getAccessTokenSilently()
		token && action.getProductAction(dispatch, productId, company.brand?.id, dbUser.language.id, dbUser.id, token)
	}, [company.brand?.id, dbUser.id, dbUser.language?.id])

	const updateProduct = useCallback(async (productId: string, attribute: TProductInfoTypes, fieldName: TProductInfoFieldNameTypes, value: string) => {
		const token = await getAccessTokenSilently()
		return await action.updateProductAction(dispatch, productId, attribute, fieldName, value, company.brand?.id, token, translate)
	}, [company.brand?.id])

	const addLikeToProduct = useCallback(async (productId: string, setHaveLike: () => void) => {
		const token = await getAccessTokenSilently()
		action.addLikeToProductAction(dispatch, catalogState.catalogItems, dbUser.id, productId, setHaveLike, token)
	}, [catalogState, dbUser.id])

	const removeLikeToProduct = useCallback(async (productId: string, favorites: any, setHaveLike: () => void) => {
		const token = await getAccessTokenSilently()
		action.removeLikeToProductAction(dispatch, catalogState.catalogItems, productId, favorites, dbUser.id, setHaveLike, token)
	}, [catalogState, dbUser.id])

	// Related Products
	const getRelatedProducts = useCallback(async () => {
		const token = await getAccessTokenSilently()
		catalogState.bodyProductSearch.options.brand_id[0] && action.getRelatedProductsAction(dispatch, catalogState.catalogItems, catalogState.bodyProductSearch, translate, token)
	}, [catalogState.bodyProductSearch, catalogState.catalogItems, translate])

	const addLikeToRelatedProduct = useCallback(async (productId: string, setHaveLike: () => void) => {
		const token = await getAccessTokenSilently()
		action.addLikeToRelatedProductAction(dispatch, catalogState.relatedProducts, dbUser.id, productId, setHaveLike, token)
	}, [dbUser.id, catalogState.relatedProducts])

	const removeLikeToRelatedProduct = useCallback(async (productId: string, favorites: any, setHaveLike: () => void) => {
		const token = await getAccessTokenSilently()
		action.removeLikeToRelatedProductAction(dispatch, catalogState.relatedProducts, productId, favorites, dbUser.id, setHaveLike, token)
	}, [dbUser.id, catalogState.relatedProducts])

	// Search Engine
	const setQuerySearch = useCallback(async (query: string) => {
		action.setQuerySearchAction(dispatch, query)
	}, [])

	const addToBodySearch = useCallback(async (query: string) => {
		const signal = abortCatalogController()
		const token = await getAccessTokenSilently()
		action.addToBodySearchAction(dispatch, query, catalogState, translate, signal, token)
	}, [catalogState, translate])

	const removeFromBodySearch = useCallback(async (query: string) => {
		const signal = abortCatalogController()
		const token = await getAccessTokenSilently()
		action.removeFromBodySearchAction(dispatch, query, catalogState, translate, signal, token)
	}, [catalogState, translate])

	const addToQuerySearchArray = useCallback(async (value: TQuerySearchArray) => {
		action.addToQuerySearchArrayAction(dispatch, value)
	}, [])

	const removeFromQuerySearchArray = useCallback(async (value: TQuerySearchArray) => {
		action.removeFromQuerySearchArrayAction(dispatch, value)
	}, [])

	const removeAllFromBodySearch = useCallback(async () => {
		const signal = abortCatalogController()
		const token = await getAccessTokenSilently()
		action.removeAllFromBodySearchAction(dispatch, catalogState, translate, signal, token)
	}, [catalogState, translate])

	const removeFromBodySearchFilter = useCallback(async (value: any) => {
		const signal = abortCatalogController()
		const token = await getAccessTokenSilently()
		action.removeFromBodySearchFilterAction(dispatch, value, catalogState, translate, signal, token)
	}, [catalogState, translate])

	// Sort By
	const setSortBy = useCallback(async (sortBy: TSortBy) => {
		const signal = abortCatalogController()
		const token = await getAccessTokenSilently()
		action.setSortByAction(dispatch, sortBy, catalogState.catalogItems, catalogState.bodyProductSearch, translate, signal, token)
	}, [catalogState.bodyProductSearch, catalogState.catalogItems, translate])

	const setSortByHaveImages = useCallback(async (haveImages: boolean | null) => {
		const signal = abortCatalogController()
		const token = await getAccessTokenSilently()
		action.setSortByHaveImagesAction(dispatch, haveImages, catalogState.catalogItems, catalogState.bodyProductSearch, translate, signal, token)
	}, [catalogState.bodyProductSearch, catalogState.catalogItems, translate])

	const memoProvider = useMemo(
		() => ({
			...catalogState,
			getCatalog,
			addMoreProductsToCatalog,
			addToBodySearch,
			removeFromBodySearch,
			removeAllFromBodySearch,
			removeFromBodySearchFilter,
			getProduct,
			updateProduct,
			filter,
			addBodyFilters,
			removeBodyFilters,
			resetBodyFilters,
			setQuerySearch,
			setSortBy,
			setPriceSliders,
			setFavorites,
			addLike,
			removeLike,
			addLikeToProduct,
			removeLikeToProduct,
			addLikeToRelatedProduct,
			removeLikeToRelatedProduct,
			getRelatedProducts,
			addToQuerySearchArray,
			removeFromQuerySearchArray,
			resetPriceSlider,
			resetCatalogItems,
			setSortByHaveImages
		}), [
		catalogState,
		getCatalog,
		addMoreProductsToCatalog,
		addToBodySearch,
		removeFromBodySearch,
		removeAllFromBodySearch,
		removeFromBodySearchFilter,
		getProduct,
		updateProduct,
		filter,
		addBodyFilters,
		removeBodyFilters,
		resetBodyFilters,
		setQuerySearch,
		setSortBy,
		setPriceSliders,
		setFavorites,
		addLike,
		removeLike,
		addLikeToProduct,
		removeLikeToProduct,
		addLikeToRelatedProduct,
		removeLikeToRelatedProduct,
		getRelatedProducts,
		addToQuerySearchArray,
		removeFromQuerySearchArray,
		resetPriceSlider,
		resetCatalogItems,
		setSortByHaveImages
	]
	);

	return (
		<CatalogContext.Provider value={memoProvider}>
			{props.children}
		</CatalogContext.Provider>
	)
}

export default memo(CatalogProvider)