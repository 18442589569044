import { TQuerySearchArray } from 'interfaces/catalog.interface';
import * as CatalogTypes from './catalog.types'

export default function catalogReducer(state: any, action: any) {
	const { type, payload } = action;

	switch (type) {
		case CatalogTypes.GET_CATALOG:
			return {
				...state,
				catalogItems: payload
			}

		case CatalogTypes.SET_RELATED_PRODUCTS:
			return {
				...state,
				relatedProducts: payload
			}

		case CatalogTypes.ADD_MORE_TO_CATALOG:
			return {
				...state,
				catalogItems: {
					...state.catalogItems,
					data: state.catalogItems.data.concat(payload.data)
				}
			}

		case CatalogTypes.GET_FILTER:
			return {
				...state,
				filteredItems: payload
			}

		case CatalogTypes.GET_COLLECTION:
			return {
				...state,
				collectionItems: payload
			}

		case CatalogTypes.GER_PRODUCT:
			return {
				...state,
				productItem: payload
			}

		case CatalogTypes.SET_IS_LOADING:
			return {
				...state,
				isLoading: payload
			}

		case CatalogTypes.SET_IS_LOADING_MORE_PRODUCTS:
			return {
				...state,
				isLoadingMoreProducts: payload
			}

		case CatalogTypes.SET_BODY_PRODUCT:
			return {
				...state,
				bodyProductSearch: {
					...state.bodyProductSearch,
					options: {
						...state.bodyProductSearch.options,
						brand_id: [payload.brandId],
						language_id: payload.languageId,
						limit: payload.limit,
						index: 0,
					},
					filter: {
						reference: [],
						color: [],
						season: [],
						segmentation: [],
						division: [],
						family: [],
						gender: [],
						pvi: [],
						pvpr: [],
						tags: [],
						tiers: [],
					}
				},
				bodyProductSearchTemp: {
					...state.bodyProductSearchTemp,
					options: {
						...state.bodyProductSearchTemp.options,
						brand_id: payload.brandId,
						language_id: payload.languageId,
						limit: payload.limit,
						index: 0,
					},
					filter: {
						reference: [],
						color: [],
						season: [],
						segmentation: [],
						division: [],
						family: [],
						gender: [],
						pvi: [],
						pvpr: [],
						tags: [],
						tiers: [],
					}
				},
				querysearchArray: []
			}

		case CatalogTypes.SET_BODY_PRODUCT_SEARCH:
			return {
				...state,
				bodyProductSearch: payload
			}

		case CatalogTypes.SET_BODY_PRODUCT_SEARCH_TEMP:
			return {
				...state,
				bodyProductSearchTemp: payload
			}

		case CatalogTypes.SET_QUERY_SEARCH:
			return {
				...state,
				querySearch: payload
			}

		case CatalogTypes.INCREMENT_PAGINATION_INDEX:
			return {
				...state,
				bodyProductSearch: {
					...state.bodyProductSearch,
					options: {
						...state.bodyProductSearch.options,
						index: payload
					}
				}
			}

		case CatalogTypes.SET_PRICE_SLIDERS:
			return {
				...state,
				bodyProductSearchTemp: {
					...state.bodyProductSearchTemp,
					filter: {
						...state.bodyProductSearchTemp.filter,
						[payload.field]: payload.value
					}
				}
			}

		case CatalogTypes.ADD_BODY_FILTERS:
			return {
				...state,
				bodyProductSearchTemp: {
					...state.bodyProductSearchTemp,
					filter: {
						...state.bodyProductSearchTemp.filter,
						[payload.field]: [...state.bodyProductSearchTemp.filter[payload.field as keyof typeof state.bodyProductSearchTemp.filter], payload.value]
					}
				}
			}

		case CatalogTypes.REMOVE_BODY_FILTERS:
			return {
				...state,
				bodyProductSearchTemp: {
					...state.bodyProductSearchTemp,
					filter: {
						...state.bodyProductSearchTemp.filter,
						[payload.field]: state.bodyProductSearchTemp.filter[payload.field as keyof typeof state.bodyProductSearchTemp.filter].filter((value: any) => value !== payload.value)
					}
				}
			}

		case CatalogTypes.RESET_BODY_FILTERS:
			return {
				...state,
				bodyProductSearch: {
					...state.bodyProductSearch,
					options: {
						...state.bodyProductSearch.options,
						favorite: "",
						// search: [""],
						index: 0
					},
					filter: {
						reference: [],
						color: [],
						season: [],
						segmentation: [],
						division: [],
						family: [],
						gender: [],
						pvi: [],
						pvpr: [],
						tags: [],
						tiers: []
					}
				},
				bodyProductSearchTemp: {
					...state.bodyProductSearchTemp,
					options: {
						...state.bodyProductSearchTemp.options,
						favorite: "",
						// search: [""],
						index: 0
					},
					filter: {
						reference: [],
						color: [],
						season: [],
						segmentation: [],
						division: [],
						family: [],
						gender: [],
						pvi: [],
						pvpr: [],
						tags: [],
						tiers: []
					}
				},
				querysearchArray: []
			}

		case CatalogTypes.RESET_PRICE_SLIDER:
			return {
				...state,
				bodyProductSearch: {
					...state.bodyProductSearch,
					filter: {
						...state.bodyProductSearch.filter,
						[payload.type]: []
					}
				},
				bodyProductSearchTemp: {
					...state.bodyProductSearchTemp,
					filter: {
						...state.bodyProductSearchTemp.filter,
						[payload.type]: []
					}
				},
			}

		case CatalogTypes.SET_THERE_ARE_MORE_PRODUCTS:
			return {
				...state,
				thereAreMoreProducts: payload
			}

		case CatalogTypes.SET_IS_EMPTY_PRODUCTS:
			return {
				...state,
				isEmptyProducts: payload
			}

		case CatalogTypes.SET_SORT_BY:
			return {
				...state,
				bodyProductSearch: {
					...state.bodyProductSearch,
					options: {
						...state.bodyProductSearch.options,
						order: [payload],
					}
				}
			}

		case CatalogTypes.SET_FAVORITES:
			return {
				...state,
				bodyProductSearchTemp: {
					...state.bodyProductSearchTemp,
					options: {
						...state.bodyProductSearchTemp.options,
						favorite: payload,
					}
				}
			}

		case CatalogTypes.SET_IS_LOADING_PRODUCT:
			return {
				...state,
				isLoadingProduct: payload
			}

		case CatalogTypes.SET_IS_LOADING_RELATED_PRODUCTS:
			return {
				...state,
				isLoadingRelatedProducts: payload
			}

		case CatalogTypes.ADD_TO_QUERY_SEARCH_ARRAY:
			return {
				...state,
				querysearchArray: !state.querysearchArray.includes(payload) ? [...state.querysearchArray, payload] : state.querysearchArray
			}

		case CatalogTypes.RESET_QUERY_SEARCH_ARRAY:
			return {
				...state,
				querysearchArray: []
			}

		case CatalogTypes.REMOVE_FROM_QUERY_SEARCH_ARRAY:
			return {
				...state,
				querysearchArray: state.querysearchArray.filter((item: TQuerySearchArray) => item !== payload)
			}

		case CatalogTypes.SET_BODY_SEARCH_FILTER:
			return {
				...state,
				querysearchArray: payload.querySearchArray,
			}

		case CatalogTypes.ADD_LIKE_TO_PRODUCT:
			const newObj = {
				favorite_id: payload.id,
				user_id: payload.user_id
			}
			return {
				...state,
				productItem: {
					...state.productItem,
					favorite: [...state.productItem.favorite, newObj]
				}
			}

		case CatalogTypes.REMOVE_LIKE_TO_PRODUCT:
			return {
				...state,
				productItem: {
					...state.productItem,
					favorite: state.productItem.favorite.filter((item: any) => item.user_id !== payload)
				}
			}

		case CatalogTypes.RESET_CATALOG_ITEMS:
			return {
				...state,
				catalogItems: {
					count: 0,
					data: []
				}
			}

		case CatalogTypes.SET_SORT_BY_HAVE_IMAGES:
			return {
				...state,
				bodyProductSearch: {
					...state.bodyProductSearch,
					options: {
						...state.bodyProductSearch.options,
						with_images: payload,
					}
				}
			}

		case CatalogTypes.SET_PRODUCT_ATTRIBUTES:
			return {
				...state,
				productAttributes: payload
			}

		default:
			return state
	}
}