import { IClientState, TUpgradeClientItems } from 'interfaces/clients.interface';
import * as ClientsTypes from './clients.types'

export default function clientsReducer(state: any, action: any) {
	const { type, payload } = action;

	switch (type) {
		case ClientsTypes.GET_CLIENTS:
			return {
				...state,
				clients: [...state.clients, payload],
				allClients: [...state.clients, payload],
				pointsOfSale: state.pointsOfSale.concat(payload.clientSalePoint),
				allPointsOfSale: state.pointsOfSale.concat(payload.clientSalePoint),
			}

		case ClientsTypes.SET_CLIENTS:
			return {
				...state,
				clients: payload,
			}

		case ClientsTypes.SET_ALL_CLIENTS:
			return {
				...state,
				allClients: payload,
			}

		case ClientsTypes.SET_POINTS_OF_SALE:
			return {
				...state,
				pointsOfSale: payload,
			}

		case ClientsTypes.SET_ALL_POINTS_OF_SALE:
			return {
				...state,
				allPointsOfSale: payload,
			}

		case ClientsTypes.SELECT_CLIENT:
			return {
				...state,
				clientSelected: payload
			}

		case ClientsTypes.SELECT_POINT_OF_SALE:
			return {
				...state,
				pointOfSaleSelected: payload
			}

		case ClientsTypes.CREATE_CLIENT:
			return {
				...state,
				clients: [...state.clients, payload]
			}

		case ClientsTypes.UPDATE_CLIENT:
			return {
				...state,
				clientSelected: {
					...state.clientSelected,
					clientCode: payload.clientCode,
					tradeName: payload.name,
					businessName: payload.legal_name,
					cif: payload.cif,
					address: payload.address,
					postalCode: payload.postal_code,
					city: payload.city,
					country: {
						id: payload.country.id,
						name: payload.country.name
					},
					province: payload.province,
					floor: payload.floor,
					door: payload.door,
					lat: payload.lat,
					lng: payload.lng,
					contact_name: payload.contact_name,
					email: payload.email,
					web: payload.web,
					tel_prefix: payload.tel_prefix,
					tel_sufix: payload.tel_sufix,
					youtube: payload.youtube,
					facebook: payload.facebook,
					instagram: payload.instagram,
					linkedin: payload.linkedin,
				}
			}

		case ClientsTypes.UPGRADE_CLIENT:
			return {
				...state,
				clientSelected: {
					...state.clientSelected,
					...payload,
				}
			}

		case ClientsTypes.IS_LOADING_UPGRADE_CLIENT:
			return {
				...state,
				isLoadingUpgradeClientItems: payload
			}

		case ClientsTypes.SET_IS_LOADING_CLIENT:
			return {
				...state,
				isLoadingClient: payload
			}

		case ClientsTypes.REFRESH_CLIENTS:
			return {
				...state,
				refreshClients: payload
			}

		case ClientsTypes.CREATE_POINT_OF_SALE:
			return {
				...state,
				clientSelected: {
					...state.clientSelected,
					clientSalePoint: [...state.clientSelected.clientSalePoint, payload.newPointOfSale]
				}
			}

		case ClientsTypes.UPDATE_POINT_OF_SALE:
			return {
				...state,
				clientSelected: payload,
			}

		case ClientsTypes.SET_QUERY_CLIENTS_SEARCH:
			return {
				...state,
				queryClientsSearch: payload,
			}

		case ClientsTypes.SET_QUERY_POINTS_OF_SALE_SEARCH:
			return {
				...state,
				queryPointsOfSaleSearch: payload,
			}

		case ClientsTypes.SET_POINTS_OF_SALE_CATEGORIES:
			return {
				...state,
				pointsOfSaleCategories: payload,
			}

		case ClientsTypes.UPDATE_ASSOCIATION_INFO:
			return {
				...state,
				clientSelected: {
					...state.clientSelected,
					associationInfo: {
						...state.clientSelected.associationInfo,
						[payload.field]: payload.value
					}
				}
			}

		case ClientsTypes.SET_EXCHANGES:
			return {
				...state,
				exchanges: payload,
			}

		case ClientsTypes.SET_SEGMENTATIONS:
			return {
				...state,
				segmentations: payload,
			}

		case ClientsTypes.SET_DATA_COLUMNS_WITH_CHANGES:
			return {
				...state,
				clientSelected: {
					...state.clientSelected,
					dataColumnsWithChanges: {
						...state.clientSelected.dataColumnsWithChanges,
						values: state.clientSelected.dataColumnsWithChanges.values.filter((item: TUpgradeClientItems) => item.keyValue !== payload.keyValue),
					}
				},
				clients: state.clients.map((client: IClientState) => {
					if (client.key === payload.clientId) {
						return {
							...client,
							dataColumnsWithChanges: {
								...state.clientSelected.dataColumnsWithChanges,
								values: state.clientSelected.dataColumnsWithChanges.values.filter((item: any) => item.keyValue !== payload.keyValue),
							}
						}
					}
					return client
				}),
			}

		case ClientsTypes.SET_DATA_COLUMNS:
			return {
				...state,
				clientSelected: {
					...state.clientSelected,
					dataColumns: payload.dataColumns
				},
			}

		default:
			return state
	}
}