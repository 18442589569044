import { IBodyProductSearch, ICatalogItemsState, ICatalogState, IProductState, TProductAttributes, TProductInfoFieldNameTypes, TProductInfoTypes, TQuerySearchArray } from "interfaces/catalog.interface"
import { initialProductsQuantity } from "utils/constants"

const bodyProductSearch: IBodyProductSearch = {
	options: {
		brand_id: [''],
		favorite: "",
		with_images: null,
		profile: false,
		search: [],
		language_id: "",
		order: [],
		group: [],
		limit: initialProductsQuantity,
		index: 0
	},
	filter: {
		reference: [],
		color: [],
		season: [],
		segmentation: [],
		division: [],
		family: [],
		gender: [],
		pvi: [],
		pvpr: [],
		tags: [],
		tiers: [],
	}
}

const bodyProductSearchTemp: IBodyProductSearch = {
	options: {
		brand_id: [''],
		favorite: "",
		with_images: null,
		profile: false,
		search: [],
		language_id: "",
		order: [],
		group: [],
		limit: initialProductsQuantity,
		index: 0
	},
	filter: {
		reference: [],
		color: [],
		season: [],
		segmentation: [],
		division: [],
		family: [],
		gender: [],
		pvi: [],
		pvpr: [],
		tags: [],
		tiers: [],
	}
}

const initialCatalogState: ICatalogState = {
	// Catalog
	catalogItems: {} as ICatalogItemsState,
	bodyProductSearch: bodyProductSearch,
	bodyProductSearchTemp: bodyProductSearchTemp,
	isLoading: true,
	isLoadingMoreProducts: false,
	thereAreMoreProducts: true,
	isEmptyProducts: false,
	getCatalog: () => { },
	addMoreProductsToCatalog: () => { },
	addLike: () => { },
	removeLike: () => { },
	resetCatalogItems: () => { },
	// Filters
	filter: () => { },
	addBodyFilters: () => { },
	removeBodyFilters: () => { },
	resetBodyFilters: () => { },
	setPriceSliders: () => { },
	setFavorites: () => { },
	resetPriceSlider: () => { },
	// Product
	productItem: {} as IProductState,
	isLoadingProduct: true,
	getProduct: () => { },
	updateProduct: (productId: string, attribute: TProductInfoTypes, fieldName: TProductInfoFieldNameTypes, value: string) => { return new Promise(() => {}) },
	addLikeToProduct: () => { },
	removeLikeToProduct: () => { },
	productAttributes: {} as TProductAttributes,
	// Related Products
	relatedProducts: {} as ICatalogItemsState,
	isLoadingRelatedProducts: true,
	getRelatedProducts: () => { },
	addLikeToRelatedProduct: () => { },
	removeLikeToRelatedProduct: () => { },
	// Search engine
	querySearch: "",
	querysearchArray: [] as TQuerySearchArray[],
	setQuerySearch: () => { },
	addToBodySearch: () => { },
	removeFromBodySearch: () => { },
	addToQuerySearchArray: () => { },
	removeFromQuerySearchArray: () => { },
	removeAllFromBodySearch: () => { },
	removeFromBodySearchFilter: () => { },
	// Sort By
	setSortBy: () => { },
	setSortByHaveImages: () => { },
}

export default initialCatalogState