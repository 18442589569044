const italianTranslation = {
  // HEADER //////////////////
  // Nav
  'header_nav_dashboard': 'Dashboard',
  'header_nav_dashboard_sales': 'Vendite',
  'header_nav_dashboard_stock': 'Azione',
  'header_nav_dashboard_prediction': 'Previsione',
  'header_nav_catalog': 'Catalogo',
  'header_nav_catalog-download': 'Scarica il catalogo',
  'header_nav_multimedia': 'Multimedia',
  'header_nav_clients': 'Clienti',
  'header_sub-nav_view-catalog': 'Visualizza catalogo',
  'header_sub-nav_add-product': 'Aggiungi prodotto',
  'header_sub-nav_all-catalogs': 'Gestione dei cataloghi',
  'header_sub-nav_catalog-correlation': 'Correlazione dei riferimenti',
  'header_sub-nav_catalog-generic': 'Generici H4R',
  'header_sub-nav_multimedia-catalog': 'Catalogo multimediale',
  'header_sub-nav_multimedia-catalog-videos': 'Video',
  'header_sub-nav_multimedia-catalog-images': 'Immagini',
  'header_sub-nav_multimedia-catalog-banners': 'Banner',
  'header_sub-nav_clients-list': 'Lista clienti',
  'header_sub-nav_add-client': 'Aggiungi cliente',
  // Notifications
  'header_notifications_empty-notifications': 'Non ci sono notifiche',
  ////////////////////////////

  // SIGNUP PAGE /////////////
  'signup-page_title': 'Registrati per:',
  'signup-page_sub-title': 'Registrati per far crescere la tua attività',
  'signup-page_button-brand': 'Registrati come Marchio',
  'signup-page_button-multibrand': 'Registrati come Multimarchio',
  'signup-page_button-brand-wh': 'Iscrivimi come marca o multimarca',
  'signup-page_return-to-login': 'Torna al Accedi',
  ////////////////////////////

  // ACCOUNT PAGE /////////////
  'profile-page_nav_profile': 'Profilo',
  'profile-page_nav_company': 'Informazioni aziendali',
  'profile-page_nav_main-contacts': 'Contatti principali',
  'profile-page_nav_commercial-info': 'Informazioni commerciali',
  /////////////////////////////

  // DASHBOARDS /////////////////
  'dashboard_empty-dashboard': 'Contatta Hub4Retail per abilitare questo servizio.',
  'dashboard_dashboard_error': 'Errore nel caricamento del dashboard. Se il problema persiste, contattare:',
  /////////////////////////////

  // HELMET ///////////////////
  'signup': 'Registrati',
  'home': 'Home',
  'dashboard': 'Dashboard',
  'multimedia': 'Multimedia',
  'multimedia-videos': 'Video multimediali',
  'multimedia-images': 'Immagini multimediali',
  'multimedia-banners': 'Banner multimediali',
  'clients': 'Clienti',
  'profile': 'Profilo',
  'company': 'Informazioni aziendali',
  'main-contacts': 'Contatti principali',
  'commercial-info': 'Informazioni commerciali',
  'catalog': 'Catalogo',
  'notifications': 'Notifiche',
  'commercial-profile': 'Profilo Commerciale',
  'all-catalogs': 'Tutti i cataloghi',
  'catalog-correlation': 'Correlazione di riferimenti',
  'catalog-generic': 'Generici H4R',
  'catalog_download': 'Scarica il catalogo',
  // Client
  'client_resume': 'Sommario',
  'client_business-info': "Informazioni sull'azienda",
  'client_contacts': 'Contatti',
  'client_sale-points': 'Punti vendita',
  'client_comercial-info': 'Informazioni commerciali',
  /////////////////////////////

  // USERS ///////////////////////
  // Departments
  'users_department_commercial': 'Commerciale',
  'users_department_administration': 'Amministrazione',
  'users_department_agent': 'Agente',
  'users_department_owner': 'Proprietario',
  'users_department_store-manager': 'Responsabile del negozio',
  'users_department_logistics': 'Logistica',
  'users_department_marketing': 'Marketing',
  'users_department_others': 'Altri',
  ////////////////////////////////

  // ACCOUNT //////////////////
  'account_profile_title': 'Il mio profilo',
  'account-info-panel_title': 'Informazioni Generali',
  'account-company-info-panel_title': "Dati dell'azienda",
  // Profile
  'account-info-panel_profile_name': 'Nome',
  'account-info-panel_profile_lastname': 'Cognome',
  'account-info-panel_profile_email': 'Email',
  'account-info-panel_profile_phone-number': 'Numero di telefono',
  'account-info-panel_profile_department': 'Dipartimento',
  'account-info-panel_profile_security-data': 'Dati di sicurezza',
  'account-info-panel_profile_change-password': 'Cambia password',
  'account-info-panel_profile_pwd_password-label': 'Nuova password',
  'account-info-panel_profile_pwd_repeat-password-label': 'Ripeti la nuova password',
  'account-info-panel_profile_pwd_change-button': 'Cambia password',
  'account-info-panel_profile_pwd_validation': 'La password deve contenere almeno 8 caratteri e un massimo di 16, 1 numero, 1 lettera maiuscola e una minuscola.',
  'account-info-panel_profile_pwd_validation-match': 'Le password non coincidono',
  'account-info-panel_profile_pwd_change-success': 'Password cambiata con successo.',
  'account-info-panel_profile_pwd_empty-inputs': 'Riempire i campi vuoti.',
  // Profile form
  'account-profile_form-input_name': 'Inserisci il tuo nome',
  'account-profile_form-input_name-validation': 'Per favore, inserisci il tuo nome',
  'account-profile_form-input_lastname': 'Inserisci il tuo cognome',
  'account-profile_form-input_lastname-validation': 'Per favore, inserisci il tuo cognome',
  'account-profile_form-input_email': 'Inserisci la tua email',
  'account-profile_form-input_email-validation-required': 'Per favore, inserisci la tua email',
  'account-profile_form-input_email-validation': 'L\'email non è valida',
  'account-profile_form-input_department': 'Inserisci il dipartimento',
  'account-profile_form-input_department-validation': 'Per favore, inserisci il dipartimento',
  'account-profile_form-input_phone-number': '',
  // Company
  'account-info-panel_company_title': "Dati dell'azienda",
  'account-info-panel_company_cif': 'CIF (Codice di Identificazione Fiscale)',
  'account-info-panel_company_trade-name': 'Nome del Marchio',
  'account-info-panel_company_business-name': 'Ragione sociale',
  'account-info-panel_company_address': 'Indirizzo',
  'account-info-panel_company_postal-code': 'Codice postale',
  'account-info-panel_company_population': 'Città',
  'account-info-panel_company_country': 'Paese',
  'account-info-panel_company_email': 'Email',
  'account-info-panel_company_phone-number': 'Numero di telefono',
  'account-info-panel_company_contact_name_it': 'Contatto Principale',
  'account-info-panel_company_metas-connected_title_it': 'Obiettivi Collegati',
  'account-info-panel_company_active-seasons_title_it': 'Stagioni Attive',
  // Company form
  'account-profile_form-input_phone-number-validation': 'Per favore, inserisci il numero di telefono',
  'account-company_form-input_trade-name': 'Inserisci il Nome del Marchio',
  'account-company_form-input_trade-name-validation': 'Per favore, inserisci il Nome del Marchio',
  'account-company_form-input_business-name': 'Inserisci la ragione sociale',
  'account-company_form-input_business-name-validation': 'Per favore, inserisci la ragione sociale',
  'account-company_form-input_country': 'Inserisci il paese',
  'account-company_form-input_country-validation': 'Per favore, inserisci il paese',
  'account-company_form-input_population': 'Inserisci la città',
  'account-company_form-input_population-validation': 'Per favore, inserisci la città',
  'account-company_form-input_address': 'Inserisci l\'indirizzo',
  'account-company_form-input_address-validation': 'Per favore, inserisci il tuo indirizzo',
  'account-company_form-input_postal-code': 'Inserisci il codice postale',
  'account-company_form-input_postal-code-validation': 'Per favore, inserisci il codice postale',
  'account-company_form-input_cif': 'Inserisci il CIF (Codice di Identificazione Fiscale)',
  'account-company_form-input_cif-validation': 'Per favore, inserisci il CIF (Codice di Identificazione Fiscale)',
  'account-company_form-input_phone-number': 'Numero di telefono',
  'account-company_form-input_phone-number-validation': 'Per favore, inserisci il numero di telefono',
  'account-company_form-input_email': 'Inserisci l\'email aziendale',
  'account-company_form-input_contact_name_it': 'Inserisci il contatto principale dell\'azienda',
  'account-company_form-input_contact_name-validation-required_it': 'Per favore, inserisci il contatto principale dell\'azienda',
  'account-company_form-input_email-validation-required': 'Per favore, inserisci la tua email',
  'account-company_form-input_email-validation': 'L\'email non è valida',
  'account-company_form-input_instagram': 'Inserisci l\'URL di Instagram',
  'account-company_form-input_youtube': 'Inserisci l\'URL di Youtube',
  'account-company_form-input_linkedin': 'Inserisci l\'URL di Linkedin',
  'account-company_form-input_facebook': 'Inserisci l\'URL di Facebook',
  'account-company_social-media-title': 'Social Media',
  'account-company_form-input_social-media-label': 'Nome Rete Sociale',
  'account-company_form-input_social-media-label-placeholder': 'Nome della Rete Sociale',
  'account-company_form-input_social-media-label_validation': 'Per favore, inserisci il nome della rete sociale',
  'account-company_form-input_social-media-value': 'URL Rete Sociale',
  'account-company_form-input_social-media-value-placeholder': 'URL della Rete Sociale',
  'account-company_form-input_social-media-value_validation': 'Per favore, inserisci l\'URL della rete sociale',
  'account-company_form-input_social-media-add-button': 'Aggiungi Rete Sociale',
  'account-company_form-input_social-media-accept-button_title': 'Aggiorna Rete Sociale',
  'account-company_form-input_social-media-reject-button_title': 'Scarta Modifiche',
  'account-company_form-input_social-media-delete-button_title': 'Elimina Rete Sociale',
  'account-company_form-input_social-media-delete-button_popconfirm_title': 'Elimina Rete Sociale',
  'account-company_form-input_social-media-delete-button_popconfirm_description': 'Sei sicuro di voler eliminare questa Rete Sociale?',
  'account-company_form-input_social-media-delete-button_popconfirm_ok': 'Elimina',
  'account-company_form-input_social-media-delete-button_popconfirm_cancel': 'Annulla',
  // Main Contacts
  'main-contacts_title': 'Contatti Principali',
  'main-contacts_add-contact': '+ Aggiungi',
  'main-contacts_table_name': 'Nome',
  'main-contacts_table_lastname': 'Cognome',
  'main-contacts_table_email': 'Email',
  'main-contacts_table_phone-number': 'Numero di telefono',
  'main-contacts_table_department': 'Dipartimento',
  'main-contacts_table_pop-confirm_title': 'Elimina contatto',
  'main-contacts_table_pop-confirm_description': 'Sei sicuro di voler eliminare questo contatto?',
  'main-contacts_table_pop-confirm_btn-accept': 'Elimina',
  'main-contacts_table_pop-confirm_btn-cancel': 'Annulla',
  // Main Contacts Form
  'main-contacts_form-label_name': 'Nome',
  'main-contacts_form-label_lastname': 'Cognome',
  'main-contacts_form-label_email': 'Email',
  'main-contacts_form-label_phone-number': 'Numero di telefono',
  'main-contacts_form-label_department': 'Dipartimento',
  'main-contacts_form-input_name': 'Inserisci il nome',
  'main-contacts_form-input_lastname': 'Inserisci il cognome',
  'main-contacts_form-input_email': 'Inserisci l\'email',
  'main-contacts_form-input_department': 'Inserisci il dipartimento',
  'main-contacts_form_sector_label': 'Settore',
  'main-contacts_form-input_sector': 'Inserisci il settore',
  'main-contacts_form-input_sector_validation': 'Inserisci il settore',
  'main-contacts_form-input_phone-number': 'Inserisci il numero di telefono',
  'main-contacts_form-input-validation_name': 'Per favore, inserisci il nome',
  'main-contacts_form-input-validation_lastname': 'Per favore, inserisci il cognome',
  'main-contacts_form-input-validation_email': 'L\'email non è valida',
  'main-contacts_form-input-validation-required_email': 'Per favore, inserisci l\'email',
  'main-contacts_form-input-validation_department': 'Per favore, inserisci il dipartimento',
  'main-contacts_form-input-validation_phone-number': 'Per favore, inserisci il numero di telefono',
  // Comercial information
  'comercial-info_title': 'Informazioni Commerciali',
  'comercial-info_add-contact': '+ Aggiungi Agente',
  'comercial-info_table_agent-name': 'Agente Commerciale / Distributore',
  'comercial-info_table_division': 'Divisione',
  'comercial-info_table_buy-group': 'Gruppo d\'acquisto',
  'comercial-info_table_genre': 'Genere',
  'comercial-info_table_pop-confirm_title': 'Elimina agente commerciale',
  'comercial-info_table_pop-confirm_description': 'Sei sicuro di voler eliminare questo agente commerciale?',
  'comercial-info_table_pop-confirm_btn-accept': 'Elimina',
  'comercial-info_table_pop-confirm_btn-cancel': 'Annulla',
  // Comercial information form
  'comercial-info_form-label_name': 'Nome',
  'comercial-info_form-label_lastname': 'Cognome',
  'comercial-info_form-label_trade-name': 'Nome Azienda',
  'comercial-info_form-label_business-name': 'ragione sociale',
  'comercial-info_form-label_cif': 'CIF (Codice di Identificazione Fiscale)',
  'comercial-info_form-label_phone-number': 'Numero di telefono',
  'comercial-info_form-label_main-contact': 'Contatto Principale',
  'comercial-info_form-label_working-brands': 'Marche con cui lavora:',
  'comercial-info_form-input_name': 'Inserisci il nome',
  'comercial-info_form-label_email': 'Email',
  'comercial-info_form-label_sector': 'Settore',
  'comercial-info_form-input_email': 'Inserisci l’email',
  'comercial-info_form-input_sector': 'Inserisci il settore',
  'comercial-info_form-input_division': 'Inserisci la divisione',
  'comercial-info_form-input_yes': 'Sì',
  'comercial-info_form-input_no': 'No',
  'comercial-info_form-input_lastname': 'Inserisci il cognome',
  'comercial-info_form-input_trade-name': 'Inserisci il nome del Marchio',
  'comercial-info_form-input_business-name': 'Inserisci il ragione sociale',
  'comercial-info_form-input_cif': 'Inserisci il CIF (Codice di Identificazione Fiscale)',
  'comercial-info_form-input_phone-number': 'Inserisci il numero di telefono',
  'comercial-info_form-input_main-contact': 'Inserisci il contatto principale',
  'comercial-info_form-input-validation_name': 'Per favore, inserisci il nome',
  'comercial-info_form-input-validation_email': 'Per favore, inserisci l’email',
  'comercial-info_form-input-validation_sector': 'Per favore, inserisci il settore',
  'comercial-info_form-input-validation_division': 'Per favore, inserisci la divisione',
  'comercial-info_form-input-validation_buy-group': 'Per favore, inserisci il gruppo d’acquisto',
  'comercial-info_form-input-validation_lastname': 'Per favore, inserisci il cognome',
  'comercial-info_form-input-validation_trade-name': 'Per favore, inserisci il Nome del Marchio',
  'comercial-info_form-input-validation_business-name': 'Per favore, inserisci il ragione sociale',
  'comercial-info_form-input-validation_cif': 'Per favore, inserisci il (CIF Codice di Identificazione Fiscale)',
  'comercial-info_form-input-validation_phone-number': 'Per favore, inserisci il numero di telefono',
  'comercial-info_form-input-validation_main-contact': 'Per favore, inserisci il contatto principale',
  // Drawers
  'account-profile_drawer_title': 'Informazioni Generali',
  'account-company_drawer_title': 'Dati dell’Azienda',
  'comercial-info_drawer_title': 'Informazioni Commerciali',
  'main-contacts_drawer_title': 'Contatti Principali',
  'main-contacts_drawer_create-title': 'Crea contatto',
  'main-contacts_drawer_update-title': 'Aggiorna contatto',
  /////////////////////////////

  // MULTIMEDIA ///////////////
  'multimedia_nav-videos': 'Video',
  'multimedia_nav-images': 'Immagini',
  'multimedia_sort-btn': 'Ordina per',
  'multimedia_filter-btn': 'Filtri',
  'multimedia_apply-filters-btn': 'Applica filtri',
  'multimedia_search-input-placeholder': 'Cerca per marca, genere, stagione, paese, data',
  'multimedia_search-results': 'Risultati',
  'multimedia_download_started': 'Il download è iniziato',
  'multimedia_open': 'Apri',
  'multimedia_link-copied': 'Link copiato negli appunti',
  'multimedia_link-copy-button': 'Copia link',
  'multimedia_delete-popconfirm-title': 'Elimina file multimediale',
  'multimedia_delete-popconfirm-description': 'Sei sicuro di voler eliminare questo file?',
  // Sort by
  'multimedia_sort-last-favorites': 'Preferiti',
  'multimedia_sort-last-aggregates': 'Ultimi aggiunti',
  'multimedia_sort-best-seller': 'Miglior venditore',
  'multimedia_sort-less-sold': 'Meno venduto',
  'multimedia_sort-best-pvp_great-minor': 'Prezzo: alto-basso',
  'multimedia_sort-best-pvp_minor-great': 'Prezzo: basso-alto',
  'multimedia_sort-best-pvi_great-minor': 'PVI: alto-basso',
  'multimedia_sort-best-pvi_minor-great': 'PVI: basso-alto',
  'multimedia_sort-by-color': 'Per colore da A a Z',
  'multimedia_sort-by-reference': 'Per riferimento da A a Z',
  // Images
  'multimedia_images_popover-edit': 'Modifier',
  'multimedia_images_popover-remove': 'Supprimer',
  'multimedia_images_popover-yes': 'Sì',
  'multimedia_images_popover-no': 'No',
  // Filter
  'multimedia_filter-remove-filters': 'Elimina filtri',
  'multimedia_filter-season': 'Stagione',
  'multimedia_filter-division': 'Division',
  'multimedia_filter-genders': 'Genere',
  'multimedia_filter-visualization': 'Visualizzazione',
  'multimedia_filter-image-rights': 'Diritti di immagine',
  'multimedia_filter-country': 'Paese',
  'multimedia_filter-tags': 'Tags',
  'multimedia_filter-favorites': 'Preferiti',
  'multimedia_filter-start': 'Emettibile da',
  'multimedia_filter-end': 'Emettibile fino a',
  //Dragger
  'multimedia_upload-images-manually_dragger_msg-error': 'File supportati',
  'multimedia_upload-images-manually_dragger_msg_image-size-limit_error': 'La dimensione dell’immagine deve essere inferiore a 3MB',
  'multimedia_upload-images-manually_dragger_msg_video-size-limit_error': 'La dimensione del video deve essere inferiore a 100MB',
  'multimedia_upload-images-manually_dragger_text-1': 'Clicca o trascina un\'immagine',
  // Upload image manually
  'multimedia_upload-images-manually_form-item_selection': 'Seleziona',
  'multimedia_upload-images-manually_form-item_title': 'Titolo',
  'multimedia_upload-images-manually_form-item_title-required': 'Per favore, inserisci il titolo dell\'immagine',
  'multimedia_upload-images-manually_form-item_usage-required': 'Si prega di inserire il tipo di uso',
  'multimedia_upload-images-manually_form-item_name': 'Denominazione Tecnica',
  'multimedia_upload-images-manually_form-item_name-required': 'Per favore, inserisci il nome dell\'immagine',
  'multimedia_upload-images-manually_form-item_description': 'Descrizione',
  'multimedia_upload-images-manually_form-item_description-required': 'Per favore, inserisci la descrizione',
  'multimedia_upload-images-manually_form-item_divisions': 'Division',
  'multimedia_upload-images-manually_form-item_season': 'Stagione',
  'multimedia_upload-images-manually_form-item_language': 'Lingua',
  'multimedia_upload-images-manually_form-item_genders': 'Genere',
  'multimedia_upload-images-manually_form-item_products': 'Prodotti',
  'multimedia_upload-images-manually_form-item_catalog_id': 'ID del catalogo',
  'multimedia_upload-images-manually_form-item_catalog_id_text-1': 'Campo associato a Chronos per definire il suo circuito pubblicitario',
  'multimedia_upload-images-manually_form-item_catalog_id_text-2': 'Contatta Hub4Retail per attivare Chronos',
  'multimedia_upload-images-manually_form-item_usage': 'Uso del contenuto',
  'multimedia_upload-images-manually_form-item_visualization': 'Visualizzazione',
  'multimedia_upload-images-manually_form-item_tags': 'Tags',
  'multimedia_upload-images-manually_form-item_tags_text-1': 'Crea le tue etichette',
  'multimedia_upload-images-manually_form-item_start': 'Riproducibile da',
  'multimedia_upload-images-manually_form-item_end': 'Riproducibile fino a',
  'multimedia_upload-images-manually_form-item_copyright': 'Questo video ha i diritti d\'immagine?',
  'multimedia_upload-images-manually_form-item_enable_download': 'Permetti il download?',
  'multimedia_upload-images-manually_form-item_countries': 'Paese',
  'multimedia_upload-images-manually_form-item_add': 'Aggiungi',
  'multimedia_upload-images-manually_form-item_next': 'Avanti',
  'multimedia_upload-images-manually_form-item_yes': 'Sì',
  'multimedia_upload-images-manually_form-item_no': 'No',
  // Modal manually
  'multimedia_upload-images-manually_modal_title': 'Aggiorna immagine',
  'multimedia_upload-images-manually_modal_step-1': 'Seleziona un\'immagine',
  'multimedia_upload-images-manually_modal_step-2': 'Completa i dati',
  'multimedia_upload-images-manually_modal_step-2-msg': 'Titolo dell\'immagine',
  'multimedia_upload-images-manually_modal_step-3-msg': 'Immagine caricata con successo',
  // Upload images CSV
  'multimedia_upload-images_button': 'Carica immagini',
  'multimedia_upload-images_step-1': 'Seleziona immagini',
  'multimedia_upload-images_step-1_dragger_text-1': 'Clicca o trascina i file immagine',
  'multimedia_upload-images_step-1_dragger_text-2': 'Prendi nota delle seguenti informazioni...',
  'multimedia_upload-images_step-1_dragger_text-3': 'Salva le immagini con il nome',
  'multimedia_upload-images_step-1_dragger_text-4': 'riferimento_colore_immagine#',
  'multimedia_upload-images_step-2': 'Carica file CSV',
  'multimedia_upload-images_step-2_dragger_text-1': 'Clicca o trascina il file CSV',
  'multimedia_upload-images_step-3': 'Caricamento completato!',
  'multimedia_upload-images_step-3-text': 'file immagine caricati',
  'multimedia_upload-images_modal_title': 'Carica immagini',
  'multimedia_upload-images_modal_btn-back': 'Indietro',
  'multimedia_upload-images_modal_btn-next': 'Avanti',
  'multimedia_upload-images_modal_btn-close': 'Chiudi',
  //Upload
  'multimedia_upload_step-2': 'Completa i dati',
  // Upload videos
  'multimedia_upload-videos_button': 'Caricare video',
  'multimedia_upload-videos_step-1_dragger_text-1': 'Clicca o trascina e rilascia file video',
  'multimedia_upload-videos_step-1': 'Seleziona video',
  'multimedia_upload-videos_modal_title': 'Carica video',
  // Upload banners
  'multimedia_upload-banners_button': 'Caricare banner',
  'multimedia_upload-banners_modal_title': 'Carica un banner',
  'multimedia_upload-banner_step-1': 'Seleziona un banner',
  'multimedia_upload-banners_step-1_dragger_text-1': 'Fai clic o trascina e rilascia un banner',
  ////////////////////////////

  // BRAND USERS /////////////////
  'brand-user_title': 'Utenti',
  'brand-user_description': 'Controlla come i membri del tuo team interagiscono con Hub4Retail.',
  'brand-user_create-user': 'Creare un utente',
  'brand-user_modal_title-create': 'Aggiungi utente',
  'brand-user_modal_title-edit': 'Modifica utente',
  'brand-user_modal_input_name': 'Nome',
  'brand-user_modal_input_lastname': 'Cognome',
  'brand-user_modal_input_email': 'Email',
  'brand-user_modal_input_password': 'Password',
  'brand-user_modal_input_btn-save': 'Salva',
  'brand-user_modal_input_btn-cancel': 'Annulla',
  'brand-user_table_name': 'Nome',
  'brand-user_table_email': 'Email',
  'brand-user_table_role': 'Ruolo',
  'brand-user_table_last-session': 'Ultima sessione',
  'brand-user_table_last-session-never': 'Mai',
  'brand-user_delete-popconfirm_title': 'Elimina l’utente',
  'brand-user_delete-popconfirm_description': 'Sei sicuro di voler eliminare questo utente?',
  'brand-user_delete-popconfirm_confirm': 'Sì',
  'brand-user_delete-popconfirm_cancel': 'No',
  ////////////////////////////////

  // CATALOG //////////////////
  'catalog_add-product': 'Aggiungi un prodotto',
  'catalog_ProductList_results': 'Risultati',
  'catalog_no-more-results': 'Nessun risultato trovato...',
  'catalog_tags_delete-all': 'Elimina tutto',
  'catalog_loading-filters': 'Caricamento filtri',
  // Sort by
  'catalog_sort-by_dateAsc': 'Ultimi aggiunti',
  'catalog_sort-by_dateDesc': 'Più vecchi',
  'catalog_sort-by_unitsAsc': 'Più venduti',
  'catalog_sort-by_unitsDesc': 'Meno venduti',
  'catalog_sort-by_nameAsc': 'Nome da A a Z',
  'catalog_sort-by_nameDesc': 'Nome da Z a A',
  'catalog_sort-by_colorAsc': 'Colore da A a Z',
  'catalog_sort-by_colorDesc': 'Colore da Z a A',
  'catalog_sort-by_pviAsc': 'PVI dal minore al maggiore',
  'catalog_sort-by_pviDesc': 'PVI dal maggiore al minore',
  'catalog_sort-by_pvprAsc': 'PVPR dal minore al maggiore',
  'catalog_sort-by_pvprDesc': 'PVPR dal maggiore al minore',
  'catalog_sort-by_referenceAsc': 'Riferimento da A a Z',
  'catalog_sort-by_referenceDesc': 'Riferimento da Z a A',
  'catalog_search-input-placeholder': 'Cerca per riferimento',
  // Sor by images
  'catalog_sort-by-images_with-images': 'Con immagini',
  'catalog_sort-by-images_without-images': 'Senza immagini',
  'catalog_sort-by-images_all': 'Tutti i prodotti',
  'catalog_sort-by-images_download-csv-products-without-images-tooltip': 'Scarica CSV di prodotti senza immagini',
  // Add catalog
  'catalog_add-catalog-csv-input-title': 'Carica CSV dei prodotti',
  'catalog_add-catalog-csv-s1-text-1': 'Opzione',
  'catalog_add-catalog-csv-s1-text-2': 'Puoi scaricare il nostro',
  'catalog_add-catalog-csv-s1-text-3': 'modello excel',
  'catalog_add-catalog-csv-s1-text-4': 'o',
  'catalog_add-catalog-csv-s1-text-5': 'Carica il tuo modello',
  'catalog_add-catalog-csv-s2-text-1': 'Carica CSV',
  'catalog_add-catalog-csv-s2-text-2': 'Carica immagini',
  'catalog_add-catalog-csv-s2-text-3': 'Determinare le date di inizio e fine delle stagioni',
  'catalog_add-catalog-csv_dowload-template-success': 'Modello scaricato con successo',
  // Correlation-Generic
  'catalog-correlation_select-all': 'Tutti i riferimenti',
  'catalog-correlation_select-no-associated': 'Riferimenti unici per generico H4R',
  'catalog-correlation_select-no-associated-at-all': 'Riferimenti non assegnati',
  'catalog-correlation_select-all_tooltip': 'Visualizza tutti i riferimenti disponibili',
  'catalog-correlation_select-no-associated_tooltip': 'Visualizza i riferimenti che non si ripetono in più di un generico H4R',
  'catalog-correlation_select-no-associated-at-all_tooltip': 'Visualizza i riferimenti che non sono assegnati a nessun generico H4R',
  'catalog-correlation_empty-values': 'Nessun valore selezionato da assegnare',
  'catalog-correlation_not-associated': 'Non assegnato',
  'catalog-correlation_season-label': 'Stagione',
  'catalog-correlation_gender-label': 'Genere',
  'catalog-correlation_color-label': 'Colore',
  'catalog-correlation_color-code-label': 'Codice colore',
  'catalog-correlation_segmentation-label': 'Segmentazione',
  'catalog-correlation_division-label': 'Divisione',
  'catalog-correlation_family-label': 'Famiglia',
  'catalog-correlation_table_column_h4r': 'Generico H4R',
  'catalog-correlation_table_accept-btn': 'Salva',
  'catalog-correlation_table_reject-btn': 'Annulla',
  'catalog-correlation_table_required-field': 'Campo richiesto',
  'catalog-correlation_table_add-field': 'Aggiungi',
  'catalog-correlation_pending-correlations': 'non assegnata',
  'catalog-correlation_pendings-correlations': 'non assegnate',
  // Upload product images
  'catalog_handle-images_pattern_title': 'Modello di immagine',
  'catalog_handle-images_pattern_description': 'Seleziona il modello del nome dell\'immagine:',
  'catalog_handle-images_pattern_separator': 'Separatore:',
  'catalog_handle-images_pattern_label': 'Modello:',
  'catalog_handle-images_pattern_save': 'Salva modello',
  'catalog_handle-images_pattern_create-message-success': 'Modello salvato correttamente',
  'catalog_handle-images_pattern_create-message-error': 'Errore nel salvataggio del modello',
  'catalog_handle-images_upload_title': 'Carica immagini',
  'catalog_handle-images_upload_upload-image-success': 'Immagine salvata',
  'catalog_handle-images_upload_upload-images-success': 'Immagini salvate',
  'catalog_handle-images_upload_upload-image-without-pattern-success': 'Immagine senza modello salvata',
  'catalog_handle-images_upload_upload-images-without-pattern-success': 'Immagini senza modello salvate',
  'catalog_handle-images_upload_upload-images-success-correctly': 'correttamente',
  'catalog_handle-images_upload_upload-image-error': 'Errore nel caricamento delle immagini',
  'catalog_handle-images_upload_upload-image-btn': 'Carica immagini',
  'catalog_handle-images_upload_selected-images': 'Immagini selezionate:',
  'catalog_handle-images_upload_previously-uploaded-images': 'Immagini caricate precedentemente:',
  'catalog_handle-images_upload_uploaded-images': 'Immagini caricate:',
  'catalog_handle-images_upload_images-without-pattern': 'Immagini senza modello:',
  'catalog_handle-images_upload_save-images-confirm': 'Vuoi salvare le immagini senza modello?',
  'catalog_handle-images_not-matched-images_title': 'Immagini non corrispondenti a nessun prodotto',
  'catalog_handle-images_not-matched-images_save-success': 'Immagini assegnate correttamente',
  'catalog_handle-images_not-matched-images_save-error': 'Errore nell\'assegnazione delle immagini',
  'catalog_handle-images_not-matched-images_search-placeholder': 'Cerca immagini per riferimento',
  'catalog_handle-images_not-matched-images_search-clear-btn': 'Cancella ricerca',
  'catalog_handle-images_not-matched-images_assign-product-btn': 'Assegna al prodotto',
  'catalog_handle-images_not-matched-images_cancel-product-selection': 'Annulla selezione',
  // Catalog download
  'catalog_download_alert_brand-required': 'Si prega di selezionare un marchio',
  'catalog_download_alert_get-filters-error': 'Errore nel recupero dei filtri',
  'catalog_download_alert_dowloading-compressed-files': 'Download dei file compressi in corso...',
  'catalog_download_progress-images': 'Immagini',
  'catalog_download_success-msg': 'Immagini scaricate correttamente',
  'catalog_download_success-msg-imgs-and-csv': 'CSV e immagini scaricati correttamente',
  'catalog_download_csv-msg-without-images': 'CSV scaricato correttamente',
  'catalog_download_csv-msg-pending-images': 'CSV scaricato correttamente, ma ci sono immagini in sospeso',
  'catalog_download_csv-msg-pending-images-navigate': 'CSV scaricato correttamente, ma ci sono immagini in sospeso. Puoi continuare a navigare mentre il download è in corso',
  'catalog_download_apply-changes-btn': 'Applica modifiche',
  'catalog_download_cancel-filter-btn': 'Annulla',
  'catalog_download_cancel-values-selection-btn': 'Cancella selezione',
  'catalog_download_cancel-values-download-btn': 'Annulla download',
  'catalog_download_clear-filters-btn': 'Cancella filtri',
  'catalog_download_result': 'risultato',
  'catalog_download_results': 'risultati',
  'catalog_download_columns_brand': 'Marchio',
  'catalog_download_columns_reference': 'Riferimento',
  'catalog_download_columns_ean': 'EAN',
  'catalog_download_columns_size': 'Taglia',
  'catalog_download_columns_name': 'Nome',
  'catalog_download_columns_color': 'Descrizione del colore',
  'catalog_download_columns_color_code': 'Codice colore',
  'catalog_download_columns_pvi': 'PVI',
  'catalog_download_columns_pvpr': 'PVPR',
  'catalog_download_columns_division': 'Divisione',
  'catalog_download_columns_gender': 'Genere',
  'catalog_download_columns_season': 'Stagione',
  'catalog_download_columns_segmentation': 'Segmentazione',
  'catalog_download_columns_family': 'Famiglia',
  'catalog_download_columns_material': 'Materiale',
  'catalog_download_columns_images': 'Immagini',
  'catalog_download_columns_all-images': 'Tutti i prodotti',
  'catalog_download_columns_with-images': 'Con immagini',
  'catalog_download_columns_without-images': 'Senza immagini',
  'catalog_download_columns_favorites': 'Preferiti',
  'catalog_download_columns_column-search_search': 'Cerca',
  'catalog_download_columns_column-search_reset': 'Ripristina',
  'catalog_download_columns_column-search_close': 'Chiudi',
  'catalog_download_list_select-all': 'Seleziona tutto',
  'catalog_download_list_clear-all': 'Cancella selezione',
  'catalog_download_list_download-btn': 'Scarica',
  'catalog_download_list_download-images-btn': 'Scarica immagini',
  'catalog_download_list_item': 'elemento',
  'catalog_download_list_items': 'elementi',
  'catalog_download_list_select-order_popover-title': 'Seleziona e ordina',
  ///////////////////////////

  // PRODUCT /////////////////
  'product_infoBox_codeEAN': 'Codice EAN',
  'product_collection_title': 'Tutta la collezione',
  'product_infoProduct_other_colors': 'Altri colori',
  'product_infoProduct_currency': '€',
  'product_infoProduct_sizes': 'Taglie e prezzi',
  'product_infoProduct_EAN_size': 'Taglie',
  'product_infoProduct_EAN_code': 'Codici EAN',
  'product_collapse_title_key1': 'Taglie',
  'product_collapse_title_key2': 'INFORMAZIONI GENERALI',
  'product_collapse_title_key3': 'COMPOSIZIONE',
  'product_card-color': 'colore',
  'product_mobile_product-details_sizes': 'Taglie e prezzi',
  'product_mobile_product-details_size': 'Taglia',
  'product_mobile_product-details_info': 'Informazioni Generali',
  'product_mobile_product-details_ean-codes': 'Codici EAN',
  'product_mobile_product-details_ean-code': 'Codice EAN',
  'product_mobile_product-details_composition': 'Composizione',
  'product_related-videos_title': 'Catalogo Multimediale',
  'product_related-videos_subtitle': 'Video Correlati',
  'product_related-products_title': 'Potrebbe anche interessarti...',
  'product_card_sold-units': 'Unità vendute:',
  // Edit product
  'product_edit-product_button': 'Modifica prodotto',
  'product_edit-product_modal_title': 'Modifica',
  'product_edit-product_modal_season': 'Stagione',
  'product_edit-product_modal_season_required': 'Inserisci la stagione',
  'product_edit-product_modal_color-name': 'Nome del colore',
  'product_edit-product_modal_color-name_required': 'Inserisci il nome del colore',
  'product_edit-product_modal_color-code': 'Codice colore',
  'product_edit-product_modal_color-code_required': 'Inserisci il codice del colore',
  'product_edit-product_modal_gender': 'Genere',
  'product_edit-product_modal_gender_required': 'Inserisci il genere',
  'product_edit-product_modal_family': 'Famiglia',
  'product_edit-product_modal_family_required': 'Inserisci la famiglia',
  'product_edit-product_modal_division': 'Divisione',
  'product_edit-product_modal_division_required': 'Inserisci la divisione',
  'product_edit-product_modal_segmentation': 'Segmentazione',
  'product_edit-product_modal_segmentation_required': 'Inserisci la segmentazione',
  'product_edit-product_modal_material': 'Composizione',
  'product_edit-product_modal_material_required': 'Inserisci la composizione',
  'product_edit-product_modal_cancel-button': 'Annulla',
  'product_edit-product_modal_save-button': 'Salva',
  ///////////////////////////

  // THANKS PAGE ////////////
  'ThanksForRegister__register': 'REGISTRAZIONE MARCHI',
  'ThanksForRegister__title': 'Grazie per il tuo interesse in Hub4Retail!',
  'ThanksForRegister__subtitle': 'La tua richiesta è in fase di elaborazione e ti contatteremo a breve.',
  'ThanksForRegister__button': 'Vai al sito web',
  ///////////////////////////

  // REGISTER BRAND PAGE ////
  'RegisterBrand__title1': 'Pronto a iniziare a',
  'RegisterBrand__title2': 'portare la tua azienda al',
  'RegisterBrand__title3': 'livello successivo?',
  'RegisterBrand__subtitle': 'Piattaforma progettata per:',
  'RegisterBrand__ex1': 'Migliorare gli strumenti di collaborazione e condivisione.',
  'RegisterBrand__ex2': 'Garantire la sicurezza dei dati in ogni momento.',
  'RegisterBrand__ex3': 'Aumentare i margini di profitto.',
  'RegisterBrand__button': 'Indietro',
  'RegisterBrand__register': 'Registrazione',
  'registerBrand__form__name': 'Nome',
  'registerBrand__form__lastName': 'Cognome',
  'registerBrand__form__business': 'Ragione Sociale',
  'registerBrand__form__company': 'Nome Commerciale',
  'registerBrand__form__phone': 'Web',
  'registerBrand__form__email': 'Telefono',
  'registerBrand__form__web': 'Email Corporativa',
  'registerBrand__form__description': 'Vuoi dirci qualcosa di più?',
  'registerBrand__form__selector': 'Settore',
  'registerBrand__form__say-more': 'Vuoi dirci qualcosa di più?',
  'registerBrand__form__selector__optionDefault': '',
  'registerBrand__form__selector__option1': 'Calzature',
  'registerBrand__form__selector__option2': 'Accessori',
  'registerBrand__form__selector__option3': 'Casa-Giardino',
  'registerBrand__form__selector__option4': 'Bellezza',
  'registerBrand__form__selector__option5': 'Elet',
  ///////////////////////////

  // CLIENTS ///////////////
  'clients_by-client-btn': 'Per cliente',
  'clients_by-points-sale-btn': 'Per punto vendita',
  'clients_add-client-link': '+ Aggiungi cliente',
  'clients_add-search-input': 'Cerca...',
  'clients_filters-btn': 'Filtri',
  // Table
  'clients_clients-table_businessName': 'ragione sociale',
  'clients_clients-table_tradeName': 'Nome del Marchio',
  'clients_clients-table_clientCode': 'Codice cliente',
  'clients_clients-table_city': 'c',
  'clients_clients-table_totalPointsSale': 'Totale punto vendita',
  'clients_clients-table_totalPointsSale_popover-title': 'Ci sono punti vendita con nuovi dati',
  'clients_clients-table_totalPointsSale_popover-btn': 'Vai ai punti vendita',
  'clients_clients-table_totalPointsSale_client-popover-title': 'ha aggiornato i dati',
  'clients_clients-table_totalPointsSale_client-popover-content': 'dati aggiunti o aggiornati',
  'clients_clients-table_totalPointsSale_client-popover-content-2': 'dati aggiunti o aggiornati',
  'clients_clients-table_totalPointsSale_client-popover-btn': 'Vai al cliente',
  'clients_client-table_status': 'Stato',
  'clients_client-table_action_see-profile': 'Visualizza profilo',
  'clients_client-table_action_remove-popconfirm_title': 'Rifiuta associazione',
  'clients_client-table_action_remove-popconfirm_description': 'Sei sicuro di voler rifiutare questa associazione?',
  'clients_client-table_action_remove-popconfirm_on-ok': 'Accetta',
  'clients_client-table_action_remove-popconfirm_on-cancel': 'Annulla',
  'clients_client-table_action_reject-popconfirm_title_it': 'Scollegare Associazione',
  'clients_client-table_action_reject-popconfirm_description_it': 'Sei sicuro di voler scollegare questa associazione?',
  'clients_client-table_action_reject-popconfirm_on-ok_it': 'Accettare',
  'clients_client-table_action_reject-popconfirm_on-cancel_it': 'Annullare',
  'client_clients-page_table_disassociate-modal_title_it': 'Scollegare Cliente',
  'client_clients-page_table_disassociate-modal_on-ok_it': 'Scollegare',
  'client_clients-page_table_disassociate-modal_on-cancel_it': 'Annullare',
  'client_clients-page_table_disassociate-modal_content_it': 'Sei sicuro di voler scollegare questo cliente e tutti i suoi punti vendita?',
  'clients_sale-point-table_businessName': 'Il ragione sociale',
  'clients_sale-point-table_shop-name': 'Nome del negozio',
  'clients_sale-point-table_category': 'Categoria',
  'clients_sale-point-table_tradeName': 'Nome del Marchio',
  'clients_sale-point-table_pointSaleCode': 'Codice punto vendita',
  'clients_sale-point-table_city': 'Città',
  'clients_sale-point-table_personae-and-tier': 'Personae e Tier',
  'clients_sale-point-table_share-data': 'Condividi dati',
  'clients_sale-point-table_share-data-popover-share': 'Ricezione dati di vendita del cliente.',
  'clients_sale-point-table_share-data-popover-no-share': 'Non ricezione dati di vendita del cliente.',
  'clients_sale-point-table_recive-data': 'Ricevi dati',
  'clients_sale-point-table_recive-data_green_it': 'Ricezione dati di vendita del cliente.',
  'clients_sale-point-table_recive-data_orange_it': 'Il cliente non sta condividendo i dati.',
  'clients_sale-point-table_recive-data_red_it': 'Non si stanno ricevendo dati dall\'obiettivo.',
  'clients_sale-point-table_connection': 'Connessione',
  'clients_sale-point-table_connection-popover-share': 'Connesso al terminale del punto vendita del cliente.',
  'clients_sale-point-table_connection-popover-no-share': 'Non connesso al terminale del punto vendita del cliente.',
  'clients_sale-point-table_active': 'Attivo',
  'clients_sale-point-table_inactive': 'Inattivo',
  'clients_sale-point-table_connected': 'Connesso',
  'clients_sale-point-table_not-connected': 'Non connesso',
  'clients_sale-point-table_total-clients': 'Totale clienti',
  'clients_sale-point-table_total-sale-points': 'Totale punti vendita',
  // Association
  'clients_association-modal_title': 'Associare cliente',
  'clients_association-modal_associate-label_associate_txt-1': 'Associare:',
  'clients_association-modal_associate-label_associate_txt-2': 'viene creata l\'associazione e si mantengono i dati del punto vendita proprio.',
  'clients_association-modal_associate-label_associate-update_txt-1': 'Associare e aggiornare:',
  'clients_association-modal_associate-label_associate-update_txt-2': 'viene creata l\'associazione e si copiano i dati del punto vendita da associare.',
  'clients_association-modal_update-label_associate_txt-1': 'Aggiornare:',
  'clients_association-modal_update-label_associate_txt-2': 'si copiano i dati del punto vendita con il punto vendita proprio.',
  'clients_association-modal_update-label_associate-update_txt-1': 'Aggiornare e associare:',
  'clients_association-modal_update-label_associate-update_txt-2': 'viene creata l\'associazione e si copiano i dati del punto vendita da associare.',
  'clients_pending-association-modal_title': 'Associazioni in sospeso',
  'clients_pending-association-modal_no-more': 'Non mostrare più',
  'clients_pending-association-modal_close': 'Chiudi',
  'clients_association-modal_on-ok': 'Accetta',
  'clients_association-modal_on-finish': 'Finire',
  'clients_association-modal_on-next': 'Prossimo',
  'clients_association-modal_on-cancel': 'Annulla',
  'clients_association-modal_ps_pdv-code_it': 'Codice PDV',
  'clients_association-modal_ps_associate_it': 'Associare',
  'clients_association-modal_ps_update_it': 'Aggiornare',
  'clients_association-modal_ps_empty-ps_it': 'Nessun nuovo punto vendita',
  'clients_association-table_item_client-code': 'Codice Cliente',
  'clients_association-table_item_cif': 'Numero CIF',
  'clients_association-table_item_trade-name': 'Nome Commerciale',
  'clients_association-table_item_business-name': 'Ragione Sociale',
  'clients_association-table_item_address': 'Indirizzo',
  'clients_association-table_item_postal-code': 'Codice Postale',
  'clients_association-table_item_city': 'Città',
  'clients_association-table_item_province': 'Provincia',
  'clients_association-table_item_country': 'Paese',
  'clients_association-table_item_contact_it': 'Contatto',
  'clients_association-table_item_email': 'Email',
  'clients_association-table_item_phone-number': 'Numero di Telefono',
  'clients_association-steps_step-1': 'Informazioni Generali',
  'clients_association-steps_step-2': 'Punti Vendita',
  // Add client form
  'clients_add-client-form-label_trade-name': 'Nome del Marchio',
  'clients_add-client-form-label_business-name': 'Il ragione sociale',
  'clients_add-client-form-label_client-code': 'Codice cliente',
  'clients_add-client-form-label_clientCode': 'Codice cliente',
  'clients_add-client-form-label_country': 'Paese',
  'clients_add-client-form-label_population': 'Città',
  'clients_add-client-form-label_province': 'Provincia',
  'clients_add-client-form-label_address': 'Indirizzo',
  'clients_add-client-form-label_postal-code': 'Codice postale',
  'clients_add-client-form-label_cif': 'Partita CIF (Codice di Identificazione Fiscale)',
  'clients_add-client-form-label_phone-number': 'Numero di telefono',
  'clients_add-client-form-label_email': 'Email',
  'clients_add-client-form-input-validation_trade-name': 'Per favore, inserisci il Nome del Marchio',
  'clients_add-client-form-input-validation_business-name': 'Per favore, inserisci la ragione sociale',
  'clients_add-client-form-input-validation_country': 'Per favore, inserisci il paese',
  'clients_add-client-form-input-validation_population': 'Per favore, inserisci la Città',
  'clients_add-client-form-input-validation_address': "Per favore, inserisci l'indirizzo",
  'clients_add-client-form-input-validation_postal-code': 'Per favore, inserisci il codice postale',
  'clients_add-client-form-input-validation_cif': 'Per favore, inserisci il Codice di Identificazione Fiscale CIF',
  'clients_add-client-form-input-validation_email-required': "Per favore, inserisci l'email",
  'clients_add-client-form-input-validation_email': 'Email non valida',
  'clients_add-client-form-input-validation_phone-number': 'Per favore, inserisci il numero di telefono',
  // Aggiungi cliente
  'clients_add-client_add-manually': 'Aggiungi cliente manualmente',
  'clients_add-client_add-by-csv-1': 'Carica CSV',
  'clients_add-client_add-by-csv-2': 'clienti',
  'clients_add-client_add-by-excel': 'Scarica modello Excel',
  // Menu cliente
  'clients_menu-desktop_resume': 'Riepilogo',
  'clients_menu-desktop_business-info': 'Informazioni sull\'azienda',
  'clients_menu-desktop_main-contacts': 'Contatti principali',
  'clients_menu-desktop_sale-points': 'Punti vendita',
  'clients_menu-desktop_commercial-info': 'Informazioni commerciali',
  // Client resume
  'client_resume_title': 'Catalogo disponibile',
  'client_resume_main-info-panel_title': 'Informazioni generali',
  'client_resume_main-info-panel_li-info_client-code': 'Codice cliente:',
  'client_resume_main-info-panel_li-info_trade-name': 'Nome del Marchio:',
  'client_resume_main-info-panel_li-info_web-page': 'Sito Web:',
  'client_resume_main-info-panel_li-info_points-of-sale': 'Punti Vendita:',
  'client_resume_main-info-panel_li-info_email': 'E-mail:',
  'client_resume_main-info-panel_li-info_category': 'Categoria:',
  'client_resume_main-info-panel_li-info_start-date': 'Data di inizio:',
  'client_resume_main-info-panel_li-info_start-season': 'Stagione di inizio:',
  'client_resume_main-info-panel_li-info_phone-number': 'Telefono:',
  'client_resume_main-info-panel_li-info_main-contact': 'Contatto principale:',
  'client_resume_main-info-panel_li-info_contact-email': 'E-mail contatto:',
  // Business Info
  'client_business-info_edit-button': 'Modifica',
  'client_business-info_name': 'Nome del Marchio',
  'client_business-info_legal_name': 'ragione sociale',
  'client_business-info_cif': 'CIF (Codice di Identificazione Fiscale)',
  'client_business-info_address': 'Indirizzo',
  'client_business-info_postal_code': 'Codice postale',
  'client_business-info_city': 'Città',
  'client_business-info_country': 'Paese',
  'client_business-info_province': 'Provincia',
  'client_business-info_email': 'E-mail',
  'client_business-info_contact_name': 'Contatto Principale',
  'client_business-info_phoneNumber': 'Numero di telefono',
  'client_business-info_clientCode': 'Codice cliente',
  'client_business-info_web': 'Sito web',
  'client_business-info_floor': 'Piano',
  'client_business-info_door': 'Porta',
  'client_business-info_upgrade_all-popover_title': 'Aggiorna nuovi dati',
  'client_business-info_upgrade_popover_update': 'Aggiorna',
  'client_business-info_upgrade_popover_update-all': 'Aggiorna tutto',
  'client_business-info_upgrade_update-field': 'campo da aggiornare',
  'client_business-info_upgrade_update-fields': 'campi da aggiornare',
  'client_business-info_upgrade_client-updates': 'è cambiato',
  'client_business-info_upgrade_new-value': 'Nuovo',
  'client_business-info_upgrade_new-actual': 'Attuale',
  // Client Contacts
  'client_business-client-contacts_title': 'Contatti principali',
  'client_business-client-contacts_add-contact': '+ Aggiungi contatto',
  'client_business-client-contacts_modal_title': 'Rimuovi contatto',
  'client_business-client-contacts_modal_text': 'Vuoi rimuovere il contatto dalla lista? Questa azione non lo elimina dai tuoi contatti.',
  'client_business-client-contacts_modal_ok-text': 'Rimuovi',
  'client_business-client-contacts_modal_cancel-text': 'Annulla',
  //////////////////////////

  // CLIENT ///////////////
  // Resume page
  'client_resume-page_history': 'Cronologia',
  'client_resume-page_contacts': 'Contatti',
  'client_resume-page_accept-association': 'Associare',
  'client_resume-page_reject-association': 'Rifiutare',
  'client_resume-page_accept-association_btn-title': 'Accettare associazione',
  'client_resume-page_reject-association_btn-title': 'Rifiutare associazione',
  'client_resume-page_client-brand-works_title': 'Marchi con cui lavora',
  'client-page_history-points-of-sale_associated': 'Associato',
  'client-page_history-points-of-sale_not-associated': 'Non associato',
  'client-page_history-active-contacts_name': 'Nome',
  'client-page_history-active-contacts_email': 'Email',
  'client-page_history-active-contacts_phone-number': 'Numero di telefono',
  'client-page_history-active-contacts_department': 'Dipartimento',
  // Commercial info page
  'client_commercial-info_title': 'Informazioni Commerciali',
  'client_commercial-info_add-agent': '+ Aggiungi Agente',
  'client_commercial-info_table_agent-name': 'Agente Commerciale / Distributore',
  'client_commercial-info_table_division': 'Divisione',
  'client_commercial-info_table_buy-group': 'Gruppo di Acquisto',
  'client_commercial-info_table_buy-group-true': 'Sì',
  'client_commercial-info_table_buy-group-false': 'No',
  // Sale points page
  'client_sale-points-page_title': 'Punti Vendita',
  'client_sale-points-page_add-sale-point': '+ Aggiungi',
  'client_sale-points-page_associate-sale-point': 'Punto di vendita associato',
  'client_sale-points-page_table_client-code': 'Codice',
  'client_sale-points-page_table_address': 'Indirizzo',
  'client_sale-points-page_table_city': 'Città',
  'client_sale-points-page_table_division': 'Divisione',
  'client_sale-points-page_table_personae': 'Personae',
  'client_sale-points-page_table_tier': 'Livello',
  'client_sale-points-page_table_pdv': 'Foto Punto Vendita',
  'client_sale-points-page_table_name': 'Nome',
  'client_sale-points-page_table_area': 'Superficie commerciale (M2)',
  'client_sale-points-page_table_category': 'Categoria',
  'client_sale-points-page_table_brands': 'Marchi',
  'client_sale-points-page_table_disassociate-modal_title_it': 'Scollegare Punto Vendita',
  'client_sale-points-page_table_disassociate-modal_on-ok_it': 'Scollegare',
  'client_sale-points-page_table_disassociate-modal_on-cancel_it': 'Annullare',
  'client_sale-points-page_table_disassociate-modal_content_it': 'Sei sicuro di voler scollegare questo punto vendita?',
  'client_sale-points-page_table_associate-modal_title_it': 'Collegare Punti Vendita',
  'client_sale-points-page_table_associate-modal_on-ok_it': 'Aggiornare',
  'client_sale-points-page_table_associate-modal_on-cancel_it': 'Annullare',
  'client_sale-points-page_table_status_associated': 'Associato',
  'client_sale-points-page_table_status_matching': 'In attesa',
  'points-of-sale_drawer_exchange_label': 'Tipo di valuta',
  'points-of-sale_drawer_add-edit-sale-point_exchange_input-validation': 'Per favore, inserisci il tipo di valuta',
  'client_sale-points-page_table_status_not-associated': 'Non Associato',
  'client_sale-points-page_upgrade_online': 'Negozio fisico',
  'client_sale-points-page_upgrade_category_id': 'Categoria',
  'client_sale-points-page_upgrade_name': 'Nome del negozio',
  'client_sale-points-page_upgrade_exchange_id': 'Tipo di valuta',
  'client_sale-points-page_upgrade_area': 'Area commerciale',
  'client_sale-points-page_upgrade_address': 'Indirizzo',
  'client_sale-points-page_upgrade_floor': 'Piano',
  'client_sale-points-page_upgrade_door': 'Porta',
  'client_sale-points-page_upgrade_city': 'Città',
  'client_sale-points-page_upgrade_province': 'Provincia',
  'client_sale-points-page_upgrade_postal_code': 'Codice postale',
  'client_sale-points-page_upgrade_country_id': 'Paese',
  'client_sale-points-page_upgrade_lat': 'Latitudine',
  'client_sale-points-page_upgrade_lng': 'Longitudine',
  'client_sale-points-page_upgrade_tel_prefix': 'Prefisso telefonico',
  'client_sale-points-page_upgrade_tel_sufix': 'Numero di telefono',
  'client_sale-points-page_upgrade_web': 'Web',
  'client_sale-points-page_upgrade_popconfirm-title': 'Aggiorna dati dei punti vendita',
  'client_sale-points-page_upgrade_popconfirm-description': 'Sei sicuro di voler aggiornare i dati di tutti i punti vendita?',
  'client_sale-points-page_upgrade_upgrade-btn': 'Aggiorna punti vendita',
  // Point of sale info modal
  'points-of-sale_point-of-sale-info_close-btn': 'Chiudi',
  // Info
  'points-of-sale_point-of-sale-info_info_title': 'Informazioni',
  'points-of-sale_point-of-sale-info_info_code': 'Codice',
  'points-of-sale_point-of-sale-info_info_store-type': 'Tipo di negozio',
  'points-of-sale_point-of-sale-info_info_store-type_online': 'Online',
  'points-of-sale_point-of-sale-info_info_store-type_physic': 'Fisico',
  'points-of-sale_point-of-sale-info_info_category': 'Categoria',
  'points-of-sale_point-of-sale-info_info_web': 'Pagina Web',
  'points-of-sale_point-of-sale-info_info_exchange-type': 'Tipo di valuta',
  'points-of-sale_point-of-sale-info_info_start-season': 'Inizio stagione',
  'points-of-sale_point-of-sale-info_info_division': 'Divisione',
  'points-of-sale_point-of-sale-info_info_segmentation': 'Personae',
  'points-of-sale_point-of-sale-info_info_tier': 'Livello',
  // Connection
  'points-of-sale_point-of-sale-info_connection_title': 'Connessione',
  'points-of-sale_point-of-sale-info_connection_no-associated': 'Nessuna associazione con il punto vendita',
  'points-of-sale_point-of-sale-info_connection_meta-connection': 'Connessione con Meta',
  'points-of-sale_point-of-sale-info_connection_meta-connection_true': 'Connesso',
  'points-of-sale_point-of-sale-info_connection_meta-connection_false': 'Non connesso',
  'points-of-sale_point-of-sale-info_connection_meta-receiving': 'Ricezione dati da Meta',
  'points-of-sale_point-of-sale-info_connection_meta-receiving_true': 'Ricevendo',
  'points-of-sale_point-of-sale-info_connection_meta-receiving_false': '24 ore senza ricezione',
  'points-of-sale_point-of-sale-info_connection_data-share': 'Il cliente condivide i dati',
  'points-of-sale_point-of-sale-info_connection_data-share_true': 'Condividendo',
  'points-of-sale_point-of-sale-info_connection_data-share_false': 'Non condivide',
  // Contact
  'points-of-sale_point-of-sale-info_contact_title': 'Contatto',
  'points-of-sale_point-of-sale-info_contact_contact-name': 'Persona di contatto',
  'points-of-sale_point-of-sale-info_contact_phone-number': 'Telefono di contatto',
  'points-of-sale_point-of-sale-info_contact_email': 'Email',
  'points-of-sale_point-of-sale-info_contact_address': 'Indirizzo',
  // Brand works
  'points-of-sale_point-of-sale-info_contact_brand-works_title_0': 'marchi in',
  'points-of-sale_point-of-sale-info_contact_brand-works_title_1': 'marca in',
  'points-of-sale_point-of-sale-info_contact_brand-works_empty': 'Ancora nessun marchio nel punto vendita',
  // Drawers
  // Sale points
  'client_drawer_add-edit-sale-point_title': 'Dati del punto di vendita',
  'client_drawer_add-edit-sale-point_name': 'Nome',
  'client_drawer_add-edit-sale-point_point-of-sale-type_it': 'Tipo di Punto Vendita',
  'client_drawer_add-edit-sale-point_point-of-sale-type_physical_it': 'Negozio Fisico',
  'client_drawer_add-edit-sale-point_point-of-sale-type_online_it': 'Negozio Online',
  'client_drawer_add-edit-sale-point_category_it': 'Categoria',
  'client_drawer_add-edit-sale-point_client-code': 'Codice',
  'client_drawer_add-edit-sale-point_client-area': 'Superficie commerciale (M2)',
  'client_drawer_add-edit-sale-point_address': 'Indirizzo',
  'client_drawer_add-edit-sale-point_postal-code': 'Codice Postale',
  'client_drawer_add-edit-sale-point_city': 'Città',
  'client_drawer_add-edit-sale-point_country': 'Paese / Regione',
  'client_drawer_add-edit-sale-point_province': 'Stato / Provincia',
  'client_drawer_add-edit-sale-point_name_input-validation': 'Inserisci il nome del punto vendita',
  'client_drawer_add-edit-sale-point_category_input-validation_it': 'Per favore, inserisci la categoria del punto vendita',
  'client_drawer_add-edit-sale-point_client-code_input-validation': 'Per favore, inserisci il codice cliente',
  'client_drawer_add-edit-sale-point_address_input-validation': 'Per favore, inserisci l\'indirizzo',
  'client_drawer_add-edit-sale-point_postal-code_input-validation': 'Per favore, inserisci il codice postale',
  'client_drawer_add-edit-sale-point_city_input-validation': 'Per favore, inserisci la città',
  'client_drawer_add-edit-sale-point_country_input-validation': 'Per favore, inserisci Paese / Regione',
  'client_drawer_add-edit-sale-point_province_input-validation': 'Per favore, inserisci Stato / Provincia',
  'client_drawer_add-edit-sale-point_division': 'Divisione',
  'client_drawer_add-edit-sale-point_personae': 'Personae',
  'client_drawer_add-edit-sale-point_tier': 'Livello',
  'client_drawer_add-edit-sale-point_share-sale-point-title': 'Classificazione Punto Vendita',
  'client_drawer_add-edit-sale-point_share-sale-point-data': 'Desideri condividere i dati con questo punto vendita?',
  'client_drawer_add-edit-sale-point_share-sale-point-data-true': 'Sì',
  'client_drawer_add-edit-sale-point_share-sale-point-data-false': 'No',
  // Commercial info
  'client_drawer_client-commercial-info_title': 'Assegna Agente',
  'client_drawer_client-commercial-info_select': 'Agenti',
  'client_drawer_client-commercial-info_select-placeholder': 'Seleziona agenti',
  'client_drawer_client-commercial-info_add-agent': '+ Aggiungi Agente',
  // Client contacts
  'client_drawer_client-contacts_title': 'Contatti Principali',
  // Edit client
  'client_drawer_edit-client_title': 'Dati dell\'Azienda',
  ///////////////////////////

  // ACTIONS ////////////////
  // Clients
  'clients_actions_add-client-success': 'Cliente creato con successo',
  'clients_actions_update-client-success': 'Cliente aggiornato con successo',
  'clients_actions_update-client-error': "Errore nell'aggiornamento del cliente",
  'clients_actions_client-exists': 'Il cliente esiste già',
  'clients_actions_add-point-of-sale-success': 'Punto vendita creato con successo',
  'clients_actions_add-point-of-sale-error': 'Errore nella creazione del punto vendita',
  'clients_actions_update-point-of-sale-success': 'Punto vendita aggiornato con successo',
  'clients_actions_upgrade-point-of-sale-success': 'Punto vendita aggiornato con successo',
  'clients_actions_upgrade-points-of-sale-success': 'Punti vendita aggiornati con successo',
  'clients_actions_update-point-of-sale-error': "Errore nell'aggiornamento del punto vendita",
  'clients_actions_share-data-point-of-sale-success': 'Condivisione dati con il punto vendita',
  'clients_actions_dont-share-data-point-of-sale-success': 'Hai smesso di condividere dati con il punto vendita',
  'clients_actions_association-success_it': 'Cliente associato correttamente',
  'clients_actions_association-error_it': 'Errore nell\'associare il cliente',
  'clients_actions_reject-association-success_it': 'Disassociazione effettuata con successo',
  'clients_actions_reject-association-error_it': 'Errore nella disassociazione del cliente',
  'clients_actions_accept-ps-association-success_it': 'Punto vendita collegato correttamente',
  'clients_actions_accept-ps-association-error_it': 'Errore nello scollegare il punto vendita',
  // Commercial Profile
  'commercial-profile_actions_init-bio_error': 'Errore nel recupero della biografia',
  'commercial-profile_actions_update-bio_success': 'Biografia aggiornata con successo',
  'commercial-profile_actions_update-bio_error': 'Errore nell\'aggiornamento della biografia',
  // Catalog Correlation
  'catalog-correlation_assign-generics_success': 'Generici assegnati con successo',
  'catalog-correlation_assign-generics_error': 'Errore durante l\'assegnazione dei generici',
  // Catalog CSV upload
  'catalog-csv-upload_publish-upload-success-1': 'Catalogo pubblicato con successo',
  'catalog-csv-upload_publish-upload-success-2': 'Catalogo nascosto con successo',
  'catalog-csv-upload_publish-upload-error-1': 'Errore nella pubblicazione del catalogo',
  'catalog-csv-upload_publish-upload-error-2': 'Errore nel nascondere il catalogo',
  'catalog-csv-upload_reject-upload-success': 'Catalogo eliminato con successo',
  'catalog-csv-upload_reject-upload-error': 'Errore nell\'eliminazione del catalogo',
  'catalog-csv-upload_set-start-season-date-success': 'Data di inizio stagione aggiornata con successo',
  'catalog-csv-upload_set-start-season-date-error': 'Errore nell\'aggiornamento della data di inizio stagione',
  'catalog-csv-upload_set-end-season-date-success': 'Data di fine stagione aggiornata con successo',
  'catalog-csv-upload_set-end-season-date-error': 'Errore nell\'aggiornamento della data di fine stagione',
  'catalog-csv-upload_enable-season-success-1': 'Stagione abilitata con successo',
  'catalog-csv-upload_enable-season-success-2': 'Stagione disabilitata con successo',
  'catalog-csv-upload_enable-season-error-1': 'Errore nell\'abilitazione della stagione',
  'catalog-csv-upload_enable-season-error-2': 'Errore nella disabilitazione della stagione',
  'catalog-csv-upload_get-mappings-error-1': 'Errore nel recupero delle chiavi di mappatura',
  'catalog-csv-upload_get-mappings-error-2': 'Errore del server nel recupero delle chiavi di mappatura',
  'catalog-csv-upload_delete-mapping-success': 'Mappatura eliminata con successo',
  'catalog-csv-upload_delete-mapping-error': 'Errore nell\'eliminazione della mappatura',
  'catalog-csv-upload_create-mapping-success': 'Mappatura creata con successo',
  'catalog-csv-upload_create-mapping-error': 'Errore nella creazione della mappatura',
  // Commercial profile
  'commercial-proile_actions_set-banner-action-success': 'Banner aggiornato con successo',
  'commercial-proile_actions_set-banner-action-error': 'Errore durante l\'aggiornamento del banner',
  'commercial-proile_actions_set-banner-action-server-error': 'Errore del server durante l\'aggiornamento del banner',
  'commercial-proile_actions_set-products-action-success': 'Prodotti aggiornati con successo',
  'commercial-proile_actions_set-products-action-error': 'Errore durante l\'aggiornamento dei prodotti',
  'commercial-proile_actions_set-products-action-server-error': 'Errore del server durante l\'aggiornamento dei prodotti',
  'commercial-proile_actions_remove-product-action-error': 'Errore durante l\'eliminazione del prodotto',
  'commercial-proile_actions_remove-product-action-server-error': 'Errore del server durante l\'eliminazione del prodotto',
  'commercial-proile_actions_set-videos-action-success': 'Video aggiornati con successo',
  'commercial-proile_actions_set-videos-action-error': 'Errore durante l\'aggiornamento dei video',
  'commercial-proile_actions_set-videos-action-server-error': 'Errore del server durante l\'aggiornamento dei video',
  'commercial-proile_actions_remove-video-action-error': 'Errore durante l\'eliminazione del video',
  'commercial-proile_actions_remove-video-action-server-error': 'Errore del server durante l\'eliminazione del video',
  ///////////////////////////

  // SETTINGS PAGE ////////
  'settings-page_nav_chronos': 'Chronos',
  'settings-page_nav_devices': 'Dispositivi',
  'settings-page_nav_touch-screen': 'Schermi Tattili',
  'settings-page_nav_users': 'Utenti',
  'settings-page_nav_system-settings': 'Impostazioni di Sistema',
  'settings-page_nav_commercial-profile': 'Profilo Commerciale del Marchio',
  'settings-page_nav_billing': 'Fatturazione',
  'settings-page_nav_support': 'Supporto',
  // Users page
  'settings-page_users-page_create-user-btn': 'Crea utente',
  /////////////////////////

  // COMMERCIAL PROFILE ////////////
  'commercial-profile_title': 'Profilo Commerciale',
  'commercial-profile_btn-edit': 'Modifica',
  'commercial-profile_btn-save': 'Salva',
  'commercial-profile_banner_title': 'Carica Banner',
  'commercial-profile_banner_p-1': 'Il banner apparirà sul profilo commerciale del tuo marchio per promuoverlo e connetterti con i negozi multimarca.',
  'commercial-profile_banner_p-2': 'Carica un banner di 1800x200 px.',
  'commercial-profile_banner_p-3': 'Assicurati che sia di buona qualità e attraente per attirare i negozi multimarca.',
  'commercial-profile_about-us_title': 'Chi Siamo',
  'commercial-profile_about-us_p-1': 'Racconta qualcosa sulla tua marca per attirare i negozi multimarca. Fino a un massimo di 100 parole.',
  'commercial-profile_about-us_p-2': 'La descrizione apparirà sul profilo commerciale del tuo marchio per promuoverlo e connetterti con i negozi multimarca.',
  'commercial-profile_our-products_title': 'I Nostri Prodotti',
  'commercial-profile_our-products_p': 'Seleziona 5-10 prodotti dalla tua ultima stagione.',
  'commercial-profile_our-videos_title': 'Alcuni dei nostri video...',
  'commercial-profile_our-videos_p': 'Seleziona 4 prodotti dalla tua ultima stagione.',
  'commercial-profile_banner_modal_title': 'Seleziona Banner',
  'commercial-profile_modal_onOk': 'Accetta',
  'commercial-profile_modal_onCancel': 'Annulla',
  'commercial-profile_banner-title': 'Seleziona un banner per il tuo profilo commerciale',
  'commercial-profile_modal-content_banners-empty-msg': 'Non ci sono ancora banner.',
  'commercial-profile_modal-content_navigate-banners-btn': 'Vai ai banner multimediali',
  'commercial-profile_modal-content_videos-empty-msg': 'Non ci sono ancora video.',
  'commercial-profile_modal-content_navigate-videos-btn': 'Vai ai video multimediali',
  'commercial-profile_our-products_modal_title': 'Seleziona prodotti',
  'commercial-profile_our-products_modal_max-items-msg': 'Puoi selezionare solo 10 prodotti.',
  'commercial-profile_our-products_modal_see-more-btn': 'Vedi di più',
  'commercial-profile_our-videos_modal_title': 'Seleziona video',
  'commercial-profile_our-videos_modal_max-items-msg': 'Puoi selezionare solo 4 video.',
  'commercial-profile_search-input-placeholder_it': 'Cerca per nome o codice',
  //////////////////////////////////

  // NOTIFICATIONS /////////////////
  // Notifications Page
  'notifications-page_notifications-title': 'Notifiche',
  'notifications-page_alerts-title': 'Allerte',
  'notifications_btn-name_catalog': 'Vedi Catalogo',
  'notifications_btn-name_profile': 'Vedi Profilo',
  'notifications_list_show-all': 'Tutto',
  'notifications_list_show-unreaded': 'Non letto',
  'notifications_list_per-page-select': 'per pagina',
  // Popover
  'notifications_popover_title': 'NOTIFICHE',
  'notifications_popover_empty-notifications': 'Nessuna notifica',
  'notifications_popover_btn-name_catalog': 'Vedi Catalogo',
  'notifications_popover_btn-name_profile': 'Vedi Profilo',
  'notifications_popover_item_mark-as-readed_msg': 'Segnato come letto',
  'notifications_popover_item_mark-as-unreaded_msg': 'Segnato come non letto',
  'notifications_popover_item_mark-as-readed': 'Segna come letto',
  'notifications_popover_item_mark-as-unreaded': 'Segna come non letto',
  'notifications_popover_all-notifications-btn': 'Tutte le notifiche',
  'notifications_popover_accept': 'Accetta',
  'notifications_popover_reject': 'Rifiuta',
  'notifications_popover_pending_it': 'In sospeso',
  'alerts_popover_title': 'ALLERTE',
  'alerts_popover_empty-notifications': 'Nessuna allerta',
  'alerts_popover_all-notifications-btn': 'Tutte le allerte',
  //////////////////////////////////////

  // ALIMENTAZIONE ALL'INGROSSO /////////////////
  'wholesale-feed_info_title': 'Informazioni Generali',
  'wholesale-feed_info_city': 'Città',
  'wholesale-feed_info_province': 'Provincia',
  'wholesale-feed_main-contact_title': 'Contatto Principale',
  'wholesale-feed_main-contact_name': 'Nome',
  'wholesale-feed_main-contact_email': 'Email',
  'wholesale-feed_main-contact_phone-number': 'Numero di Telefono',
  'wholesale-feed_main-contact_sector': 'Settore',
  'wholesale-feed_points-of-sale_title': 'Punti Vendita',
  'wholesale-feed_working-brands_title': 'Marchi Trattati',
  ///////////////////////////////////

  // MESSAGE PAGES //////////
  // Error
  'errors-page_404_subtitle': 'Spiacenti, la pagina che stai cercando non esiste.',
  'errors-page_404_btn-go-back': 'Torna indietro',
  // Coming soon
  'coming-soon-page_title': 'Prossimamente!',
  'coming-soon-page_go-back': 'Torna indietro',
  /////////////////////////

  // BREADCRUMBS ///////////////
  'breadcrumbs_multimedia': 'Multimedia',
  'breadcrumbs_multimedia-videos': 'Video',
  'breadcrumbs_multimedia-images': 'Immagini',
  'catalog_breadcrumb_item1': 'Catalogo',
  'catalog_breadcrumb_item2': 'Vedi catalogo',
  'breadcrumbs_multimedia-banners': 'Banner',
  'catalog-uploads_breadcrumb': 'Caricamento cataloghi',
  'catalog-uploads_handle-images_breadcrumb': 'Gestione delle immagini',
  'breadcrumb_catalog-download': 'Scarica il catalogo',
  'catalog-uploads-preview_breadcrumb': 'Anteprima',
  'add-catalog_breadcrumb': 'Aggiungi catalogo',
  'catalog_correlation': 'Correlazione di riferimenti',
  'catalog_generic': 'Generici H4R',
  //Clients
  'breadcrumbs_clients': 'Clienti',
  'breadcrumbs_clients-list': 'Elenco clienti',
  'breadcrumbs_add-client': 'Aggiungi cliente',
  'breadcrumbs_resume': 'Riepilogo',
  'breadcrumbs_business-info': 'Informazioni sull\'azienda',
  'breadcrumbs_main-contacts': 'Contatti principali',
  'breadcrumbs_sale-points': 'Punti vendita',
  'breadcrumbs_commercial-info': 'Informazioni commerciali',
  // Account
  'breadcrumbs_account': 'Account',
  'breadcrumbs_account_profile': 'Profilo',
  'breadcrumbs_account_company': "Dati dell'azienda",
  'breadcrumbs_account_contacts': 'Contatti Principali',
  'breadcrumbs_account_commercial-info': 'Informazioni Commerciali',
  // Impostazioni
  'breadcrumbs_settings': 'Impostazioni',
  'breadcrumbs_commercial-profile': 'Profilo Commerciale',
  //////////////////////////////

  // MODALS ////////////////////
  'modal_email-verified_title': 'Controlla la tua email',
  'modal_email-verified_content': 'Per procedere con il processo, controlla la tua email.',
  'modal_email-verified_content-1': 'Verifica l\'indirizzo dal link ricevuto via email e poi clicca su',
  'modal_email-verified_content_btn_resend': 'Rispedisci verifica',
  'modal_email-verified_content_btn_reload': 'Ricarica',
  'modal_email-verified_content_notif_resend_title': 'Notifica di rispedizione',
  'modal_email-verified_content_notif_resend_content-1': 'La email di verifica è stata inviata a questo indirizzo',
  'modal_email-verified_content_notif_resend_content-2': 'Controlla la tua email per continuare.',
  //////////////////////////////

  // POLICY PRIVACY //////////
  'policy-privacy-modal_title': 'Termini di servizio',
  'policy-privacy-modal_btn-accept': 'Accetta',
  'policy-privacy-modal_btn-cancel': 'Annulla',
  'policy-privacy-modal_checkbox': 'Accetto i Termini di servizio',
  'policy-privacy-modal_checkbox-message': 'Accetta l\'informativa sulla privacy e le condizioni.',
  ////////////////////////////

  // SERVER DOWN MODAL /////
  'server-down-modal_title': 'Errore del server',
  'server-down-modal_content': 'Stiamo riscontrando problemi con i nostri servizi. Riprova più tardi o contatta l\'assistenza tecnica.',
  //////////////////////////

  // NOTIFICATIONS /////////
  'notification-error_message': 'Codice di errore',
  'notification-error_description': 'Riprova più tardi. Se il problema persiste, contatta Hub4Retail e fornisci il codice di errore.',
  //////////////////////////

  // GOOGLE ADDRESS FORM //////
  'google-address-form_address': 'Indirizzo',
  'google-address-form_floor': 'Piano',
  'google-address-form_door': 'Porta',
  'google-address-form_postal-code': 'Codice postale',
  'google-address-form_city': 'Città',
  'google-address-form_province': 'Provincia',
  'google-address-form_country': 'Paese',
  'google-address-form_select-country': 'Seleziona un paese',
  // Countries
  'google-address-form_country_spain': 'Spagna',
  'google-address-form_country_italy': 'Italia',
  'google-address-form_country_france': 'Francia',
  'google-address-form_country_portugal': 'Portogallo',
  'google-address-form_country_united kingdom': 'Regno Unito',
  'google-address-form_country_afghanistan': 'Afghanistan',
  'google-address-form_country_brazil': 'Brasile',
  'google-address-form_country_canada': 'Canada',
  'google-address-form_country_india': 'India',
  'google-address-form_country_japan': 'Giappone',
  'google-address-form_country_kenya': 'Kenya',
  'google-address-form_country_mexico': 'Messico',
  'google-address-form_country_nigeria': 'Nigeria',
  'google-address-form_country_philippines': 'Filippine',
  'google-address-form_country_russia': 'Russia',
  'google-address-form_country_south africa': 'Sudafrica',
  'google-address-form_country_thailand': 'Thailandia',
  'google-address-form_country_turkey': 'Turchia',
  'google-address-form_country_argentina': 'Argentina',
  'google-address-form_country_bangladesh': 'Bangladesh',
  'google-address-form_country_colombia': 'Colombia',
  'google-address-form_country_egypt': 'Egitto',
  'google-address-form_country_indonesia': 'Indonesia',
  'google-address-form_country_iran': 'Iran',
  'google-address-form_country_poland': 'Polonia',
  'google-address-form_country_vietnam': 'Vietnam',
  'google-address-form_country_south korea': 'Corea del Sud',
  /////////////////////////////

  // CSV MAPPING FIELDS //////
  'csv-mapping-feld__product_description': 'Descrizione del prodotto',
  'csv-mapping-feld__reference': 'Riferimento',
  'csv-mapping-feld__color': 'Colore',
  'csv-mapping-feld__season': 'Stagione',
  'csv-mapping-feld__ean': 'EAN',
  'csv-mapping-feld__gender': 'Genere',
  'csv-mapping-feld__material': 'Composizione',
  'csv-mapping-feld__segmentation': 'Segmentazione',
  'csv-mapping-feld__family': 'Famiglia',
  'csv-mapping-feld__division': 'Divisione',
  'csv-mapping-feld__tier': 'Segmentazione Catalogo (Tier)',
  'csv-mapping-feld__color_description': 'Descrizione del colore',
  'csv-mapping-feld__pvi': "WHP (Prezzo all'ingrosso)",
  'csv-mapping-feld__pvpr': 'PVP (Prezzo di vendita consigliato)',
  'csv-mapping-feld__size': 'Taglia',
  'csv-mapping-feld__tags': 'Etichette',
  /////////////////////////////

  // LANGUAGES /////////////////
  'spanish-lang': 'Spagnolo',
  'english-lang': 'Inglese',
  'french-lang': 'Francese',
  'italian-lang': 'Italiano',
  ////////////////////////////

  // SHARED //////////////////
  'settings': 'Impostazioni',
  'logout': 'Esci',
  'admin-panel_link': 'Pannello di amministrazione',
  'see-more': 'Vedi di più',
  'see-less': 'Vedi di meno',
  'see-all': 'Vedi tutto',
  'save-btn': 'Salva',
  'edit-btn-label': 'Modifica',
  'delete-btn-label': 'Elimina',
  'cancel-btn': 'Annulla',
  'see-more-content-button': 'Vedi di più',
  'never-label': 'Mai',
  'clean-form': 'Pulisci il modulo',
  ////////////////////////////
}

export default italianTranslation;