import { useReducer, useMemo, useCallback, memo, useEffect } from 'react'
import SuperAdminContext from './SuperAdminContext'
import initialSuperAdminState from './initialSuperAdminState'
import superAdminReducer from './reducer/superAdmin.reducer'
import * as action from "./reducer/superAdmin.actions";
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { IInitialSuperAdminState } from 'interfaces/superAdmin.interface';
import { IBrandUser } from 'interfaces/brandUsers.interface';
import useContextUser from 'hooks/contexts/useContextUser';
import { superAdminRole } from 'utils/constants';
import { ChildrenProps } from 'types';

function SuperAdminProvider(props: ChildrenProps) {
	const [saState, dispatch] = useReducer(superAdminReducer, initialSuperAdminState)
	const superAdminState: IInitialSuperAdminState = saState
	const { company, dbUser } = useContextUser()
	const { getAccessTokenSilently } = useAuth0()
	const { t: translate } = useTranslation()

	useEffect(() => {
		if (dbUser.role !== superAdminRole) return
		(async () => {
			const token = await getAccessTokenSilently()
			action.getClientsAction(dispatch, token, translate)
		})()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [translate, company.brand?.id, dbUser.role])

	const removeUserClient = useCallback(async (tenantId: string, userId: string) => {
		action.removeUserClientAction(dispatch, superAdminState.clients, tenantId, userId, translate)
	}, [superAdminState.clients, translate])

	const addUserClient = useCallback(async (tenantId: string, user: IBrandUser) => {
		action.addUserClientAction(dispatch, superAdminState.clients, tenantId, user, translate)
	}, [superAdminState.clients, translate])

	const memoProvider = useMemo(
		() => ({
			...superAdminState,
			removeUserClient,
			addUserClient
		}), [
		superAdminState,
		removeUserClient,
		addUserClient
	]
	);

	return (
		<SuperAdminContext.Provider value={memoProvider}>
			{props.children}
		</SuperAdminContext.Provider>
	)
}

export default memo(SuperAdminProvider)