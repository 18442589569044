import { ISAdminUserTenantState } from 'interfaces/superAdmin.interface';
import * as UserTypes from './user.types'
import { ICompanyForm } from 'interfaces/user.interface';
import moment from 'moment';
import { TCompanyAPIResponse } from 'api/responseInterfaces/company.response.interface';
import { TLanguageResponseItem } from 'api/responseInterfaces/general.response.interface.';

export default function userReducer(state: any, action: any) {
	const { type, payload } = action;
	const companyResponse = payload.company as TCompanyAPIResponse

	switch (type) {
		case UserTypes.LOGIN_USER:
			return {
				dbUser: {
					...state.dbUser,
					id: payload.user.id,
					auth0Id: payload.user.auth_id,
					email: payload.user.email,
					name: payload.user.name,
					surname: payload.user.surname,
					telephone_prefix: payload.user.telephone_prefix,
					telephone_sufix: payload.user.telephone_sufix,
					profile_image: payload?.user?.profile_image ? `${payload?.user?.profile_image}?ts=${moment().unix()}` : null,
					language: {
						...state.dbUser.language,
						id: payload.user?.i18n_lang?.id ?? 'd54451a1-674a-4f97-a769-acff8ab795bb',
						code: payload.user?.i18n_lang?.code ?? 'es',
					},
					role: payload.user.role,
					sector: payload.user.sector,
					signed: payload.user.signed,
					first_login: payload.user.first_login,
					enabled: payload.user.enabled,
					user_tenants: payload.user.user_tenants,
				},
				company: {
					...state.company,
					id: companyResponse?.id,
					cif: companyResponse?.cif,
					name: companyResponse?.name,
					legal_name: companyResponse?.legal_name,
					address: companyResponse?.address,
					postal_code: companyResponse?.postal_code,
					city: companyResponse?.city,
					province: companyResponse?.province,
					floor: companyResponse?.floor,
					door: companyResponse?.door,
					lat: companyResponse?.lat,
					lng: companyResponse?.lng,
					country: {
						id: payload.user.user_tenants ? payload.user.user_tenants[0]?.tenant?.country_id : '',
						name: companyResponse?.country?.name,
					},
					email: companyResponse?.email,
					contact_name: companyResponse?.contact_name,
					telephone_prefix: companyResponse?.tel_prefix,
					telephone_sufix: companyResponse?.tel_sufix,
					logo: companyResponse?.logo ? `${companyResponse?.logo}?ts=${moment().unix()}` : null,
					brand: companyResponse?.brand,
					web: companyResponse?.web,
					instagram: companyResponse?.instagram ?? '',
					youtube: companyResponse?.youtube ?? '',
					facebook: companyResponse?.facebook ?? '',
					linkedin: companyResponse?.linkedin ?? '',
					social_media: companyResponse?.tenant_social_media
				},
			}

		case UserTypes.LOGIN_COMPANY_USER_SUPER_ADMIN:
			const user = payload.user as ISAdminUserTenantState
			return {
				dbUser: {
					...state.dbUser,
					id: user.user_id,
					auth0Id: user.user.auth_id,
					email: user.user.username,
					name: user.user.name,
					surname: user.user.surname,
					telephone_prefix: user.user.tel_prefix,
					telephone_sufix: user.user.tel_sufix,
					profile_image: user.user.profile_image ? `${user.user.profile_image}?ts=${moment().unix()}` : null,
					language: {
						...state.dbUser.language,
						id: user.user.i18n_lang_id ?? 'd54451a1-674a-4f97-a769-acff8ab795bb',
						code: payload.languages.find((lang: TLanguageResponseItem) => lang.id === user.user.i18n_lang_id).code ?? 'es',
					},
					sector: user.user.sector,
					signed: user.user.signed,
					first_login: user.user.first_login,
					enabled: user.user.enabled,
				},
				company: {
					...state.company,
					id: companyResponse.id,
					cif: companyResponse.cif,
					name: companyResponse.name,
					legal_name: companyResponse.legal_name,
					address: companyResponse.address,
					postal_code: companyResponse.postal_code,
					city: companyResponse.city,
					province: companyResponse.province,
					country: {
						id: companyResponse.country_id,
						name: companyResponse.country.name,
					},
					floor: companyResponse.floor,
					door: companyResponse.door,
					lat: companyResponse.lat,
					lng: companyResponse.lng,
					contact_name: companyResponse.contact_name,
					email: companyResponse.email,
					brand: companyResponse.brand,
					telephone_prefix: companyResponse.tel_prefix,
					telephone_sufix: companyResponse.tel_sufix,
					logo: companyResponse?.logo ? `${companyResponse?.logo}?ts=${moment().unix()}` : null,
					web: companyResponse.web,
					instagram: companyResponse.instagram,
					youtube: companyResponse.youtube,
					facebook: companyResponse.facebook,
					linkedin: companyResponse.linkedin,
					social_media: companyResponse.tenant_social_media,
				},
			}

		case UserTypes.LOGIN_COMPANY_SUPER_ADMIN:
			return {
				...state,
				company: {
					...state.company,
					id: companyResponse.id,
					cif: companyResponse.cif,
					name: companyResponse.name,
					legal_name: companyResponse.legal_name,
					address: companyResponse.address,
					postal_code: companyResponse.postal_code,
					city: companyResponse.city,
					province: companyResponse.province,
					country: {
						id: companyResponse.country.id,
						name: companyResponse.country.name,
					},
					floor: companyResponse.floor,
					door: companyResponse.door,
					lat: companyResponse.lat,
					lng: companyResponse.lng,
					contact_name: companyResponse.contact_name,
					email: companyResponse.email,
					brand: companyResponse.brand,
					telephone_prefix: companyResponse.tel_prefix,
					telephone_sufix: companyResponse.tel_sufix,
					logo: companyResponse.brand.logo ? `${companyResponse.brand.logo}?ts=${moment().unix()}` : null,
					web: companyResponse.web,
					instagram: companyResponse.instagram,
					youtube: companyResponse.youtube,
					facebook: companyResponse.facebook,
					linkedin: companyResponse.linkedin,
					social_media: companyResponse.tenant_social_media,
				},
			}

		case UserTypes.UPDATE_USER:
			return {
				...state,
				dbUser: {
					...state.dbUser,
					name: payload.name,
					surname: payload.surname,
					telephone_prefix: payload.telephone_prefix,
					telephone_sufix: payload.telephone_sufix,
					sector: payload.sector,
				}
			}

		case UserTypes.UPDATE_COMPANY:
			const { name, legal_name, province, city, address, country_id, country_name, postal_code, floor, door, lat, lng, email, tel_prefix, tel_sufix, cif, contact_name, web, instagram, facebook, youtube, linkedin } = payload as ICompanyForm
			return {
				...state,
				company: {
					...state.company,
					name: name ?? '',
					legal_name: legal_name ?? '',
					province: province ?? '',
					city: city ?? '',
					address: address ?? '',
					country: {
						id:  country_id ?? '',
						name:  country_name ?? '',
					},
					postal_code: postal_code ?? '',
					floor: floor ?? '',
					door: door ?? '',
					lat: lat ?? '',
					lng: lng ?? '',
					email: email ?? '',
					telephone_prefix:  tel_prefix ?? '',
					telephone_sufix:  tel_sufix ?? '',
					cif: cif ?? '',
					contact_name: contact_name ?? '',
					web: web ?? '',
					instagram: instagram ?? '',
					youtube: youtube ?? '',
					linkedin: linkedin ?? '',
					facebook: facebook ?? ''
				}
			}

		case UserTypes.UPDATE_USER_AVATAR:
			return {
				...state,
				dbUser: {
					...state.dbUser,
					profile_image: `${payload.url}?ts=${moment().unix()}`
				}
			}

		case UserTypes.UPDATE_COMPANY_LOGO:
			return {
				...state,
				company: {
					...state.company,
					logo: `${payload.url}?ts=${moment().unix()}`
				}
			}

		case UserTypes.VERIFY_EMAIL:
			return {
				...state,
				dbUser: {
					...state.dbUser,
					enabled: payload
				}
			}

		case UserTypes.DELETE_USER:
			return {
				...state,
				// userAuth0: payload
			}

		case UserTypes.UPDATE_LANGUAGE:
			return {
				...state,
				dbUser: {
					...state.dbUser,
					language: payload
				}
			}

		case UserTypes.SET_ASSOCIATIONS_STATES:
			return {
				...state,
				dbUser: {
					...state.dbUser,
					associationsStates: payload
				}
			}

		case UserTypes.ADD_SOCIAL_MEDIA:
			return {
				...state,
				company: {
					...state.company,
					social_media: [...state.company.social_media, payload]
				}
			}

		case UserTypes.SET_SOCIAL_MEDIA:
			return {
				...state,
				company: {
					...state.company,
					social_media: payload
				}
			}

		case UserTypes.SET_IS_LOADING:
			return {
				...state,
				isLoading: payload
			}

		default:
			return state
	}
}