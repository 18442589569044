export const GET_CLIENTS = 'GET_CLIENTS'
export const SET_CLIENTS = 'SET_CLIENTS'
export const SET_ALL_CLIENTS = 'SET_ALL_CLIENTS'
export const SET_POINTS_OF_SALE = 'SET_POINTS_OF_SALE'
export const SET_ALL_POINTS_OF_SALE = 'SET_ALL_POINTS_OF_SALE'
export const CREATE_CLIENT = 'CREATE_CLIENT'
export const UPDATE_CLIENT = 'UPDATE_CLIENT'
export const UPGRADE_CLIENT = 'UPGRADE_CLIENT'
export const IS_LOADING_UPGRADE_CLIENT = 'IS_LOADING_UPGRADE_CLIENT'
export const SELECT_CLIENT = 'SELECT_CLIENT'
export const SELECT_POINT_OF_SALE = 'SELECT_POINT_OF_SALE'
export const CREATE_POINT_OF_SALE = 'CREATE_POINT_OF_SALE'
export const UPDATE_POINT_OF_SALE = 'UPDATE_POINT_OF_SALE'
export const SET_IS_LOADING_CLIENT = 'SET_IS_LOADING_CLIENT'
export const REFRESH_CLIENTS = 'REFRESH_CLIENTS'
export const SET_QUERY_CLIENTS_SEARCH = 'SET_QUERY_CLIENTS_SEARCH'
export const SET_QUERY_POINTS_OF_SALE_SEARCH = 'SET_QUERY_POINTS_OF_SALE_SEARCH'
export const SET_POINTS_OF_SALE_CATEGORIES = 'SET_POINTS_OF_SALE_CATEGORIES'
export const UPDATE_ASSOCIATION_INFO = 'UPDATE_ASSOCIATION_INFO'
export const SET_EXCHANGES = 'SET_EXCHANGES'
export const SET_SEGMENTATIONS = 'SET_SEGMENTATIONS'
export const SET_DATA_COLUMNS_WITH_CHANGES = 'SET_DATA_COLUMNS_WITH_CHANGES'
export const SET_DATA_COLUMNS = 'SET_DATA_COLUMNS'