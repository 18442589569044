import { TResponseAPI } from "interfaces/general.interface"
import { basePath } from "./utils/config"
import { TCountryItem } from "interfaces/countries.interface"

type TGetCountryDataApi = {
	data: TCountryItem[]
}
export const getCountriesAPI = async (token: string): Promise<TResponseAPI & TGetCountryDataApi> => {
	const params = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			filter: {},
			select: "",
			limit: 1000,
			index: 0
		}),
	}
	const response = await fetch(`${basePath}/crud_country/findManyCrud_country`, params)
	const data = await response.json()
	return { response, data }
}