import { useReducer, useMemo, useCallback, memo } from 'react'
import ComercialInfoContext from './ComercialInfoContext'
import initialComercialInfoState from './initialComercialInfoState'
import userReducer from 'contexts/comercialInfo/reducer/comercialInfo.reducer'
import * as action from "contexts/comercialInfo/reducer/comercialInfo.actions";
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import useContextUser from 'hooks/contexts/useContextUser';
import { ChildrenProps, CommercialInfo } from 'types';

function ComercialInfoProvider(props: ChildrenProps) {
	const [comercialInfo, dispatch] = useReducer(userReducer, initialComercialInfoState)
	const { getAccessTokenSilently } = useAuth0()
	const { t: translate } = useTranslation()
	const { company } = useContextUser()
	const { id: tenant_id, brand } = company

	const getCommercialInfo = useCallback(async () => {
		const token = await getAccessTokenSilently()
		if (tenant_id) {
			action.getCommercialInfoAction(dispatch, tenant_id, token, translate)
		}
	}, [tenant_id, getAccessTokenSilently, translate])

	const selectComercialContact = useCallback(async (contact: any) => {
		const token = await getAccessTokenSilently()
		action.selectComercialContactAction(dispatch, contact, translate, token)
	}, [getAccessTokenSilently, translate])

	const createCommercialInfoContact = useCallback(async (contact: any, setIsOpenDrawer: (isOpen: boolean) => void) => {
		const token = await getAccessTokenSilently()
		action.createCommercialInfoContactAction(dispatch, brand?.id, contact, token, setIsOpenDrawer, translate)
	}, [getAccessTokenSilently, brand?.id, translate])

	const updateCommercialInfoContact = useCallback(async (contact: CommercialInfo, setIsOpenDrawer: (isOpen: boolean) => void) => {
		const token = await getAccessTokenSilently()
		action.updateCommercialInfoContactAction(dispatch, comercialInfo, contact, token, setIsOpenDrawer, translate)
	}, [comercialInfo, getAccessTokenSilently, translate])
	
	const removeCommercialAgent = useCallback(async (contactId: string) => {
		const token = await getAccessTokenSilently()
		action.removeCommercialAgent(dispatch, contactId, comercialInfo.contacts, token, translate)
	}, [getAccessTokenSilently, comercialInfo, translate])

	const memoProvider = useMemo(
		() => ({
			...comercialInfo,
			getCommercialInfo,
			selectComercialContact,
			updateCommercialInfoContact,
			removeCommercialAgent,
			createCommercialInfoContact
		}), [
		comercialInfo,
		getCommercialInfo,
		selectComercialContact,
		updateCommercialInfoContact,
		removeCommercialAgent,
		createCommercialInfoContact
	]
	);

	return (
		<ComercialInfoContext.Provider value={memoProvider}>
			{props.children}
		</ComercialInfoContext.Provider>
	)
}

export default memo(ComercialInfoProvider)