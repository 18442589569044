import { useReducer, useMemo, useCallback, memo } from 'react'
import BrandUsersContext from './BrandUsersContext'
import initialBrandUsersState from './initialBrandUsersState'
import brandUsersReducer from 'contexts/brandUsers/reducers/brandUsers.reducer'
import * as action from "contexts/brandUsers/reducers/brandUsers.actions";
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { IInitialBrandUsersState } from 'interfaces/brandUsers.interface';
import useContextUser from 'hooks/contexts/useContextUser';
import useContextSuperAdmin from 'hooks/contexts/useContextSuperAdmin';
import { ChildrenProps } from 'types';

function BrandUsersProvider(props: ChildrenProps) {
	const [bsState, dispatch] = useReducer(brandUsersReducer, initialBrandUsersState)
	const brandUsers: IInitialBrandUsersState = bsState
	const { getAccessTokenSilently } = useAuth0()
	const { t: translate } = useTranslation()
	const { dbUser, company } = useContextUser()
	const { brand } = company
	const { addUserClient } = useContextSuperAdmin()

	const getBrandUsers = useCallback(async () => {
		const token = await getAccessTokenSilently()
		if (brand?.id) {
			action.getBrandUsersAction(dispatch, brand.id, dbUser.role, token, translate)
		}
	}, [brand?.id, dbUser.role, getAccessTokenSilently, translate])

	const selectBrandUser = useCallback((user: any) => {
		user && action.selectBrandUserAction(dispatch, user, translate)
	}, [translate])

	const createBrandUser = useCallback(async (newBrandUser: any, setIsOpenModal: (isOpen: boolean) => void) => {
		const token = await getAccessTokenSilently()
		if (!company.id) return
		action.createBrandUserAction(dispatch, company.id, newBrandUser, dbUser.language.id, token, setIsOpenModal, translate, addUserClient)
	}, [getAccessTokenSilently, company.id, dbUser.language?.id, translate, addUserClient])

	const updateBrandUser = useCallback(async (user: any, userId: string, setIsOpenDrawer: (isOpen: boolean) => void, updateRole: boolean) => {
		const token = await getAccessTokenSilently()
		action.updateBrandUserAction(dispatch, brandUsers, user, userId, updateRole, token, setIsOpenDrawer, translate)
	}, [getAccessTokenSilently, brandUsers, translate])

	const deleteBrandUser = useCallback(async (userId: string) => {
		const token = await getAccessTokenSilently()
		action.deleteBrandUserAction(dispatch, userId, token, translate)
	}, [getAccessTokenSilently, translate])

	const memoProvider = useMemo(
		() => ({
			...brandUsers,
			getBrandUsers,
			selectBrandUser,
			createBrandUser,
			updateBrandUser,
			deleteBrandUser
		}), [
		brandUsers,
		getBrandUsers,
		selectBrandUser,
		createBrandUser,
		updateBrandUser,
		deleteBrandUser
	]
	);

	return (
		<BrandUsersContext.Provider value={memoProvider}>
			{props.children}
		</BrandUsersContext.Provider>
	)
}

export default memo(BrandUsersProvider)