/* eslint-disable react-hooks/exhaustive-deps */
import { useReducer, useMemo, useCallback, memo, useEffect } from 'react'
import MultimediaContext from './MultimediaContext'
import initialMultimediaState from './initialMultimediaState'
import userReducer from 'contexts/multimedia/reducer/multimedia.reducer'
import * as action from "contexts/multimedia/reducer/multimedia.actions";
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import useContextUser from 'hooks/contexts/useContextUser';
import { IInitialMultimediaState, MultimediaItemState, MultimediaTypes, TCreateMultimedia, TMediaState } from 'interfaces/multimedia.interface';
import { abortCatalogController } from 'utils/abortController';
import * as MTypes from './reducer/multimedia.types'
import { ChildrenProps } from 'types';
import useContextCatalog from 'hooks/contexts/useContextCatalog';

function MultimediaProvider(props: ChildrenProps) {
	const [multimediaState, dispatch] = useReducer(userReducer, initialMultimediaState)
	const multimedia = multimediaState as IInitialMultimediaState
	const { getAccessTokenSilently } = useAuth0()
	const { company } = useContextUser()
	const { productAttributes } = useContextCatalog()
	const { t: translate } = useTranslation()

	useEffect(() => {
		if (!company.brand?.id) return
		dispatch({ type: MTypes.RESET_MEDIA })
	}, [company.brand?.id])

	const getMedia = useCallback(async (type: MultimediaTypes) => {
		const signal = abortCatalogController()
		const token = await getAccessTokenSilently()
		company.brand?.id && action.getMediaAction(dispatch, ((multimedia[type as keyof typeof multimedia] as TMediaState)), company.brand?.id, type, signal, translate, token)
	}, [company.brand?.id, multimedia, translate])

	const getMoreMedia = useCallback(async (type: MultimediaTypes) => {
		const signal = abortCatalogController()
		const token = await getAccessTokenSilently()
		company.brand?.id && action.getMoreMediaAction(dispatch, ((multimedia[type as keyof typeof multimedia] as TMediaState)), company.brand?.id, type, signal, translate, token)
	}, [company.brand?.id, multimedia, translate])

	const searchMedia = useCallback(async (query: string, type: MultimediaTypes) => {
		const signal = abortCatalogController()
		const token = await getAccessTokenSilently()
		action.searchMediaAction(dispatch, ((multimedia[type as keyof typeof multimedia] as TMediaState)), query, type, signal, translate, token)
	}, [multimedia, translate])

	const createMultimedia = useCallback(async ({ body, media, videoThumbnailMedia, setCurrentStep, type, products }: TCreateMultimedia) => {
		const token = await getAccessTokenSilently()
		action.createMultimediaAction(dispatch, company, type, body, media, videoThumbnailMedia, setCurrentStep, productAttributes, products, translate, token)
	}, [company, translate, productAttributes])

	const setItemSelected = useCallback((item: MultimediaItemState) => {
		dispatch({ type: MTypes.SET_ITEM_SELECTED, payload: item })
	}, [])

	const setIsOpenEditModal = useCallback((showEditModal: boolean) => {
		dispatch({ type: MTypes.SET_IS_OPEN_EDIT_MODAL, payload: showEditModal })
	}, [])

	const removeMultimedia = useCallback(async ({ mediaId, multimediaType }: { mediaId: string, multimediaType: MultimediaTypes }) => {
		const token = await getAccessTokenSilently()
		return await action.removeMultimediaAction(dispatch, mediaId, multimediaType, translate, token) as boolean
	}, [getAccessTokenSilently, translate])

	const memoProvider = useMemo(
		() => ({
			...multimedia,
			getMedia,
			getMoreMedia,
			setItemSelected,
			removeMultimedia,
			searchMedia,
			createMultimedia,
			setIsOpenEditModal
		}), [
		multimedia,
		getMedia,
		getMoreMedia,
		setItemSelected,
		removeMultimedia,
		searchMedia,
		createMultimedia,
		setIsOpenEditModal
	]
	);

	return (
		<MultimediaContext.Provider value={memoProvider}>
			{props.children}
		</MultimediaContext.Provider>
	)
}

export default memo(MultimediaProvider)