import * as CatalogCorrelationsType from './catalogCorrelations.types'

export default function catalogCorrelationsReducer(state: any, action: any) {
	const { type, payload } = action;

	switch (type) {
		case CatalogCorrelationsType.SET_CORRELATIONS:
			return {
				...state,
				correlations: {
					...state.correlations,
					[payload.type]: payload.data
				}
			}

		case CatalogCorrelationsType.SET_IS_LOADING:
			return {
				...state,
				isLoading: payload
			}

		case CatalogCorrelationsType.SET_IS_LOADING_ASSIGN_GENERICS:
			return {
				...state,
				isLoadingAssignGenerics: payload
			}

		default:
			return state
	}
}