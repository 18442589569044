import { IBodyMultimediaFetch, IInitialMultimediaState, MultimediaItemState } from "interfaces/multimedia.interface"
import { bannerType, imageType, videoType } from "views/pages/main/multimediaPage/components/constants"

export const mediaDefaultLimit = 15

const bodyMultimediaImagesFetch: IBodyMultimediaFetch = {
	options: {
		profile: false,
		brand_id: [],
		multimedia_type: [imageType],
		search: [],
		limit: mediaDefaultLimit,
		index: 0
	},
	filter: {
		tag: [],
		product: [],
		division: [],
		gender: [],
		country: [],
		season: []
	}
}
const bodyMultimediaBannersFetch: IBodyMultimediaFetch = {
	options: {
		profile: false,
		brand_id: [],
		multimedia_type: [bannerType],
		search: [],
		limit: mediaDefaultLimit,
		index: 0
	},
	filter: {
		tag: [],
		product: [],
		division: [],
		gender: [],
		country: [],
		season: []
	}
}
const bodyMultimediaVideosFetch: IBodyMultimediaFetch = {
	options: {
		profile: false,
		brand_id: [],
		multimedia_type: [videoType],
		search: [],
		limit: mediaDefaultLimit,
		index: 0
	},
	filter: {
		tag: [],
		product: [],
		division: [],
		gender: [],
		country: [],
		season: []
	}
}

const initialMultimediaState: IInitialMultimediaState = {
	image: {
		items: [] as MultimediaItemState[],
		itemSelected: {} as MultimediaItemState,
		bodyMultimediaFetch: bodyMultimediaImagesFetch,
		isLoading: true,
		isLoadingMoreMedia: false,
		thereAreMoreMedia: true,
		search: {
			media: [],
			query: '',
		}
	},
	video: {
		items: [] as MultimediaItemState[],
		itemSelected: {} as MultimediaItemState,
		bodyMultimediaFetch: bodyMultimediaVideosFetch,
		isLoading: true,
		isLoadingMoreMedia: false,
		thereAreMoreMedia: true,
		search: {
			media: [],
			query: '',
		}
	},
	banner: {
		items: [] as MultimediaItemState[],
		itemSelected: {} as MultimediaItemState,
		bodyMultimediaFetch: bodyMultimediaBannersFetch,
		isLoading: true,
		isLoadingMoreMedia: false,
		thereAreMoreMedia: true,
		search: {
			media: [],
			query: '',
		}
	},
	getMedia: () => { },
	getMoreMedia: () => { },
	itemSelected: {} as MultimediaItemState,
	setItemSelected: () => { },
	removeMultimedia: () => Promise.resolve(false),
	isOpenEditModal: false,
	setIsOpenEditModal: () => { },
	searchMedia: () => { },
	createMultimedia: () => { },
	isLoadingCreateMultimedia: false,
}

export default initialMultimediaState