/* eslint-disable react-hooks/exhaustive-deps */
import { useReducer, useMemo, useCallback, memo } from 'react'
import SuperAdminContext from './CatalogCsvUploadContext'
import initialCatalogCsvUploadState from './initialCatalogCsvUploadState'
import catalogCsvUploadReducer from './reducer/catalogCsvUpload.reducer'
import * as action from "./reducer/catalogCsvUpload.actions";
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { ICreateMapping, IInitialCatalogCsvUploadState, IReportsBySeason } from 'interfaces/catalogCsvUpload.interface';
import useContextUser from 'hooks/contexts/useContextUser';
import * as CatalogCsvUploadType from './reducer/catalogCsvUpload.types'
import { ChildrenProps } from 'types';

function CatalogCsvUploadProvider(props: ChildrenProps) {
	const [ccuState, dispatch] = useReducer(catalogCsvUploadReducer, initialCatalogCsvUploadState)
	const catalogCsvUploadtate: IInitialCatalogCsvUploadState = ccuState
	const { getAccessTokenSilently, isLoading } = useAuth0()
	const { company } = useContextUser()
	const { t: translate } = useTranslation()

	const getReportsByUpload = useCallback(async () => {
		const token = await getAccessTokenSilently()
		if (isLoading || !token || !company.brand?.id) return
		action.getReportsByUploadAction(dispatch, company.brand.id, token, translate)
	}, [translate, company.brand?.id, isLoading])

	const publishUpload = useCallback(async (uploadId: string, publish: boolean) => {
		const token = await getAccessTokenSilently()
		if (isLoading || !token || !company.brand?.id) return
		action.publishUploadAction(dispatch, uploadId, company.brand?.id, publish, token, translate)
	}, [company.brand?.id, isLoading, translate])

	const rejectUpload = useCallback(async (uploadId: string) => {
		const token = await getAccessTokenSilently()
		if (isLoading || !token || !company.brand?.id) return
		action.rejectUploadAction(dispatch, uploadId, company.brand?.id, token, translate)
	}, [company.brand?.id, isLoading, translate])

	const getReportsBySeason = useCallback(async () => {
		const token = await getAccessTokenSilently()
		if (isLoading || !token || !company.brand?.id) return
		action.getReportsBySeasonAction(dispatch, company.brand.id, token, translate)
	}, [company.brand?.id, isLoading, translate])

	const setStartSeasonDate = useCallback(async (season: IReportsBySeason, date: any) => {
		const token = await getAccessTokenSilently()
		if (isLoading || !token) return
		action.setStartSeasonDateAction(dispatch, season, date, token, translate)
	}, [isLoading, translate])

	const setEndSeasonDate = useCallback(async (season: IReportsBySeason, date: any) => {
		const token = await getAccessTokenSilently()
		if (isLoading || !token) return
		action.setEndSeasonDateAction(dispatch, season, date, token, translate)
	}, [isLoading, translate])

	const enableSeason = useCallback(async (season: IReportsBySeason, enable: boolean) => {
		const token = await getAccessTokenSilently()
		if (isLoading || !token) return
		action.enableSeasonAction(dispatch, season, enable, token, translate)
	}, [isLoading, translate])

	const setCSVSelected = useCallback((data: Papa.ParseResult<unknown>, file: File, navigate?: Function) => {
		action.setCSVSelectedAction(dispatch, data, file, navigate)
	}, [])

	const setIsLoadingCSVSelected = useCallback((isLoading: boolean) => {
		dispatch({ type: CatalogCsvUploadType.SET_IS_LOADING_CSV_SELECTED, payload: isLoading })
	}, [])

	const getMappings = useCallback(async () => {
		const token = await getAccessTokenSilently()
		if (isLoading || !token || !company.brand?.id) return
		action.getMappingsAction(dispatch, company.brand?.id, token, translate)
	}, [isLoading, company.brand?.id])

	const deleteMapping = useCallback(async (mappingId: string) => {
		const token = await getAccessTokenSilently()
		if (isLoading || !token) return
		action.deleteMappingAction(dispatch, mappingId, token, translate)
	}, [isLoading])

	const createMapping = useCallback(async (body: ICreateMapping) => {
		const token = await getAccessTokenSilently()
		if (isLoading || !token || !company.brand?.id) return
		body.brand_id = company.brand?.id
		action.createMappingAction(dispatch, body, token, translate)
	}, [isLoading, company.brand?.id])

	const saveCatalogCSVFile = useCallback(async () => {
		const token = await getAccessTokenSilently()
		if (isLoading || !token) return false
		return await action.saveCatalogCSVFileAction(dispatch, catalogCsvUploadtate, company, token, translate) as boolean
	}, [getAccessTokenSilently, isLoading, catalogCsvUploadtate, company, translate])

	const memoProvider = useMemo(
		() => ({
			...catalogCsvUploadtate,
			getReportsByUpload,
			publishUpload,
			rejectUpload,
			getReportsBySeason,
			setStartSeasonDate,
			setEndSeasonDate,
			enableSeason,
			setCSVSelected,
			getMappings,
			deleteMapping,
			createMapping,
			setIsLoadingCSVSelected,
			saveCatalogCSVFile
		}), [
		catalogCsvUploadtate,
		getReportsByUpload,
		publishUpload,
		rejectUpload,
		getReportsBySeason,
		setStartSeasonDate,
		setEndSeasonDate,
		enableSeason,
		setCSVSelected,
		getMappings,
		deleteMapping,
		createMapping,
		setIsLoadingCSVSelected,
		saveCatalogCSVFile
	]
	);

	return (
		<SuperAdminContext.Provider value={memoProvider}>
			{props.children}
		</SuperAdminContext.Provider>
	)
}

export default memo(CatalogCsvUploadProvider)