import { IReportsBySeason, IReportsByUpload } from 'interfaces/catalogCsvUpload.interface';
import * as CatalogCsvUploadType from './catalogCsvUpload.types'

export default function superAdminReducer(state: any, action: any) {
	const { type, payload } = action;

	switch (type) {
		case CatalogCsvUploadType.SET_REPORTS_BY_UPLOAD:
			return {
				...state,
				reportsByUpload: payload
			}

		case CatalogCsvUploadType.SET_IS_LOADING_BY_UPLOAD:
			return {
				...state,
				isLoadingByUpload: payload
			}

		case CatalogCsvUploadType.SET_PUBLISH_UPLOAD:
			return {
				...state,
				reportsByUpload: state.reportsByUpload.map((report: IReportsByUpload) => {
					if (report.id === payload.uploadId) {
						return { ...report, published: payload.publish }
					}
					return report
				})
			}

		case CatalogCsvUploadType.SET_IS_LOADING_PUBLISH_UPLOAD:
			return {
				...state,
				isLoadingPublishUpload: payload
			}

		case CatalogCsvUploadType.SET_REJECT_UPLOAD:
			return {
				...state,
				reportsByUpload: state.reportsByUpload.filter((report: IReportsByUpload) => report.id !== payload)
			}

		case CatalogCsvUploadType.SET_IS_LOADING_REJECT_UPLOAD:
			return {
				...state,
				isLoadingRejectUpload: {
					isLoading: payload.isLoading,
					uploadId: payload.uploadId
				}
			}

		case CatalogCsvUploadType.SET_REPORTS_BY_SEASON:
			return {
				...state,
				reportsBySeason: payload
			}

		case CatalogCsvUploadType.SET_IS_LOADING_BY_SEASON:
			return {
				...state,
				isLoadingBySeason: payload
			}

		case CatalogCsvUploadType.SET_START_SEASON_DATE:
			return {
				...state,
				reportsBySeason: state.reportsBySeason.map((report: IReportsBySeason) => {
					if (report.id === payload.id) {
						return { ...report, start: payload.date }
					}
					return report
				})
			}

		case CatalogCsvUploadType.SET_END_SEASON_DATE:
			return {
				...state,
				reportsBySeason: state.reportsBySeason.map((report: IReportsBySeason) => {
					if (report.id === payload.id) {
						return { ...report, end: payload.date }
					}
					return report
				})
			}

		case CatalogCsvUploadType.SET_PUBLISH_SEASON:
			return {
				...state,
				reportsBySeason: state.reportsBySeason.map((report: IReportsBySeason) => {
					if (report.id === payload.id) {
						return { ...report, enabled: payload.enabled }
					}
					return report
				})
			}

		case CatalogCsvUploadType.SET_IS_LOADING_ENABLE_SEASON:
			return {
				...state,
				isLoadingEnableSeason: {
					isLoading: payload.isLoading,
					seasonId: payload.seasonId
				}
			}

		case CatalogCsvUploadType.SET_CSV_SELECTED:
			return {
				...state,
				csvSelected: {
					papa: payload.papa,
					file: payload.file
				}
			}

		case CatalogCsvUploadType.SET_IS_LOADING_CSV_SELECTED:
			return {
				...state,
				isLoadingCSVSelected: payload
			}

		case CatalogCsvUploadType.SET_CSV_MAPPINGS:
			return {
				...state,
				csvMappings: payload
			}

		case CatalogCsvUploadType.SET_CSV_MAPPING_KEYS:
			return {
				...state,
				csvMappingKeys: payload
			}

		case CatalogCsvUploadType.SET_IS_LOADING_CSV_MAPPINGS:
			return {
				...state,
				isLoadingCSVMappings: payload
			}

		case CatalogCsvUploadType.DELETE_MAPPING:
			return {
				...state,
				csvMappings: state.csvMappings.filter((mapping: any) => mapping.id !== payload)
			}

		case CatalogCsvUploadType.CREATE_MAPPING:
			return {
				...state,
				csvMappings: [...state.csvMappings, payload]
			}

		case CatalogCsvUploadType.SET_IS_LOADING_SAVE_CSV:
			return {
				...state,
				isLoadingSaveCatalogCSVFile: payload
			}

		default:
			return state
	}
}