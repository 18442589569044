import { MultimediaItemState } from 'interfaces/multimedia.interface';
import * as CommercialProfileTypes from './commercialProfile.types'
import { ICatalogItem } from 'interfaces/catalog.interface';

export default function commercialProfileReducer(state: any, action: any) {
	const { type, payload } = action;

	switch (type) {
		case CommercialProfileTypes.SET_BANNER:
			return {
				...state,
				banner: payload
			}

		case CommercialProfileTypes.SET_BIO:
			return {
				...state,
				bio: payload
			}

		case CommercialProfileTypes.SET_PRODUCTS:
			return {
				...state,
				products: payload
			}

		case CommercialProfileTypes.REMOVE_PRODUCT:
			return {
				...state,
				products: state.products.filter((product: ICatalogItem) => product.id !== payload.id)
			}

		case CommercialProfileTypes.SET_VIDEOS:
			return {
				...state,
				videos: payload
			}

		case CommercialProfileTypes.REMOVE_VIDEO:
			return {
				...state,
				videos: state.videos.filter((video: MultimediaItemState) => video.id !== payload.id)
			}

		// Catalog
		case CommercialProfileTypes.GET_CATALOG:
			return {
				...state,
				catalogItems: payload
			}

		case CommercialProfileTypes.SET_IS_LOADING:
			return {
				...state,
				isLoading: payload
			}

		case CommercialProfileTypes.SET_BODY_PRODUCT_SEARCH:
			return {
				...state,
				bodyProductSearch: payload
			}

		case CommercialProfileTypes.SET_BODY_PRODUCT_SEARCH_TEMP:
			return {
				...state,
				bodyProductSearchTemp: payload
			}

		case CommercialProfileTypes.INCREMENT_PAGINATION_INDEX:
			return {
				...state,
				bodyProductSearch: {
					...state.bodyProductSearch,
					options: {
						...state.bodyProductSearch.options,
						index: payload
					}
				}
			}

		case CommercialProfileTypes.SET_THERE_ARE_MORE_PRODUCTS:
			return {
				...state,
				thereAreMoreProducts: payload
			}

		case CommercialProfileTypes.SET_IS_EMPTY_PRODUCTS:
			return {
				...state,
				isEmptyProducts: payload
			}

		case CommercialProfileTypes.SET_BODY_PRODUCT:
			return {
				...state,
				bodyProductSearch: {
					...state.bodyProductSearch,
					options: {
						...state.bodyProductSearch.options,
						brand_id: [payload.brandId],
						limit: payload.limit,
						language_id: payload.languageId,
					},
					filter: {
						reference: [],
						color: [],
						season: [],
						segmentation: [],
						division: [],
						family: [],
						gender: [],
						pvi: [],
						pvpr: [],
						tags: [],
						tiers: [],
					}
				},
				bodyProductSearchTemp: {
					...state.bodyProductSearchTemp,
					options: {
						...state.bodyProductSearchTemp.options,
						brand_id: payload.brandId,
						limit: payload.limit,
						language_id: payload.languageId,
					},
					filter: {
						reference: [],
						color: [],
						season: [],
						segmentation: [],
						division: [],
						family: [],
						gender: [],
						pvi: [],
						pvpr: [],
						tags: [],
						tiers: [],
					}
				},
				querysearchArray: []
			}

		case CommercialProfileTypes.ADD_MORE_TO_CATALOG:
			return {
				...state,
				catalogItems: {
					...state.catalogItems,
					data: state.catalogItems.data.concat(payload.data)
				}
			}

		case CommercialProfileTypes.SET_IS_LOADING_MORE_PRODUCTS:
			return {
				...state,
				isLoadingMoreProducts: payload
			}

		// Filters
		case CommercialProfileTypes.ADD_BODY_FILTERS:
			return {
				...state,
				bodyProductSearchTemp: {
					...state.bodyProductSearchTemp,
					filter: {
						...state.bodyProductSearchTemp.filter,
						[payload.field]: [...state.bodyProductSearchTemp.filter[payload.field], payload.value]
					}
				}
			}

		case CommercialProfileTypes.REMOVE_BODY_FILTERS:
			return {
				...state,
				bodyProductSearchTemp: {
					...state.bodyProductSearchTemp,
					filter: {
						...state.bodyProductSearchTemp.filter,
						[payload.field]: state.bodyProductSearchTemp.filter[payload.field].filter((value: any) => value !== payload.value)
					}
				}
			}

		case CommercialProfileTypes.RESET_BODY_FILTERS:
			return {
				...state,
				bodyProductSearch: {
					...state.bodyProductSearch,
					options: {
						...state.bodyProductSearch.options,
						favorite: "",
						// search: [""],
						index: 0
					},
					filter: {
						reference: [],
						color: [],
						season: [],
						segmentation: [],
						division: [],
						family: [],
						gender: [],
						pvi: [],
						pvpr: [],
						tags: [],
						tiers: [],
					}
				},
				bodyProductSearchTemp: {
					...state.bodyProductSearchTemp,
					options: {
						...state.bodyProductSearchTemp.options,
						favorite: "",
						// search: [""],
						index: 0
					},
					filter: {
						reference: [],
						color: [],
						season: [],
						segmentation: [],
						division: [],
						family: [],
						gender: [],
						pvi: [],
						pvpr: [],
						tags: [],
						tiers: [],
					}
				},
				querysearchArray: []
			}

		case CommercialProfileTypes.SET_PRICE_SLIDERS:
			return {
				...state,
				bodyProductSearchTemp: {
					...state.bodyProductSearchTemp,
					filter: {
						...state.bodyProductSearchTemp.filter,
						[payload.field]: payload.value
					}
				}
			}

		case CommercialProfileTypes.SET_FAVORITES:
			return {
				...state,
				bodyProductSearchTemp: {
					...state.bodyProductSearchTemp,
					options: {
						...state.bodyProductSearchTemp.options,
						favorite: payload,
					}
				}
			}

		case CommercialProfileTypes.RESET_PRICE_SLIDER:
			return {
				...state,
				bodyProductSearch: {
					...state.bodyProductSearch,
					filter: {
						...state.bodyProductSearch.filter,
						[payload.type]: []
					}
				},
				bodyProductSearchTemp: {
					...state.bodyProductSearchTemp,
					filter: {
						...state.bodyProductSearchTemp.filter,
						[payload.type]: []
					}
				},
			}

		// Search engine
		case CommercialProfileTypes.SET_QUERY_SEARCH:
			return {
				...state,
				querySearch: payload
			}

		case CommercialProfileTypes.ADD_TO_QUERY_SEARCH_ARRAY:
			return {
				...state,
				querysearchArray: !state.querysearchArray.includes(payload) ? [...state.querysearchArray, payload] : state.querysearchArray
			}

		case CommercialProfileTypes.REMOVE_FROM_QUERY_SEARCH_ARRAY:
			return {
				...state,
				querysearchArray: state.querysearchArray.filter((item: any) => item !== payload)
			}

		case CommercialProfileTypes.SET_BODY_SEARCH_FILTER:
			return {
				...state,
				querysearchArray: payload.querySearchArray,
			}

		// Sort By
		case CommercialProfileTypes.SET_SORT_BY:
			return {
				...state,
				bodyProductSearch: {
					...state.bodyProductSearch,
					options: {
						...state.bodyProductSearch.options,
						order: [payload],
					}
				}
			}

		case CommercialProfileTypes.SET_SORT_BY_HAVE_IMAGES:
			return {
				...state,
				bodyProductSearch: {
					...state.bodyProductSearch,
					options: {
						...state.bodyProductSearch.options,
						allow_null: payload,
					}
				}
			}

		default:
			return state
	}
}