import { useReducer, useMemo, useCallback, memo } from 'react'
import MainContactsContext from './MainContactsContext'
import initialMainContactsState, { IMainContactItemState } from './initialMainContactsState'
import userReducer from 'contexts/mainContacts/reducer/mainContacts.reducer'
import * as action from "contexts/mainContacts/reducer/mainContacts.actions";
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import useContextUser from 'hooks/contexts/useContextUser';
import { ChildrenProps } from 'types';

function MainContactsProvider(props: ChildrenProps) {
	const [contacts, dispatch] = useReducer(userReducer, initialMainContactsState)
	const { getAccessTokenSilently } = useAuth0()
	const { t: translate } = useTranslation()
	const { company } = useContextUser()

	const getMainContacts = useCallback(async () => {
		const token = await getAccessTokenSilently()
		if (company.id) {
			action.getMainContactsAction(dispatch, company.id, token, translate)
		}
	}, [company.id, getAccessTokenSilently, translate])

	const selectContact = useCallback((contact: IMainContactItemState) => {
		action.selectContactAction(dispatch, contact, translate)
	}, [translate])

	const createContact = useCallback(async (contact: IMainContactItemState, setIsOpenDrawer: (isOpen: boolean) => void) => {
		const token = await getAccessTokenSilently()
		if (!company.id) return
		action.createContactAction(dispatch, company.id, contact, token, setIsOpenDrawer, translate)
	}, [getAccessTokenSilently, company.id, translate])

	const updateContact = useCallback(async (contact: IMainContactItemState, setIsOpenDrawer: (isOpen: boolean) => void) => {
		const token = await getAccessTokenSilently()
		action.updateContactAction(dispatch, contacts, contact, token, setIsOpenDrawer, translate)
	}, [getAccessTokenSilently, contacts, translate])

	const removeContact = useCallback(async (contactId: string) => {
		const token = await getAccessTokenSilently()
		action.removeContactAction(dispatch, contactId, contacts.contacts, token, translate)
	}, [getAccessTokenSilently, contacts, translate])

	const memoProvider = useMemo(
		() => ({
			...contacts,
			getMainContacts,
			selectContact,
			createContact,
			updateContact,
			removeContact
		}), [
		contacts,
		getMainContacts,
		selectContact,
		createContact,
		updateContact,
		removeContact
	]
	);

	return (
		<MainContactsContext.Provider value={memoProvider}>
			{props.children}
		</MainContactsContext.Provider>
	)
}

export default memo(MainContactsProvider)