import * as api from 'api/clients.api';
import * as ClientsTypes from './clients.types'
import { clients } from 'router/paths';
import { messageError, messageSuccess, messageWarning } from 'views/components/UI/message';
import * as status from 'contexts/clients/constants';
import { notificationErr } from 'views/components/UI/notification';
import { clientsError } from 'utils/errorCodes';
import { ClientDataColumns, IAssociationInfoState, ICategoryState, IClientBrandWorksState, IClientState, IClientsInitialState, IContactState, IMatchingState, IPointOfSaleBrandWorksState, IPointOfSaleExchangesState, IPointOfSaleState, IPointsOfSaleCategoryState, TAddressOptionsType, TClientToUpdateRequest, TDataUpgrade, TGetClientPointOfSaleProfileAPI, TPhoneOptionsType, TPointsOfSaleToUpdateRequest, TSegmentationState, TSegmentationsValuesState, TUpgradeClientItems } from 'interfaces/clients.interface';
import { IAssociationsStates } from 'interfaces/user.interface';
import { TCountryItem } from 'interfaces/countries.interface';
import { KeyValuesTypes } from 'views/pages/main/clientPage/businessInfoPage/BusinessInfoPage';

export async function getClientsAction(dispatch: any, tenantId: string, associations: IAssociationsStates, pointsOfSaleCategories: IPointsOfSaleCategoryState[], exchanges: IPointOfSaleExchangesState[], countries: TCountryItem[], token: string, translate: Function) {
	dispatch({ type: ClientsTypes.SET_IS_LOADING_CLIENT, payload: true })
	dispatch({ type: ClientsTypes.SET_CLIENTS, payload: [] })
	dispatch({ type: ClientsTypes.SET_POINTS_OF_SALE, payload: [] })
	try {
		const [clientsAssociationsFetch] = await Promise.all([
			api.getAssociationsAPI(tenantId, token),
		])

		if (clientsAssociationsFetch.response.status === 200) {
			const sortClientsAliases = clientsAssociationsFetch.data.pending_client_aliases.sort((a, b) => a?.client?.name?.localeCompare(b.client.name))
			const sortClientsRequests = clientsAssociationsFetch.data.pending_requests.sort((a, b) => a?.client.name?.localeCompare(b.client.name))
			const sortClientsMatching = clientsAssociationsFetch.data.matched_requests.sort((a, b) => a?.alias.name?.localeCompare(b.alias.name))
			const sortClientsConnections = clientsAssociationsFetch.data.connected_associations.sort((a, b) => a?.client_alias?.name?.localeCompare(b.client_alias.name))
			// const sortClientsDisconnectedConnections = clientsAssociationsFetch.data.diconnected_client_aliases.sort((a: any, b: any) => a?.client?.name?.localeCompare(b.client.name))
			// const sortClientsRejectedConnections = clientsAssociationsFetch.data.rejected_requests.sort((a: any, b: any) => a?.client?.name?.localeCompare(b.client.name))
			let clientsAliases: IClientState[] = []
			let clientsRequests: IClientState[] = []
			let clientsMatchings: IClientState[] = []
			let clientsConnections: IClientState[] = []
			// let clientsDisconnected: IClientState[] = []
			// let clientsRejected: IClientState[] = []
			let allPointsOfSale: IPointOfSaleState[] = []

			for (const client of sortClientsAliases) {
				const getClient = await getClients(client, associations, countries, exchanges, '', pointsOfSaleCategories, token, translate)
				clientsAliases.push(getClient)
				getClient.clientSalePoint?.forEach((pointOfSale: IPointOfSaleState) => {
					pointOfSale.businessName = getClient.businessName
					pointOfSale.tradeName = getClient.tradeName
					pointOfSale.clientStatus = ''
					allPointsOfSale.push(pointOfSale)
				})
			}

			for (const client of sortClientsRequests) {
				const getClient = await getClients(client, associations, countries, exchanges, status.pending, pointsOfSaleCategories, token, translate)
				clientsRequests.push(getClient)
				getClient.clientSalePoint?.forEach((pointOfSale: IPointOfSaleState) => {
					pointOfSale.businessName = getClient.businessName
					pointOfSale.tradeName = getClient.tradeName
					pointOfSale.clientStatus = status.pending
					allPointsOfSale.push(pointOfSale)
				})
			}

			for (const client of sortClientsMatching) {
				const getClient = await getClients(client.association, associations, countries, exchanges, status.matching, pointsOfSaleCategories, token, translate)
				const findMatch = client.alias
				const { id, name, legal_name, cif, address, postal_code, city, province, floor, door, lat, lng, email, web, tel_prefix, tel_sufix, country_id, contact_name, instagram, youtube, linkedin, facebook } = client.alias
				const { client_id, state_id } = client.association
				const ownCLientObj: IMatchingState = {
					key: id,
					clientCode: getClient.clientCode ?? '',
					tradeName: name,
					businessName: legal_name,
					cif: cif,
					address: address,
					postal_code: postal_code,
					city: city,
					country: {
						id: country_id,
						name: countries.find((country) => country.id === country_id)?.name as string
					},
					province: province,
					floor: floor ?? '',
					door: door ?? '',
					lat: lat ?? 0,
					lng: lng ?? 0,
					email: email,
					web: web ?? '',
					tel_prefix: tel_prefix,
					tel_sufix: tel_sufix,
					status: status.matching,
					instagram: instagram ?? '',
					youtube: youtube ?? '',
					linkedin: linkedin ?? '',
					facebook: facebook ?? '',
					// totalPointsSale: 2,
					// segmentations: "",
					// tiers: "",
					contact_name: contact_name ?? '',
					client_id: client_id ?? '',
					state_id,
					country_id,
				}
				findMatch && (getClient.matching = ownCLientObj)
				clientsMatchings.push(getClient)
				for (const pointOfSale of getClient.clientSalePoint) {
					pointOfSale.businessName = getClient.businessName
					pointOfSale.tradeName = getClient.tradeName
					pointOfSale.clientStatus = status.matching
					allPointsOfSale.push(pointOfSale)
				}
			}

			for (const client of sortClientsConnections) {
				let clientBrandWorks: IPointOfSaleBrandWorksState[] = []
				const getClient = await getClients(client, associations, countries, exchanges, status.connected, pointsOfSaleCategories, token, translate)
				clientsConnections.push(getClient)
				for (const pointOfSale of getClient.clientSalePoint as IPointOfSaleState[]) {
					pointOfSale.businessName = getClient.businessName
					pointOfSale.tradeName = getClient.tradeName
					pointOfSale.clientStatus = status.connected
					allPointsOfSale.push(pointOfSale)
					for (const item of pointOfSale.point_of_sale_brand_works as IPointOfSaleBrandWorksState[]) {
						const findBrand = clientBrandWorks.findIndex((brand) => brand.id === item.id)
						if (findBrand === -1) {
							clientBrandWorks.push(item)
						}
					}
					getClient.clientBrandWorks = clientBrandWorks
				}
			}

			// for (const client of sortClientsDisconnectedConnections as IClientState[]) {
			// 	const getClient = await getClients(client, associations, status.disconnected, categories, token)
			// 	clientsDisconnected.push(getClient)
			// 	// getClient.clientSalePoint?.forEach((pointOfSale: IPointOfSaleState) => {
			// 	// 	pointOfSale.businessName = getClient.businessName
			// 	// 	pointOfSale.tradeName = getClient.tradeName
			// 	// 	pointOfSale.clientStatus = status.disconnected
			// 	// 	// allPointsOfSale.push(pointOfSale)
			// 	// })
			// }

			// for (const client of sortClientsRejectedConnections as IClientState[]) {
			// 	const getClient = await getClients(client, associations, status.rejected, categories, token)
			// 	clientsRejected.push(getClient)
			// 	// getClient.clientSalePoint?.forEach((pointOfSale: IPointOfSaleState) => {
			// 	// 	pointOfSale.businessName = getClient.businessName
			// 	// 	pointOfSale.tradeName = getClient.tradeName
			// 	// 	pointOfSale.clientStatus = status.rejected
			// 	// 	// allPointsOfSale.push(pointOfSale)
			// 	// })
			// }

			const sortAllPointsOfSale = allPointsOfSale.sort((a, b) => a.state_name?.localeCompare(b.state_name))
			dispatch({
				type: ClientsTypes.SET_POINTS_OF_SALE,
				payload: sortAllPointsOfSale
			})
			dispatch({
				type: ClientsTypes.SET_ALL_POINTS_OF_SALE,
				payload: sortAllPointsOfSale
			})
			const clients = [...clientsRequests, ...clientsMatchings, ...clientsConnections, ...clientsAliases]
			// const clients = [...clientsRequests, ...clientsMatchings, ...clientsConnections, ...clientsAliases, ...clientsDisconnected, ...clientsRejected]
			dispatch({
				type: ClientsTypes.SET_ALL_CLIENTS,
				payload: clients
			})
			return dispatch({
				type: ClientsTypes.SET_CLIENTS,
				payload: clients
			})
		}
	} catch (err) {
		console.log('error', err);
	} finally {
		dispatch({ type: ClientsTypes.REFRESH_CLIENTS, payload: false })
		return dispatch({ type: ClientsTypes.SET_IS_LOADING_CLIENT, payload: false })
	}
}

export async function getCategoriesAction(dispatch: any, token: string) {
	try {
		const [pointsOfSaleCategoriesFetch] = await Promise.all([
			api.getPointsOfSaleCategoriesAPI(token)
		])
		let categories: ICategoryState[] = []
		if (pointsOfSaleCategoriesFetch.response.status === 200) {
			categories = pointsOfSaleCategoriesFetch.data.map((data) => {
				return { value: data.id, label: data.name, online: data.online }
			})
			dispatch({
				type: ClientsTypes.SET_POINTS_OF_SALE_CATEGORIES,
				payload: categories
			})
		}
	} catch (err) {
		console.log('error', err);
	}
}

export async function selectClientAction(dispatch: any, client: IClientState, navigate: any, urlPath: string, token: string) {
	if (client.associationInfo?.client_alias_id) {
		const [getPointsOfSaleAssociationsFecth, getClientContactsFecth] = await Promise.all([
			api.getPointsOfSaleAssociationsAPI(client.associationInfo?.client_alias_id, token),
			api.getClientContactsAPI(client.associationInfo?.client_id, token)
		])
		if (getClientContactsFecth.response.status === 200) {
			const mapClientContacts = getClientContactsFecth.data.company_client_contacts.map((contact: any) => {
				const { id, name, surname, email, telephone_prefix, telephone_sufix, sector } = contact.contact
				return {
					id,
					name,
					surname,
					email,
					telephone_prefix,
					telephone_sufix,
					sector
				}
			})
			client.contacts = mapClientContacts as IContactState[]
		}
		if (getClientContactsFecth.response.status === 404) {
			client.contacts = []
		}
		if (getPointsOfSaleAssociationsFecth.response.status === 200) {
			const { client_points_of_sale, connected_points_of_sale, matched_points_of_sale } = getPointsOfSaleAssociationsFecth.data
			client.pendingPointsOfSaleAssociations = (client_points_of_sale.length + matched_points_of_sale.length) - connected_points_of_sale.length
		}
	}
	urlPath && navigate && navigate(urlPath)
	return dispatch({
		type: ClientsTypes.SELECT_CLIENT,
		payload: client
	})
}

export async function selectPointOfSaleAction(dispatch: any, pointOfSale: IPointOfSaleState) {
	try {
		return dispatch({
			type: ClientsTypes.SELECT_POINT_OF_SALE,
			payload: pointOfSale
		})
	} catch (err) {
		console.log(err);
	}
}

export async function createClientAction(dispatch: any, client: any, countries: TCountryItem[], setIsOpenDrawer: any, resetGoogleAddress: () => void, navigate: any, token: string, translate: any) {
	try {
		client.startDate = new Date().toISOString()
		const createClientFetch = await api.createClientAliasAPI(client, token)
		if (createClientFetch.response.status === 403) {
			return messageWarning(translate('clients_actions_client-exists'))
		}
		if (createClientFetch.response.status === 200) {
			const { id, code, name, legal_name, cif, address, floor, door, lat, lng, postal_code, city, province, region, email, web, tel_prefix, tel_sufix, contact_name, instagram, youtube, facebook, linkedin, startDate } = createClientFetch.data
			const newClient: IClientState = {
				key: id,
				clientCode: code,
				tradeName: name,
				businessName: legal_name,
				cif: cif,
				address: address,
				floor: floor ?? '',
				door: door ?? '',
				lat: lat ?? 0,
				lng: lng ?? 0,
				postal_code: postal_code,
				city: city,
				country: {
					id: region,
					name: countries.find((country) => country.id === region)?.name as string
				},
				province: province,
				email: email,
				web: web,
				startDate: startDate,
				startSeason: '',
				tel_prefix: tel_prefix,
				tel_sufix: tel_sufix,
				contact_name,
				totalPointsSale: 0,
				segmentations: '',
				tiers: '',
				clientSalePoint: [],
				agents: [],
				status: "",
				pendingPointsOfSaleAssociations: 0,
				instagram,
				youtube,
				facebook,
				linkedin,
				associationInfo: {} as IAssociationInfoState,
				matching: null,
				contacts: [],
				enableModify: false,
				clientBrandWorks: [],
				clientStatus: "",
				dataColumns: [],
				dataColumnsWithChanges: {
					values: [] as TUpgradeClientItems[],
					clientId: ''
				}
			}
			messageSuccess(translate('clients_actions_add-client-success'))
			setIsOpenDrawer(false)
			navigate(clients)
			resetGoogleAddress()
			return dispatch({
				type: ClientsTypes.CREATE_CLIENT,
				payload: newClient
			})
		} else {
			console.log(createClientFetch.response.status);
		}
	} catch (err) {
		console.log(err);
	}
}

export async function updateClientAction(
	dispatch: any,
	clientId: string,
	dataColumns: ClientDataColumns[],
	categories: IPointsOfSaleCategoryState[],
	exchanges: IPointOfSaleExchangesState[],
	client: IClientState & { country_id: string, code: string },
	brandId: string,
	associations: IAssociationsStates,
	countries: TCountryItem[],
	setIsOpenDrawer: any,
	token: string,
	translate: any
) {
	try {
		const updateClientFetch = await api.updateClientAPI(clientId, client, token)
		if (updateClientFetch.response.status === 202 || updateClientFetch.response.status === 200) {
			const convertedClient: IClientState = {
				...client,
				clientCode: client.code,
				country: {
					id: client.country_id,
					name: countries.find((country) => country.id === client.country_id)?.name as string
				}
			}
			let copyOfDataColumns: any[] = JSON.parse(JSON.stringify(dataColumns));
			const convertedClientMap = Object.entries(convertedClient).map(([key, value]) => ({ key, value }))
			for (const item of convertedClientMap) {
				for (const column of copyOfDataColumns) {
					if (column.phoneNumber && (item.key === 'tel_prefix' || item.key === 'tel_sufix')) {
						column.phoneNumber.own = `${convertedClient.tel_prefix} ${convertedClient.tel_sufix}`
					}
					if (column[item.key as keyof typeof column]) {
						if (item.key === 'country') {
							column[item.key as keyof typeof column].own = countries.find((country) => country.id === client.country_id)?.name as string
						} else if (column[item.key as keyof typeof column].keyValue === 'phoneNumber') {
							column[item.key as keyof typeof column].own = `${convertedClient.tel_prefix} ${convertedClient.tel_sufix}`
						} else {
							column[item.key as keyof typeof column].own = item.value
						}
					}
				}
			}
			dispatch({
				type: ClientsTypes.SET_DATA_COLUMNS,
				payload: {
					dataColumns: copyOfDataColumns,
					clientId: clientId
				}
			})
			dispatch({
				type: ClientsTypes.UPDATE_CLIENT,
				payload: convertedClient
			})
			messageSuccess(translate('clients_actions_update-client-success'))
			setIsOpenDrawer(false)
			return
		} else {
			messageSuccess(translate('clients_actions_update-client-error'))
		}
	} catch (err) {
		console.log(err);
	} finally {
		await getClientsAction(dispatch, brandId, associations, categories, exchanges, countries, token, translate)
	}
}

function modifyObject(columns: any, item: TUpgradeClientItems) {
	for (let obj of columns) {
		if (obj[item.keyValue]) {
			obj[item.keyValue].own = item.newString;
		}
	}
}
export async function upgradeClientItemsAction(
	dispatch: any,
	clientId: string,
	state: IClientsInitialState,
	values: TUpgradeClientItems[],
	countries: TCountryItem[],
	token: string,
	translate: any
) {
	dispatch({ type: ClientsTypes.IS_LOADING_UPGRADE_CLIENT, payload: true })
	try {
		interface ClientValuesOptions {
			tradeName?: string
			businessName?: string
			name?: string
			legal_name?: string
			cif?: string
			address?: string
			postal_code?: string
			city?: string
			country_id?: string,
			country?: {
				id: string,
				name: string
			},
			province?: string
			floor?: string
			door?: string
			lat?: number
			lng?: number
			email?: string
			web?: string
			tel_prefix?: string
			tel_sufix?: string
			contact_name?: string
			instagram?: string,
			youtube?: string,
			facebook?: string,
			linkedin?: string,
		}

		let copyOfDataColumns: ClientDataColumns[] = JSON.parse(JSON.stringify(state.clientSelected.dataColumns));
		let clientNewValues: ClientValuesOptions = {}

		for (const item of values) {
			if (item.keyValue === 'address') {
				clientNewValues = {
					...clientNewValues,
					...(item.value as TAddressOptionsType),
				}
				modifyObject(copyOfDataColumns, item);
			} else if (item.keyValue === 'country') {
				clientNewValues = {
					...clientNewValues,
					country_id: countries.find((country) => country.name === item.value)?.id as string,
				}
				modifyObject(copyOfDataColumns, item);

			} else if (item.keyValue === 'phoneNumber') {
				clientNewValues = {
					...clientNewValues,
					...(item.value as TPhoneOptionsType),
				}
				modifyObject(copyOfDataColumns, item);
			} else {
				clientNewValues = {
					...clientNewValues,
					[item.keyValue]: item.value
				}
				modifyObject(copyOfDataColumns, item);
			}

			dispatch({
				type: ClientsTypes.SET_DATA_COLUMNS_WITH_CHANGES,
				payload: {
					keyValue: item.keyValue,
					clientId: clientId
				}
			})
		}

		dispatch({
			type: ClientsTypes.SET_DATA_COLUMNS,
			payload: {
				dataColumns: copyOfDataColumns,
				clientId: clientId
			}
		})

		const updateClientFetch = await api.updateClientAPI(clientId, clientNewValues, token)
		if (updateClientFetch.data[0] === 1) {
			if (clientNewValues.country_id) {
				clientNewValues = {
					...clientNewValues,
					country: {
						id: clientNewValues.country_id,
						name: countries.find((country) => country.id === clientNewValues.country_id)?.name as string
					}
				}
			}
			if (clientNewValues.name) {
				clientNewValues = {
					...clientNewValues,
					tradeName: clientNewValues.name
				}
			}
			if (clientNewValues.legal_name) {
				clientNewValues = {
					...clientNewValues,
					businessName: clientNewValues.legal_name
				}
			}

			dispatch({
				type: ClientsTypes.UPGRADE_CLIENT,
				payload: clientNewValues
			})

			// Update clients
			const findClient = state.clients.findIndex((client) => client.key === clientId)
			if (findClient) {
				state.clients[findClient] = {
					...state.clients[findClient],
					...clientNewValues,
					dataColumns: copyOfDataColumns,
					dataColumnsWithChanges: {
						...state.clients[findClient].dataColumnsWithChanges,
						values: []
					}
				}
				dispatch({
					type: ClientsTypes.SET_CLIENTS,
					payload: state.clients
				})
			}
			messageSuccess(translate('clients_actions_update-client-success'))
			return true
		} else {
			messageSuccess(translate('clients_actions_update-client-error'))
			return false
		}
	} catch (err) {
		console.log(err);
		return false
	} finally {
		dispatch({ type: ClientsTypes.IS_LOADING_UPGRADE_CLIENT, payload: false })
	}
}

export async function createPointOfSaleAction(dispatch: any, clientState: IClientsInitialState, pointOfSale: any, associations: IAssociationsStates, countries: TCountryItem[], setIsOpenDrawer: any, resetGoogleAddress: () => void, form: any, showConnected: any, token: string, translate: any) {
	dispatch({ type: ClientsTypes.REFRESH_CLIENTS, payload: true })
	const { brand_id, tenant_id, contact_name, email, code, province, city, postal_code, address, floor, door, lat, lng, full_price, online, tel_prefix, tel_sufix, start_season, start_date, web, country_id, state_id, division_id, segmentation_id, tier_id, createdAt, updatedAt, exchange_id } = pointOfSale
	const { key: client_alias_id, tradeName, businessName, clientSalePoint } = clientState.clientSelected
	const body = {
		brand_id: brand_id,
		tenant_id: tenant_id,
		point_of_sale: {
			client_alias_id,
			...pointOfSale
		},
		division_id: division_id,
		segmentation_id: segmentation_id,
		tier_id: tier_id
	}
	try {
		const createPointOfSaleFetch = await api.createPointOfSaleAPI(body, token)
		if (createPointOfSaleFetch.response.status === 403) {
			return messageWarning('Ya existe un punto de venta con el mismo nombre')
		}
		if (createPointOfSaleFetch.response.status === 202 || createPointOfSaleFetch.response.status === 200) {
			const { division, segmentations, tiers } = createPointOfSaleFetch.data
			let newDivisions: TSegmentationsValuesState[] = []
			let newSegmentations: TSegmentationsValuesState[] = []
			let newTiers: TSegmentationsValuesState[] = []
			for (const dv of division) {
				const findDivision = clientState.segmentations.division.find((item: TSegmentationState) => item.id === dv.division_id)
				if (findDivision) {
					newDivisions.push({ value: findDivision.id, label: findDivision.name })
				}
			}
			for (const segmentation of segmentations) {
				const findSegmentation = clientState.segmentations.segmentation.find((item: TSegmentationState) => item.id === segmentation.segmentation_id)
				if (findSegmentation) {
					newSegmentations.push({ value: findSegmentation.id, label: findSegmentation.name })
				}
			}
			for (const tier of tiers) {
				const findTier = clientState.segmentations.tier.find((item: TSegmentationState) => item.id === tier.tier_id)
				if (findTier) {
					newTiers.push({ value: findTier.id, label: findTier.name })
				}
			}
			const { id, name: namePS } = createPointOfSaleFetch.data.point_of_sale
			const category = clientState.pointsOfSaleCategories.find((category: any) => category.value === pointOfSale.category_id)
			const newPointOfSaleObj: IPointOfSaleState = {
				index: clientSalePoint.length,
				key: id,
				code,
				name: namePS,
				tradeName: tradeName,
				businessName: businessName,
				client_alias_id,
				point_of_sale_id: '',
				area: pointOfSale.area,
				country: {
					id: country_id,
					name: countries.find((country) => country.id === country_id)?.name as string
				},
				category: {
					value: category?.value ?? "",
					label: category?.label ?? "",
					online: category?.online ?? false
				},
				state_id,
				state_name: 'pending',
				province,
				city,
				postal_code,
				address,
				door,
				floor,
				lat,
				lng,
				tel_prefix,
				tel_sufix,
				start_season,
				start_date,
				web,
				email,
				full_price,
				online,
				createdAt,
				updatedAt,
				connected: false,
				receiving: false,
				data_share: false,
				contact_name: contact_name,
				point_of_sale_brand_works: [],
				clientStatus: '',
				exchange_id: exchange_id,
				division_id: newDivisions,
				segmentation_id: newSegmentations,
				tier_id: newTiers,
				last_sale: null
			}
			dispatch({
				type: ClientsTypes.CREATE_POINT_OF_SALE,
				payload: {
					newPointOfSale: newPointOfSaleObj,
				}
			})
			setIsOpenDrawer(false)
			messageSuccess(translate('clients_actions_add-point-of-sale-success'))
			resetGoogleAddress()
			form.resetFields()
			showConnected(false)
			return
		} else {
			return messageError(translate('clients_actions_add-point-of-sale-error'))
		}
	} catch (err) {
		console.log(err);
	}
}
type TSegmentations = {
	value: string,
	label: string
}
type TPointOfSaleUpdateOptions = {
	online: boolean;
	category_id: string;
	name: string;
	code: string | null;
	exchange_id: string;
	area: number;
	tel_prefix: string;
	tel_sufix: string;
	web: string;
	id: string;
	address: string;
	postal_code: string;
	city: string;
	province: string;
	country_id: string;
	floor: string | null;
	door: string | null;
	lat: number;
	lng: number;
	full_price: boolean
	division_id: TSegmentations[],
	segmentation_id: TSegmentations[],
	tier_id: TSegmentations[],
};
export async function updatePointOfSaleAction(dispatch: any, tenantId: string, clientState: IClientsInitialState, pointOfSale: TPointOfSaleUpdateOptions, countries: TCountryItem[], setIsOpenDrawer: any, token: string, translate: any) {
	dispatch({ type: ClientsTypes.REFRESH_CLIENTS, payload: true })
	const { id, name, country_id, province, city, postal_code, address, floor, door, lat, lng, area, tel_prefix, tel_sufix, web, code, online, full_price, category_id } = pointOfSale
	const { clientSalePoint } = clientState.clientSelected
	const divisions = !pointOfSale.division_id ? null : pointOfSale.division_id[0]?.value ? pointOfSale.division_id.map((item) => item.value) : pointOfSale.division_id
	const segmentations = !pointOfSale.segmentation_id ? null : pointOfSale.segmentation_id[0]?.value ? pointOfSale.segmentation_id.map((item: any) => item.value) : pointOfSale.segmentation_id
	const tiers = !pointOfSale.tier_id ? null : pointOfSale.tier_id[0]?.value ? pointOfSale.tier_id.map((item: any) => item.value) : pointOfSale.tier_id
	const body: any = {
		tenant_id: tenantId,
		point_of_sale_id: id,
		update: pointOfSale,
		segmentation_id: segmentations,
		division_id: divisions,
		tier_id: tiers
	}
	delete body.update.division_id
	delete body.update.segmentation_id
	delete body.update.tier_id
	let newDivisions: TSegmentationsValuesState[] = []
	let newSegmetations: TSegmentationsValuesState[] = []
	let newTiers: TSegmentationsValuesState[] = []
		; (divisions as string[]).forEach((division) => {
			const findDivision = clientState.segmentations.division.find((item) => item.id === division)
			if (findDivision) {
				newDivisions.push({ value: findDivision.id, label: findDivision.name })
			}
		})
		; (segmentations as string[]).forEach((segmentation) => {
			const findSegmentation = clientState.segmentations.segmentation.find((item) => item.id === segmentation)
			if (findSegmentation) {
				newSegmetations.push({ value: findSegmentation.id, label: findSegmentation.name })
			}
		})
		; (tiers as string[]).forEach((tier) => {
			const findTier = clientState.segmentations.tier.find((item) => item.id === tier)
			if (findTier) {
				newTiers.push({ value: findTier.id, label: findTier.name })
			}
		})
	try {
		const updatePointOfSaleFetch = await api.updatePointOfSaleAPI(body, token)
		if (updatePointOfSaleFetch.response.status === 200) {
			const findPointOfSale = clientSalePoint.find((pointOfSale: IPointOfSaleState) => pointOfSale.key === id)
			const category = clientState.pointsOfSaleCategories.find((category: ICategoryState) => category.value === category_id)
			if (findPointOfSale) {
				findPointOfSale.name = name
				findPointOfSale.country = {
					id: country_id,
					name: countries.find((country) => country.id === country_id)?.name as string
				}
				findPointOfSale.province = province
				findPointOfSale.city = city
				findPointOfSale.postal_code = postal_code
				findPointOfSale.address = address
				findPointOfSale.floor = floor as string
				findPointOfSale.door = door as string
				findPointOfSale.lat = lat
				findPointOfSale.lng = lng
				findPointOfSale.area = area as number
				findPointOfSale.tel_prefix = tel_prefix
				findPointOfSale.tel_sufix = tel_sufix
				findPointOfSale.web = web
				findPointOfSale.code = code as string
				findPointOfSale.online = online
				findPointOfSale.full_price = full_price
				findPointOfSale.category = {
					value: category?.value ?? "",
					label: category?.label ?? "",
					online: category?.online ?? false
				}
				findPointOfSale.division_id = newDivisions
				findPointOfSale.segmentation_id = newSegmetations
				findPointOfSale.tier_id = newTiers
			}
			dispatch({
				type: ClientsTypes.UPDATE_POINT_OF_SALE,
				payload: clientState.clientSelected
			})
			messageSuccess(translate('clients_actions_update-point-of-sale-success'))
			return setIsOpenDrawer(false)
		} else {
			messageError(translate('clients_actions_update-point-of-sale-error'))
		}
	} catch (err) {
		console.log(err);
	}
}

interface FieldToUpgrade {
	pointOfSaleId: string;
}
export async function upgradePointsOfSaleAction(
	dispatch: any,
	tenantId: string,
	countries: TCountryItem[],
	clientState: IClientsInitialState,
	fieldsToUpgrade: TDataUpgrade[],
	clientId: string,
	token: string,
	translate: any
) {
	const separatedByPointOfSaleId = fieldsToUpgrade.reduce((acc: Record<string, FieldToUpgrade[]>, item) => {
		if (!acc[item.pointOfSaleId]) {
			acc[item.pointOfSaleId] = [];
		}
		acc[item.pointOfSaleId].push(item);
		return acc;
	}, {});

	const pointsOfSaleToUpgrade = Object.entries(separatedByPointOfSaleId).map(([pointOfSaleId, fields]) => ({
		pointOfSaleId,
		fields: fields as TDataUpgrade[]
	}))

	// Map each point of sale
	let updatedPointsOfSale = 0
	for (const item of pointsOfSaleToUpgrade) {
		type TBodyFFields = {
			online?: boolean
			category_id?: string
			name?: string
			exchange_id?: string
			area?: number
			address?: string
			floor?: string
			door?: string
			city?: string
			province?: string
			postal_code?: string
			country_id?: string
			lat?: number
			lng?: number
			tel_prefix?: string
			tel_sufix?: string
			web?: string
		}
		let fieldsToUpdate: TBodyFFields = {}
		item.fields.forEach((field) => {
			fieldsToUpdate = {
				...fieldsToUpdate,
				...field.values
			}
		})
		const body: any = {
			tenant_id: tenantId,
			point_of_sale_id: item.pointOfSaleId,
			update: fieldsToUpdate,
			segmentation_id: clientState.clientSelected.clientSalePoint.find((pointOfSale: IPointOfSaleState) => pointOfSale.key === item.pointOfSaleId)?.segmentation_id.map((item) => item.value),
			division_id: clientState.clientSelected.clientSalePoint.find((pointOfSale: IPointOfSaleState) => pointOfSale.key === item.pointOfSaleId)?.division_id.map((item) => item.value),
			tier_id: clientState.clientSelected.clientSalePoint.find((pointOfSale: IPointOfSaleState) => pointOfSale.key === item.pointOfSaleId)?.tier_id.map((item) => item.value),
		}

		try {
			const updatePointOfSaleFetch = await api.updatePointOfSaleAPI(body, token)
			if (updatePointOfSaleFetch.response.status === 200) {
				const findCLientSalePoint = clientState.clientSelected.clientSalePoint.find((pointOfSale: IPointOfSaleState) => pointOfSale.key === item.pointOfSaleId)
				let newPS: IPointOfSaleState = {
					...findCLientSalePoint as IPointOfSaleState,
					...fieldsToUpdate
				}
				if (fieldsToUpdate.category_id) {
					const category = clientState.pointsOfSaleCategories.find((category: ICategoryState) => category.value === fieldsToUpdate.category_id)
					newPS.category = {
						value: category?.value ?? "",
						label: category?.label ?? "",
						online: category?.online ?? false
					}
				}
				if (fieldsToUpdate.country_id) {
					newPS.country = {
						id: fieldsToUpdate.country_id,
						name: countries.find((country) => country.id === fieldsToUpdate.country_id)?.name as string
					}
				}
				item.fields.forEach((field) => {
					newPS.fieldsToUpdate = (newPS.fieldsToUpdate as TDataUpgrade[]).filter((item) => item.field !== field.field)
				})
				const findClientSelectedIndex = clientState.clientSelected.clientSalePoint.findIndex((pointOfSale: IPointOfSaleState) => pointOfSale.key === item.pointOfSaleId)
				clientState.clientSelected.clientSalePoint[findClientSelectedIndex] = newPS
				dispatch({
					type: ClientsTypes.SELECT_CLIENT,
					payload: clientState.clientSelected
				})

				const findClientIndex = clientState.clients.findIndex((client: IClientState) => client.key === clientId)
				const findPointOfSaleIndex = clientState.clients[findClientIndex].clientSalePoint.findIndex((pointOfSale: IPointOfSaleState) => pointOfSale.key === item.pointOfSaleId)
				clientState.clients[findClientIndex].clientSalePoint[findPointOfSaleIndex] = newPS
				dispatch({
					type: ClientsTypes.SET_CLIENTS,
					payload: clientState.clients
				})

				const findOnAllPointsOfSale = clientState.pointsOfSale.findIndex((pointOfSale: IPointOfSaleState) => pointOfSale.key === item.pointOfSaleId)
				clientState.pointsOfSale[findOnAllPointsOfSale] = newPS
				dispatch({
					type: ClientsTypes.SET_POINTS_OF_SALE,
					payload: clientState.pointsOfSale
				})
				let haveChanges = false
				const fieldsMap = Object.entries(fieldsToUpdate).map(([key, value]) => ({ key, value }))
				for (const field of fieldsMap) {
					if (field.key !== 'lat' && field.key !== 'lng') {
						haveChanges = true
					}
				}
				haveChanges && updatedPointsOfSale++
			} else {
				messageError(translate('clients_actions_update-point-of-sale-error'))
			}
		} catch (error) {
			console.log(error);
		}
	}
	if (updatedPointsOfSale === pointsOfSaleToUpgrade.length) {
		return messageSuccess(`${updatedPointsOfSale} ${updatedPointsOfSale === 1
			? translate('clients_actions_upgrade-point-of-sale-success')
			: translate('clients_actions_upgrade-points-of-sale-success')}`)
	}
}

export async function shareDataPointOfSaleAction(dispatch: any, clientState: IClientsInitialState, pointOfSaleId: string, haveToShare: boolean, token: string, translate: any) {
	dispatch({ type: ClientsTypes.REFRESH_CLIENTS, payload: true })
	const body = {
		filter: { id: pointOfSaleId },
		update: {
			data_share: haveToShare,
		}
	}
	try {
		const updatePointOfSaleFetch = await api.updatePointOfSaleAPI(body, token)
		if (updatePointOfSaleFetch.response.status === 200) {
			const findPointOfSale = clientState.clientSelected.clientSalePoint.find((pointOfSale: any) => pointOfSale.key === pointOfSaleId)
			if (findPointOfSale) {
				findPointOfSale.data_share = haveToShare
			}
			if (haveToShare) {
				messageSuccess(translate('clients_actions_share-data-point-of-sale-success'))
			} else {
				messageSuccess(translate('clients_actions_dont-share-data-point-of-sale-success'))
			}
			return dispatch({
				type: ClientsTypes.UPDATE_POINT_OF_SALE,
				payload: clientState.clientSelected
			})
		} else {
			messageError(translate('clients_actions_update-point-of-sale-error'))
		}
	} catch (err) {
		console.log('client.actions 627', err);
	}
}

export async function searchClientsAction(dispatch: any, clientsState: IClientsInitialState, query: string) {
	const cs = [...clientsState.allClients]
	const findByTradeName = cs.filter((client: IClientState) => client.tradeName?.toLowerCase().includes(query.toLowerCase()))
	const findByBusinessName = cs.filter((client: IClientState) => client.businessName?.toLowerCase().includes(query.toLowerCase()))
	const findByClientCode = cs.filter((client: IClientState) => client.clientCode?.toLowerCase().includes(query.toLowerCase()))
	const findByCity = cs.filter((client: IClientState) => client.city?.toLowerCase().includes(query.toLowerCase()))
	const concatResults = [...findByTradeName, ...findByBusinessName, ...findByClientCode, ...findByCity]
	const result = concatResults.filter((item, index) => {
		return concatResults.indexOf(item) === index;
	})
	dispatch({
		type: ClientsTypes.SET_QUERY_CLIENTS_SEARCH,
		payload: query
	})
	return dispatch({
		type: ClientsTypes.SET_CLIENTS,
		payload: query.length === 0 ? clientsState.allClients : result
	})
}

export async function searchPointsOfSaleAction(dispatch: any, clientsState: IClientsInitialState, query: string) {
	const ps: IPointOfSaleState[] = JSON.parse(JSON.stringify(clientsState.allPointsOfSale));
	const findByTradeName = ps.filter((pointOfSale) => pointOfSale.tradeName?.toLowerCase().includes(query.toLowerCase()))
	const findByBusinessName = ps.filter((pointOfSale) => pointOfSale.businessName?.toLowerCase().includes(query.toLowerCase()))
	const findByPointOfSaleName = ps.filter((pointOfSale) => pointOfSale.name?.toLowerCase().includes(query.toLowerCase()))
	const findByClientCode = ps.filter((pointOfSale) => pointOfSale.code?.toLowerCase().includes(query.toLowerCase()))
	const findByCity = ps.filter((pointOfSale) => pointOfSale.city?.toLowerCase().includes(query.toLowerCase()))
	// const findBySegmentation = ps.filter((client: any) => {
	// 	let filter = false;
	// 	client.segmentations.map((segmentation: any) => {
	// 		if (segmentation.label.toLowerCase().includes(query.toLowerCase())) {
	// 			filter = segmentation.label.toLowerCase().includes(query.toLowerCase())
	// 			return filter
	// 		}
	// 	})
	// 	return filter
	// })
	const concatResults = [...findByTradeName, ...findByBusinessName, ...findByPointOfSaleName, ...findByClientCode, ...findByCity]
	const result = concatResults.filter((item, index) => {
		return concatResults.indexOf(item) === index;
	})
	dispatch({
		type: ClientsTypes.SET_QUERY_POINTS_OF_SALE_SEARCH,
		payload: query
	})
	return dispatch({
		type: ClientsTypes.SET_POINTS_OF_SALE,
		payload: query.length === 0 ? clientsState.allPointsOfSale : result
	})
}

export async function acceptAssociationAction(dispatch: any, clients: IClientState[], pointsOfSaleCategories: IPointsOfSaleCategoryState[], exchanges: IPointOfSaleExchangesState[], tenantId: string, data: TClientToUpdateRequest, statusType: string, associations: IAssociationsStates, countries: TCountryItem[], setIsOpenDrawer: any, setCurrentStep: any, token: string, translate: any) {
	// Not matching
	dispatch({ type: ClientsTypes.SET_IS_LOADING_CLIENT, payload: true })
	try {
		if (statusType !== status.matching) {
			const body = [{ request_id: data.requestId }]
			const associateFetch = await api.associateClientAPI(body, tenantId, token)
			const findClientIndex = clients.findIndex((client: IClientState) => client.key === data.id)
			if (associateFetch.response.status === 200) {
				clients[findClientIndex].associationInfo.client_alias_id = associateFetch.data[0].alias_id
				dispatch({
					type: ClientsTypes.SET_CLIENTS,
					payload: clients
				})
				setCurrentStep(1)
				// setIsOpenDrawer(false)
				await getClientsAction(dispatch, tenantId, associations, pointsOfSaleCategories, exchanges, countries, token, translate)
				return
				// messageSuccess(translate('clients_actions_association-success'))
			} else {
				setIsOpenDrawer(false)
				setCurrentStep(0)
				notificationErr(clientsError.accept_association_001, translate)
				return messageError(translate('clients_actions_association-error'))
			}
		} else {
			// Matching
			if (data.dataToUpdate.includes('address')) {
				data.dataToUpdate.push('floor')
				data.dataToUpdate.push('door')
				data.dataToUpdate.push('lat')
				data.dataToUpdate.push('lng')
			}
			const body = [{
				request_id: data.requestId,
				alias_id: data.aliasId,
				code: data.clientCode,
				fields: data.dataToUpdate?.length > 0 ? data.dataToUpdate : []
			}]
			const associateFetch = await api.associateClientAPI(body, tenantId, token)
			const findClientIndex = clients.findIndex((client) => client.key === data.id)
			if (associateFetch.response.status === 200) {
				clients[findClientIndex].associationInfo.client_alias_id = associateFetch.data[0].alias_id
				clients[findClientIndex].pendingPointsOfSaleAssociations = clients[findClientIndex].clientSalePoint.filter((pointOfSale) => pointOfSale.state_name === status.disconnected).length
				dispatch({
					type: ClientsTypes.SET_CLIENTS,
					payload: clients
				})
				await getClientsAction(dispatch, tenantId, associations, pointsOfSaleCategories, exchanges, countries, token, translate)
				// setIsOpenDrawer(false)
				setCurrentStep(1)
				return messageSuccess(translate('clients_actions_association-success'))
			} else {
				setIsOpenDrawer(false)
				setCurrentStep(0)
				return messageError(translate('clients_actions_association-error'))
			}
		}
	} catch (error) {
		console.log(error);
		return notificationErr(clientsError.accept_association_002, translate)
	} finally {
		dispatch({ type: ClientsTypes.SET_IS_LOADING_CLIENT, payload: false })
	}
}

export async function rejectAssociationAction(dispatch: any, data: IClientState, pointsOfSaleCategories: IPointsOfSaleCategoryState[], exchanges: IPointOfSaleExchangesState[], tenantId: string, associationStates: IAssociationsStates, countries: TCountryItem[], token: string, translate: any) {
	try {
		const removeFetch = await api.detachAssociationAPI(data.associationInfo.id, token)
		if (removeFetch.response.status === 200) {
			// const newClients = [...clients]
			// const findBrand = newClients.findIndex((item: any) => item.associationInfo.id === data.associationInfo.id)
			// newClients[findBrand].status = newClients[findBrand].associationInfo.state_name === status.pending ? status.rejected : status.disconnected
			// newClients[findBrand].totalPointsSale = newClients[findBrand].associationInfo.state_name === status.pending ? 0 : newClients[findBrand].totalPointsSale
			// newClients[findBrand].clientSalePoint = newClients[findBrand].associationInfo.state_name === status.pending ? [] : newClients[findBrand].clientSalePoint
			// dispatch({
			// 	type: ClientsTypes.SET_CLIENTS,
			// 	payload: newClients
			// })
			await getClientsAction(dispatch, tenantId, associationStates, pointsOfSaleCategories, exchanges, countries, token, translate)
			return messageSuccess(translate('clients_actions_reject-association-success'))
		} else {
			messageError(translate('clients_actions_reject-association-error'))
			return notificationErr(clientsError.reject_association_001, translate)
		}
	} catch (err) {
		messageError(translate('clients_actions_reject-association-error'))
		return notificationErr(clientsError.reject_association_002, translate)
	}
}

export async function rejectPointOfSaleAction(dispatch: any, data: IPointOfSaleState, clientSelected: IClientState, token: string, translate: any) {
	try {
		const removeFetch = await api.detachPointOfSaleAPI(data.key, data.client_alias_id, token)
		if (removeFetch.response.status === 200) {
			const findPointOfSale = clientSelected.clientSalePoint.findIndex((item: IPointOfSaleState) => item.key === data.key)
			clientSelected.clientSalePoint[findPointOfSale].state_name = status.disconnected
			clientSelected.pendingPointsOfSaleAssociations = clientSelected.pendingPointsOfSaleAssociations + 1
			dispatch({
				type: ClientsTypes.SELECT_CLIENT,
				payload: clientSelected
			})
			return messageSuccess(translate('clients_actions_reject-association-success'))
		} else {
			messageError(translate('clients_actions_reject-association-error'))
			return notificationErr(clientsError.reject_ps_association_001, translate)
		}
	} catch (err) {
		messageError(translate('clients_actions_reject-association-error'))
		return notificationErr(clientsError.reject_ps_association_002, translate)
	}
}

export async function associatePointsOfSaleAction(dispatch: any, state: IClientsInitialState, tenantId: string, data: TPointsOfSaleToUpdateRequest[], associations: IAssociationsStates, countries: TCountryItem[], setPointsOfSaleToUpdate: any, setIsOpenDrawer: any, setCurrentStep: any, token: string, translate: any) {
	data.forEach((el, index) => {
		if (!el.alias_point_of_sale_id) {
			delete data[index].alias_point_of_sale_id
			delete data[index].associate
			delete data[index].update
		}
	});
	if (data.length === 0) {
		setCurrentStep && setCurrentStep(0)
		return setIsOpenDrawer(false)
	}
	try {
		const associatePointOfSaleFetch = await api.associatePointsOfSaleAPI(data, state.clientSelected.associationInfo?.client_alias_id, token)
		if (associatePointOfSaleFetch.response.status === 200) {
			messageSuccess(translate('clients_actions_accept-ps-association-success'))
			setPointsOfSaleToUpdate([])
			setIsOpenDrawer(false)
			setCurrentStep && setCurrentStep(0)
			state.clientSelected.pendingPointsOfSaleAssociations = state.clientSelected.pendingPointsOfSaleAssociations - data.length
			for (const association of data) {
				const findPointOfSale = state.clientSelected.clientSalePoint.findIndex((item: IPointOfSaleState) => item.key === association.alias_point_of_sale_id)
				if (findPointOfSale === -1) return
				// state.clientSelected.clientSalePoint[findPointOfSale].status = status.connected
				state.clientSelected.clientSalePoint[findPointOfSale].state_name = status.connected
			}
			return dispatch({
				type: ClientsTypes.SELECT_CLIENT,
				payload: state.clientSelected
			})
		}
	} catch (err) {
		messageError(translate('clients_actions_accept-ps-association-error'))
		return notificationErr(clientsError.accept_ps_association_001, translate)
	} finally {
		const getClient = await getClients(state.clientSelected, associations, countries, state.exchanges, status.connected, state.pointsOfSaleCategories, token, translate)
		state.clientSelected.clientSalePoint = getClient.clientSalePoint
		state.clientSelected.totalPointsSale = getClient.clientSalePoint.length
		dispatch({
			type: ClientsTypes.SELECT_CLIENT,
			payload: state.clientSelected
		})
		await getClientsAction(dispatch, tenantId, associations, state.pointsOfSaleCategories, state.exchanges, countries, token, translate)
	}
}

export async function getExchangesAction(dispatch: any, translate: any, token: string) {
	try {
		const exchangesFetch = await api.getExchangesAPI(token)
		if (exchangesFetch.response.status === 200) {
			const addFieldsAndSort: IPointOfSaleExchangesState[] = exchangesFetch.data.map((item: IPointOfSaleExchangesState) => {
				return {
					...item,
					value: item.id,
					label: `${item.currency} - ${item.code}`
				}
			}).sort((a: IPointOfSaleExchangesState, b: IPointOfSaleExchangesState) => a.currency?.localeCompare(b.currency))
			return dispatch({
				type: ClientsTypes.SET_EXCHANGES,
				payload: addFieldsAndSort
			})
		} else {
			console.log('error', 'Error al obtener los tipos de monedas.');
			// messageError('Error al obtener los tipos de monedas.')
			// return notificationErr(clientsError.get_exchanges_002, translate)
		}
	} catch (err) {
		console.log('error', err);
		// messageError('Error al obtener los tipos de monedas.')
		// return notificationErr(clientsError.get_exchanges_001, translate)
	}
}


export async function getSegmentationsAction(dispatch: any, brandId: string, translate: any, token: string) {
	try {
		const segmentationsFetch = await api.getBrandSegmentationsAPI(brandId, token)
		if (segmentationsFetch.response.status === 200) {
			return dispatch({
				type: ClientsTypes.SET_SEGMENTATIONS,
				payload: segmentationsFetch.data
			})
		} else {
			messageError('Error al obtener las segmentaciones.')
			return notificationErr(clientsError.get_segmentations_002, translate)
		}
	} catch (err) {
		messageError('Error al obtener las segmentaciones.')
		return notificationErr(clientsError.get_segmentations_001, translate)
	}
}

//////////////////////////////////////////////////////
//////////////////////////////////////////////////////

type TUpgradeFields =
	'online'
	| 'category_id'
	| 'name'
	| 'exchange_id'
	| 'area'
	| 'address'
	| 'floor'
	| 'door'
	| 'city'
	| 'province'
	| 'postal_code'
	| 'country_id'
	| 'lat'
	| 'lng'
	| 'tel_prefix'
	| 'tel_sufix'
	| 'web'

async function getClients(
	client: any,
	associations: IAssociationsStates,
	countries: TCountryItem[],
	exchanges: IPointOfSaleExchangesState[],
	statusType: 'pending' | 'connected' | 'disconnected' | 'matching' | 'rejected' | '',
	categories: IPointsOfSaleCategoryState[],
	token: string,
	translate: Function
) {
	const { id: clientId, brand_id, brand_ok, client_ok, client_alias_id, client_id, state_id, createdAt, updatedAt } = client
	const { id, code, name, legal_name, cif, address, postal_code, city, province, country_id: clientCountryId, floor, door, lat, lng, contact_name, email, web, tel_prefix, tel_sufix, instagram, youtube, facebook, linkedin } = client.client_alias ?? client.client ?? client
	let pointsOfSale: IPointOfSaleState[] = []
	let tiers: any[] = []
	let segmentations: any[] = []
	if (statusType === status.connected || statusType === status.disconnected || statusType === '') {
		const getBrandPointsOfSaleFecth = await api.getBrandPointsOfSaleAPI(id ?? client.key, token)
		if (getBrandPointsOfSaleFecth.response.status === 200) {
			const sortPointsOfSale = getBrandPointsOfSaleFecth?.data?.client_alias_points_of_sale?.sort((a, b) => a.name.localeCompare(b.name))
			if (sortPointsOfSale) {
				for (const [index, pointOfSale] of sortPointsOfSale.entries()) {
					const category: ICategoryState = categories.find((category) => category.value === pointOfSale.category_id) as ICategoryState
					const getClientPointOfSaleProfile = pointOfSale.point_of_sale_id ? await api.getClientPointOfSaleProfileAPI(pointOfSale.point_of_sale_id, token) : null
					const pointOfSaleObj: IPointOfSaleState = {
						index: index,
						key: pointOfSale.id,
						code: pointOfSale.code as string,
						name: pointOfSale.name,
						businessName: "",
						tradeName: "",
						client_alias_id: pointOfSale.client_alias_id,
						point_of_sale_id: "",
						area: pointOfSale.area ?? 0 as number,
						country: {
							id: pointOfSale.country_id,
							name: countries.find((country) => country.id === pointOfSale.country_id)?.name as string
						},
						category: {
							value: category?.value,
							label: category?.label,
							online: category?.online
						},
						state_id: pointOfSale.state_id,
						state_name: stateName(pointOfSale.state_id, associations) as string,
						province: pointOfSale.province,
						city: pointOfSale.city,
						postal_code: pointOfSale.postal_code,
						address: pointOfSale.address,
						door: pointOfSale.door as string,
						floor: pointOfSale.floor as string,
						lat: pointOfSale.lat,
						lng: pointOfSale.lng,
						tel_prefix: pointOfSale.tel_prefix,
						tel_sufix: pointOfSale.tel_sufix,
						start_season: pointOfSale.start_season as string,
						start_date: pointOfSale.start_date as string,
						web: pointOfSale.web,
						email: pointOfSale.email as string,
						full_price: pointOfSale.full_price,
						online: pointOfSale.online,
						point_of_sale_brand_works: getClientPointOfSaleProfile?.data[0]?.point_of_sale_brand_works.map(ps => ({
							id: ps.brand.id,
							name: ps.brand.name,
							logo: ps.brand.logo
						})) ?? [],
						connected: pointOfSale.connected ?? false,
						receiving: pointOfSale.receiving ?? false,
						data_share: getClientPointOfSaleProfile?.data[0]?.point_of_sale_datashares[0]?.data_share ?? false,
						contact_name: getClientPointOfSaleProfile?.data[0]?.contact_name ?? '',
						clientStatus: "",
						exchange_id: !getClientPointOfSaleProfile ? pointOfSale.exchange_id : getClientPointOfSaleProfile?.data[0].exchange_id,
						division_id: pointOfSale.client_alias_point_of_sale_divisions.map((item: any) => ({ value: item.id, label: item.division.name })),
						segmentation_id: pointOfSale.client_alias_point_of_sale_segmentations.map((item: any) => ({ value: item.id, label: item.segmentation.name })),
						tier_id: pointOfSale.client_alias_point_of_sale_tiers.length > 0 ? pointOfSale.client_alias_point_of_sale_tiers.map((item: any) => ({ value: item.id, label: item.tier?.name })) : [],
						createdAt: pointOfSale.createdAt,
						updatedAt: pointOfSale.updatedAt,
						last_sale: pointOfSale.last_sale,
					}
					if (getClientPointOfSaleProfile?.response.status === 200 && getClientPointOfSaleProfile?.data[0]?.point_of_sale_brand_works.length > 0) {
						pointOfSaleObj.point_of_sale_brand_works = getClientPointOfSaleProfile?.data[0]?.point_of_sale_brand_works.map((item: any) => {
							return {
								id: item.brand.id,
								name: item.brand.name,
								logo: item.brand.logo
							}
						})
					}
					// Check if is available points of sale data upgrade
					let fieldsToUpdate: TDataUpgrade[] = []
					const dataCompared = [
						{
							field: 'online',
							oldValue: pointOfSale.online === true ? 'Online' : 'Tienda Física',
							newValue: (getClientPointOfSaleProfile as TGetClientPointOfSaleProfileAPI)?.data[0]?.online === true ? 'Online' : 'Tienda Física',
							values: { online: (getClientPointOfSaleProfile as TGetClientPointOfSaleProfileAPI)?.data[0]?.online }
						},
						{ field: 'name', oldValue: pointOfSale.name, newValue: (getClientPointOfSaleProfile as TGetClientPointOfSaleProfileAPI)?.data[0]?.name, values: { name: (getClientPointOfSaleProfile as TGetClientPointOfSaleProfileAPI)?.data[0]?.name } },
						{ field: 'area', oldValue: pointOfSale.area, newValue: (getClientPointOfSaleProfile as TGetClientPointOfSaleProfileAPI)?.data[0]?.area, values: { area: (getClientPointOfSaleProfile as TGetClientPointOfSaleProfileAPI)?.data[0]?.area } },
						{ field: 'floor', oldValue: pointOfSale.floor, newValue: (getClientPointOfSaleProfile as TGetClientPointOfSaleProfileAPI)?.data[0]?.floor, values: { floor: (getClientPointOfSaleProfile as TGetClientPointOfSaleProfileAPI)?.data[0]?.floor } },
						{ field: 'door', oldValue: pointOfSale.door, newValue: (getClientPointOfSaleProfile as TGetClientPointOfSaleProfileAPI)?.data[0]?.door, values: { door: (getClientPointOfSaleProfile as TGetClientPointOfSaleProfileAPI)?.data[0]?.door } },
						{ field: 'city', oldValue: pointOfSale.city, newValue: (getClientPointOfSaleProfile as TGetClientPointOfSaleProfileAPI)?.data[0]?.city, values: { city: (getClientPointOfSaleProfile as TGetClientPointOfSaleProfileAPI)?.data[0]?.city } },
						{ field: 'province', oldValue: pointOfSale.province, newValue: (getClientPointOfSaleProfile as TGetClientPointOfSaleProfileAPI)?.data[0]?.province, values: { province: (getClientPointOfSaleProfile as TGetClientPointOfSaleProfileAPI)?.data[0]?.province } },
						{ field: 'postal_code', oldValue: pointOfSale.postal_code, newValue: (getClientPointOfSaleProfile as TGetClientPointOfSaleProfileAPI)?.data[0]?.postal_code, values: { postal_code: (getClientPointOfSaleProfile as TGetClientPointOfSaleProfileAPI)?.data[0]?.postal_code } },
						{ field: 'lat', oldValue: pointOfSale.lat, newValue: (getClientPointOfSaleProfile as TGetClientPointOfSaleProfileAPI)?.data[0]?.lat, values: { lat: (getClientPointOfSaleProfile as TGetClientPointOfSaleProfileAPI)?.data[0]?.lat } },
						{ field: 'lng', oldValue: pointOfSale.lng, newValue: (getClientPointOfSaleProfile as TGetClientPointOfSaleProfileAPI)?.data[0]?.lng, values: { lng: (getClientPointOfSaleProfile as TGetClientPointOfSaleProfileAPI)?.data[0]?.lng } },
						{ field: 'tel_prefix', oldValue: pointOfSale.tel_prefix, newValue: (getClientPointOfSaleProfile as TGetClientPointOfSaleProfileAPI)?.data[0]?.tel_prefix, values: { tel_prefix: (getClientPointOfSaleProfile as TGetClientPointOfSaleProfileAPI)?.data[0]?.tel_prefix } },
						{ field: 'tel_sufix', oldValue: pointOfSale.tel_sufix, newValue: (getClientPointOfSaleProfile as TGetClientPointOfSaleProfileAPI)?.data[0]?.tel_sufix, values: { tel_sufix: (getClientPointOfSaleProfile as TGetClientPointOfSaleProfileAPI)?.data[0]?.tel_sufix } },
						{ field: 'web', oldValue: pointOfSale.web, newValue: (getClientPointOfSaleProfile as TGetClientPointOfSaleProfileAPI)?.data[0]?.web, values: { web: (getClientPointOfSaleProfile as TGetClientPointOfSaleProfileAPI)?.data[0]?.web } },
						{
							field: 'category_id',
							oldValue: categories.find((category) => category.value === pointOfSale.category_id)?.label ?? null,
							newValue: categories.find((category) => category.value === (getClientPointOfSaleProfile as TGetClientPointOfSaleProfileAPI)?.data[0]?.category_id)?.label ?? null,
							values: {
								category_id: categories.find((category) => category.value === (getClientPointOfSaleProfile as TGetClientPointOfSaleProfileAPI)?.data[0]?.category_id)?.value ?? null
							}
						},
						{
							field: 'exchange_id',
							oldValue: exchanges.find(ex => ex.id === pointOfSale.exchange_id)?.label ?? null,
							newValue: exchanges.find(ex => ex.id === (getClientPointOfSaleProfile as TGetClientPointOfSaleProfileAPI)?.data[0]?.exchange_id)?.label ?? null,
							values: {
								exchange_id: exchanges.find(ex => ex.id === (getClientPointOfSaleProfile as TGetClientPointOfSaleProfileAPI)?.data[0]?.exchange_id)?.id ?? null
							}
						},
						{
							field: 'address',
							oldValue: pointOfSale.address,
							newValue: (getClientPointOfSaleProfile as TGetClientPointOfSaleProfileAPI)?.data[0]?.address,
							values: {
								address: (getClientPointOfSaleProfile as TGetClientPointOfSaleProfileAPI)?.data[0]?.address,
								lat: (getClientPointOfSaleProfile as TGetClientPointOfSaleProfileAPI)?.data[0]?.lat,
								lng: (getClientPointOfSaleProfile as TGetClientPointOfSaleProfileAPI)?.data[0]?.lng
							}
						},
						{
							field: 'country_id',
							oldValue: countries.find((country) => country.id === pointOfSale.country_id)?.name ? translate(`google-address-form_country_${countries.find((country) => country.id === pointOfSale.country_id)?.name}`) : null,
							newValue: countries.find((country) => country.id === (getClientPointOfSaleProfile as TGetClientPointOfSaleProfileAPI)?.data[0]?.country_id)?.name ? translate(`google-address-form_country_${countries.find((country) => country.id === (getClientPointOfSaleProfile as TGetClientPointOfSaleProfileAPI)?.data[0]?.country_id)?.name}`) : null,
							values: {
								country_id: countries.find((country) => country.id === (getClientPointOfSaleProfile as TGetClientPointOfSaleProfileAPI)?.data[0]?.country_id)?.id ?? null
							}
						},
					]
					dataCompared.forEach((item) => {
						if ((item.newValue !== undefined && item.newValue !== null) && (item.oldValue !== item.newValue)) {
							fieldsToUpdate.push({
								pointOfSaleId: pointOfSale.id,
								field: item.field as TUpgradeFields,
								oldValue: item.oldValue,
								newValue: item.newValue,
								values: item.values
							})
						}
					})
					if (stateName(pointOfSale.state_id, associations) as string === status.connected) {
						pointOfSaleObj.fieldsToUpdate = fieldsToUpdate
					}
					pointsOfSale.push(pointOfSaleObj)
				}
			}
		}
	} else {
		const getClientPointsOfSaleFecth = await api.getClientPointsOfSaleAPI(id, token)
		if (getClientPointsOfSaleFecth.response.status === 200) {
			const sortPointsOfSale = getClientPointsOfSaleFecth?.data?.point_of_sales.sort((a, b) => a.name.localeCompare(b.name))
			for (const [index, pointOfSale] of sortPointsOfSale?.entries()) {
				const { id, name, contact_name, address, city, area, country_id, postal_code, province, door, floor, lat, lng, full_price, online, web, tel_prefix, tel_sufix, category_id, createdAt, updatedAt, exchange_id } = pointOfSale;
				const category: ICategoryState = categories.find((category) => category.value === category_id) as ICategoryState
				const getClientPointOfSaleProfile = await api.getClientPointOfSaleProfileAPI(pointOfSale.id, token)
				const pointOfSaleObj: IPointOfSaleState = {
					index: index,
					key: id,
					code: "",
					name: name,
					businessName: "",
					tradeName: "",
					client_alias_id: client_alias_id,
					point_of_sale_id: id,
					area: area ?? 0,
					country: {
						id: country_id as string,
						name: countries.find((country) => country.id === country_id)?.name as string
					},
					state_id: "",
					state_name: "",
					category: {
						value: (category as ICategoryState)?.value,
						label: (category as ICategoryState)?.label,
						online: (category as ICategoryState)?.online
					},
					province: province,
					city: city,
					postal_code: postal_code,
					address: address,
					door: door as string,
					floor: floor as string,
					lat: lat,
					lng: lng,
					tel_prefix: tel_prefix,
					tel_sufix: tel_sufix,
					start_season: "",
					start_date: "",
					web: web as string,
					email: email,
					full_price: full_price,
					online: online,
					connected: false,
					receiving: false,
					data_share: false,
					contact_name: contact_name as string,
					point_of_sale_brand_works: getClientPointOfSaleProfile.data[0].point_of_sale_brand_works.map((ps: any) => ps.brand) as IClientBrandWorksState[] ?? [],
					clientStatus: "",
					exchange_id: exchange_id,
					division_id: [],
					segmentation_id: [],
					tier_id: [],
					createdAt: createdAt,
					updatedAt: updatedAt,
					last_sale: null
				}
				pointsOfSale.push(pointOfSaleObj)
			}
		}
	}

	// Check if is available client data upgrade
	let dataColumns: ClientDataColumns[] = [
		{
			name: { own: name, original: null, values: null },
			legal_name: { own: legal_name, original: null, values: null },
			cif: { own: cif, original: null, values: null },
		},
		{
			clientCode: { own: code, original: null, values: null },
			web: { own: web, original: null, values: null },
		},
		{
			address: {
				own: `${address}${floor ? `, ${translate('client_business-info_floor')} ${floor}` : ''}${door ? `, ${translate('client_business-info_door')} ${door}` : ''}`,
				original: null,
				values: { address: '', floor: '', door: '', lat: 0, lng: 0 }
			},
			postal_code: { own: postal_code, original: null, values: null },
		},
		{
			city: { own: city, original: null, values: null },
			province: { own: province, original: null, values: null },
			country: {
				own: countries.find((country) => country.id === clientCountryId)?.name as string,
				original: null,
				values: null
			},
		},
		{
			contact_name: { own: contact_name, original: null, values: null },
			email: { own: email, original: null, values: null },
		},
		{
			phoneNumber: {
				own: `${tel_prefix} ${tel_sufix}`,
				original: null,
				values: { tel_prefix: '', tel_sufix: '' }
			},
			instagram: { own: instagram, original: null, values: null },
			youtube: { own: youtube, original: null, values: null }
		},
		{
			facebook: { own: facebook, original: null, values: null },
			linkedin: { own: linkedin, original: null, values: null },
		}
	]

	try {
		const getClientFetch = await api.getClientProfileAPI(client_id, token)
		if (getClientFetch.response.status === 200) {
			const {
				name: cTradeName,
				legal_name: cBusinessName,
				cif: cCif,
				web: cWeb,
				address: cAddress,
				floor: cFloor,
				door: cDoor,
				lat: cLat,
				lng: cLng,
				postal_code: cPostalCode,
				city: cCity,
				province: cProvince,
				country: cCountry,
				contact_name: cContactName,
				email: cEmail,
				telephone_prefix: cTelPrefix,
				telephone_sufix: cTelSufix,
				instagram: cInstagram,
				youtube: cYoutube,
				facebook: cFacebook,
				linkedin: cLinkedin,
			} = getClientFetch.data

			dataColumns = [
				{
					name: { own: name, original: cTradeName ?? '', values: null },
					legal_name: { own: legal_name, original: cBusinessName ?? '', values: null },
					cif: { own: cif, original: cCif ?? '', values: null },
				},
				{
					clientCode: { own: code, original: '', values: null },
					web: { own: web, original: cWeb ?? '', values: null },
				},
				{
					address: {
						own: `${address}${floor ? `, ${translate('client_business-info_floor')} ${floor}` : ''}${door ? `, ${translate('client_business-info_door')} ${door}` : ''}`,
						original: `${cAddress}${cFloor ? `, ${translate('client_business-info_floor')} ${cFloor}` : ''}${cDoor ? `, ${translate('client_business-info_door')} ${cDoor}` : ''}`,
						values: { address: cAddress, floor: cFloor, door: cDoor, lat: cLat as number, lng: cLng as number }
					},
					postal_code: { own: postal_code, original: cPostalCode ?? '', values: null },
				},
				{
					city: { own: city, original: cCity ?? '', values: null },
					province: { own: province, original: cProvince ?? '', values: null },
					country: {
						own: countries.find((country) => country.id === clientCountryId)?.name as string,
						original: cCountry?.name ?? '',
						values: null
					},
				},
				{
					contact_name: { own: contact_name, original: cContactName as string ?? '', values: null },
					email: { own: email, original: cEmail ?? '', values: null },
				},
				{
					phoneNumber: {
						own: `${tel_prefix} ${tel_sufix}`,
						original: `${cTelPrefix} ${cTelSufix}`,
						values: { tel_prefix: cTelPrefix, tel_sufix: cTelSufix }
					},
					instagram: { own: instagram, original: cInstagram ?? '', values: null },
					youtube: { own: youtube, original: cYoutube ?? '', values: null }
				},
				{
					facebook: { own: facebook, original: cFacebook ?? '', values: null },
					linkedin: { own: linkedin, original: cLinkedin ?? '', values: null },
				}
			]
		}
	} catch (error) {
		console.log(error);
	} finally {
		let dataColumnsWithChanges: TUpgradeClientItems[] = []
		for (const dataColumn of dataColumns) {
			const fieldsMap = Object.entries(dataColumn).map(([key, value]) => ({ key, value }))
			for (const field of fieldsMap) {
				if (
					field.key !== 'clientCode'
					&& field.value.original !== null
					&& field.value.own
					&& (field.value.own !== field.value.original)
				) {
					dataColumnsWithChanges.push({
						keyValue: field.key as KeyValuesTypes,
						oldString: field.value.own,
						newString: field.value.original,
						value: (field.key === 'address' || field.key === 'phoneNumber') ? field.value?.values as TAddressOptionsType : field.value.original as string,
					})
				}
			}
		}

		const getClient: IClientState = {
			key: id,
			clientCode: code,
			tradeName: name,
			businessName: legal_name,
			cif: cif,
			address: address,
			postal_code: postal_code,
			city: city,
			country: {
				id: clientCountryId,
				name: countries.find((country) => country.id === clientCountryId)?.name as string
			},
			province: province,
			floor: floor,
			door: door,
			lat: lat,
			lng: lng,
			email: email,
			web: web,
			startDate: createdAt,
			startSeason: '',
			tel_prefix: tel_prefix,
			tel_sufix: tel_sufix,
			contact_name: contact_name,
			status: statusType,
			totalPointsSale: pointsOfSale.length,
			segmentations: `${segmentations.length > 0 ? segmentations.join(', ') : ''}`,
			tiers: `${tiers.length > 0 ? 'Tiers: ' + tiers.join(', ') : ''}`,
			clientSalePoint: pointsOfSale.sort((a, b) => a.state_name?.localeCompare(b.state_name)).map((pointOfSale, index) => {
				return {
					...pointOfSale,
					index: index
				}
			}),
			associationInfo: {
				id: clientId,
				client_id: client_id,
				client_alias_id: client_alias_id,
				state_id: state_id,
				state_name: stateName(state_id, associations) as string,
				brand_id: brand_id,
				client_ok: client_ok,
				brand_ok: brand_ok,
				createdAt: createdAt,
				updatedAt: updatedAt,
			},
			clientStatus: '',
			clientBrandWorks: [],
			enableModify: false,
			contacts: [],
			agents: [],
			pendingPointsOfSaleAssociations: 0,
			matching: null,
			instagram,
			youtube,
			facebook,
			linkedin,
			dataColumns: dataColumns,
			dataColumnsWithChanges: {
				values: dataColumnsWithChanges,
				clientId: id
			}
		}
		return getClient
	}
}

const stateName = (state_id: string, associationStates: IAssociationsStates) => {
	if (state_id === associationStates.pending.id) {
		return associationStates.pending.state
	}
	if (state_id === associationStates.connected.id) {
		return associationStates.connected.state
	}
	if (state_id === associationStates.rejected.id) {
		return associationStates.rejected.state
	}
	if (state_id === associationStates.disconnected.id) {
		return associationStates.disconnected.state
	}
}