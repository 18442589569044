import { useReducer, useMemo, useEffect, useCallback, memo } from 'react'
import UserContext from './UserContext'
import initialUserState from './initialUserState'
import userReducer from 'contexts/user/reducer/user.reducer'
import * as action from "contexts/user/reducer/user.actions";
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { ISAdminTenantState, ISAdminUserTenantState } from 'interfaces/superAdmin.interface';
import { ICompanyForm, IDbUserForm, IInitialUserState } from 'interfaces/user.interface';
import { ChildrenProps } from 'types';
import useContextUILanguages from 'hooks/contexts/useContextUILanguages';

function UserProvider(props: ChildrenProps) {
	const [uState, dispatch] = useReducer(userReducer, initialUserState)
	const userState = uState as IInitialUserState
	const { user, isAuthenticated, isLoading, getAccessTokenSilently, logout } = useAuth0()
	const { t: translate } = useTranslation()
	const { languages } = useContextUILanguages()

	useEffect(() => {
		if (!isLoading && isAuthenticated && user) {
			getAccessTokenSilently().then(async (token: string) => {
				// console.log(token);
				await action.loginUserAction(dispatch, token, user, translate, logout)
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading, isAuthenticated, user])

	const login = useCallback(async () => {
		const token = await getAccessTokenSilently()
		token && action.loginUserAction(dispatch, token, user, translate, logout)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [translate, userState.dbUser])

	const updateUser = useCallback(async (data: IDbUserForm, setIsOpenDrawer: (bool: boolean) => void) => {
		const token = await getAccessTokenSilently()
		action.updateUserAction(dispatch, userState.dbUser, data, token, setIsOpenDrawer, translate)
	}, [getAccessTokenSilently, userState.dbUser, translate])

	const updateCompanyData = useCallback(async (companyData: ICompanyForm, setIsOpenDrawer: (bool: boolean) => void) => {
		const token = await getAccessTokenSilently()
		action.updateCompanyAction(dispatch, userState, companyData, token, setIsOpenDrawer, translate)
	}, [getAccessTokenSilently, userState, translate])

	const deleteUser = useCallback(async () => {
		const token = await getAccessTokenSilently()
		action.deleteUserAction(dispatch, user, token, translate)
	}, [getAccessTokenSilently, user, translate])

	const updateUserAvatar = useCallback(async (image: File) => {
		const token = await getAccessTokenSilently()
		action.updateUserAvatarAction(dispatch, userState.dbUser, image, token, translate)
	}, [getAccessTokenSilently, userState, translate])

	const updateCompanyLogo = useCallback(async (image: File) => {
		const token = await getAccessTokenSilently()
		action.updateCompanyLogoAction(dispatch, userState.company.id, image, token, translate)
	}, [getAccessTokenSilently, userState.company.id, translate])

	const updateLanguage = useCallback(async (language: any) => {
		const token = await getAccessTokenSilently()
		action.updateLanguageAction(dispatch, userState.dbUser, language, token, translate)
	}, [getAccessTokenSilently, translate, userState.dbUser])

	const updateSocialMedia = useCallback(async (socialMedia: any, company: any, setShowConfirmEditBtn: any) => {
		const token = await getAccessTokenSilently()
		action.updateSocialMediaAction(dispatch, company, socialMedia, setShowConfirmEditBtn, token, translate)
	}, [getAccessTokenSilently, translate])

	const removeSocialMedia = useCallback(async (socialMediaId: string, company: any) => {
		const token = await getAccessTokenSilently()
		action.removeSocialMediaAction(dispatch, company, socialMediaId, token, translate)
	}, [getAccessTokenSilently, translate])

	const selectUserAndCompanySuperAdmin = useCallback(async (client: ISAdminTenantState, user: ISAdminUserTenantState) => {
		const token = await getAccessTokenSilently()
		action.selectUserAndCompanySuperAdminAction(dispatch, client, user, languages, translate, token)
	}, [languages, getAccessTokenSilently, translate])

	const selectCompanySuperAdmin = useCallback(async (client: ISAdminTenantState) => {
		const token = await getAccessTokenSilently()
		action.selectCompanySuperAdminAction(dispatch, client, translate, token)
	}, [getAccessTokenSilently, translate])

	const memoProvider = useMemo(
		() => ({
			...userState,
			login,
			updateUser,
			updateCompanyData,
			updateUserAvatar,
			updateCompanyLogo,
			deleteUser,
			updateLanguage,
			updateSocialMedia,
			removeSocialMedia,
			selectUserAndCompanySuperAdmin,
			selectCompanySuperAdmin
		}), [
		userState,
		login,
		updateUser,
		updateCompanyData,
		updateUserAvatar,
		updateCompanyLogo,
		deleteUser,
		updateLanguage,
		updateSocialMedia,
		removeSocialMedia,
		selectUserAndCompanySuperAdmin,
		selectCompanySuperAdmin
	]
	);

	return (
		<UserContext.Provider value={memoProvider}>
			{props.children}
		</UserContext.Provider>
	)
}

export default memo(UserProvider)